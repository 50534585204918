import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../../../Components/Notes/notes.css";
import { useDispatch, useSelector } from "react-redux";

import Note4 from "../../../Components/Notes/Note4";
import Note3 from "../../../Components/Notes/Note3";
import Notetype3 from "../../../Components/Notes/Notetype3";
import Note5 from "../../../Components/Notes/Note5";
import Note8 from "../../../Components/Notes/Note8";
import Pattern6 from "../../../Components/Notes/Pattern6";
import Pattern3 from "../../../Components/Notes/Pattern3";
import Pattern5 from "../../../Components/Notes/Pattern5";
import PatternRM from "../../../Components/Notes/PatternRM";
import Note22B from "../../../Components/Notes/Note22B";
import { setNoteNumber } from "../../../helper/calculation";

import CompareFsPattern1 from "./compareFsPattern1";
import CompareFsPattern2 from "./compareFsPattern2";
import CompareFsPattern3 from "./compareFsPattern3";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    minHeight: "50vh",
  },
  tabs: {
    maxHeight: "100vh",
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: "3rem",
    paddingBottom: "3rem",
    background: "linear-gradient(45deg, transparent, #E5EEEE)",

    color: "black",
    fontSize: "1rem",
    width: "260px",
  },
  tab: {
    // fontSize:'1px',
    padding: "12px 12px",
    overflow: "hidden",
    position: "relative",
    fontSize: "5px",
    // maxWidth: '300px',
    // width: '220px',
    boxSizing: "border-box",
    minHeight: "55px",
    textAlign: "center",
    flexShrink: 0,
    fontWeight: "500",
    // lineHeight: '1.75',
    letterSpacing: "0.02857em",
    textTransform: "none",
  },
  TabPanel: {
    width: "100%",
  },
}));

export default function CompareFSPnlStatementNotes(props) {
  const {
    consoleNotes,
    balanceSheetData,
    groupingData,
    consoleData,
    allcheckdata,
    tab,
    pnlStatementData,
    currentCompProjectId,
    headingData,
    checkKey,
    standaloneGroupingData,
    FindIr
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const NoteNo = useSelector((initialState) => initialState.reducer.plNoteNo);
  const { loading, isSuccess, response, error_message } = pnlStatementData;
  const currentProjectPnl = response.data?.pnl_statement?.pnl_statement ?? [];
  const fsRows = Object.keys(currentProjectPnl);
  const [no, setNo] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(checkKey);
    setNo(setNoteNumber(fsRows, currentProjectPnl));
  }, [checkKey]);

  // useEffect(() => {
  //   setValue(props.note)
  // }, [props.note])
  // useEffect(() => {
  //   setValue(NoteNo)
  // }, [NoteNo])
  const getNoteNoIndex = (notes_no) => {
    try {
      let resultIndex = 0;
      Object.keys(consoleNotes).forEach(function (key, index2) {
        let index = consoleNotes[key].find(
          (data) => data.note_number === notes_no
        );
        if (index !== undefined) {
          resultIndex = index.index;
        }
      });
      return resultIndex;
    } catch (error) {
      return "00";
    }
  };
  const heading = {
    width: "100%",
    height: "30px",
    backgroundColor: "#E5EEEE",
    position: "sticky",
    top: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // border:'1px solid teal'
  };

  const headingText = {
    fontSize: "1.1 rem",
    fontWeight: "700",
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {fsRows.map((group, groupIndex) => {
          return currentProjectPnl[group].map((noteGroup, noteGroupIndex) => (
            <Tab
              className={classes.tab}
              label={"Note - " + noteGroup.notes_grp}
              value={Number(getNoteNoIndex(noteGroup.notes_no))}
              {...a11yProps(noteGroupIndex)}
            />
          ));
        })}
      </Tabs>
      {Object.keys(consoleNotes).map((data, key) => (
        <div key={key}>
          {data === "pattern_1" ? (
            <>
              {consoleNotes[data].map((data, key) => (
                <TabPanel
                  key={key}
                  className={classes.tab}
                  value={value}
                  index={data.index}
                >
                  <CompareFsPattern1
                    note_no={data.note_number}
                    groupingData={groupingData}
                    rows={balanceSheetData?.balance_sheet?.balance_sheet}
                    currentCompany={consoleData}
                    subsiary={balanceSheetData?.balance_sheet?.balance_sheet}
                    sybsidiary={[
                      balanceSheetData?.balance_sheet?.balance_sheet,
                    ]}
                    finalSubsidiaryData={balanceSheetData?.project_ids_bs?.map(
                      (data) => data.balance_sheet
                    )}
                    subsiaryData={allcheckdata}
                    browsFs={[]}
                    current_tab={tab}
                    tabData={"pnl"}
                    pnlStatementData={pnlStatementData}
                    currentCompProjectId={currentCompProjectId}
                    standaloneGroupingData={standaloneGroupingData}
                    FindIr={FindIr}
                  />
                </TabPanel>
              ))}
            </>
          ) : data === "pattern_2" ? (
            <>
              {consoleNotes[data].map((data, key) => (
                <>
                  <TabPanel key={key} value={value} index={data.index}>
                    <CompareFsPattern2
                      note_no={data.note_number}
                      group={0}
                      groupingData={groupingData}
                      currentCompany={consoleData}
                      subsiaryData={allcheckdata}
                      currentCompProjectId={currentCompProjectId}
                      current_tab={tab}
                      tabData={"pnl"}
                      standaloneGroupingData={standaloneGroupingData}
                    />
                  </TabPanel>
                </>
              ))}
            </>
          ) : data === "pattern_3" ? (
            <>
              {consoleNotes[data].map((data, key) => (
                <TabPanel key={key} value={value} index={data.index}>
                  <CompareFsPattern3
                    note_no={data.note_number}
                    groupingData={groupingData}
                    currentCompProjectId={currentCompProjectId}
                    currentCompany={consoleData}
                    subsiaryData={allcheckdata}
                    subsidiaryGrouping={
                      groupingData?.response?.data?.browse_fs_grouping ?? []
                    }
                    browsFsGrouping={
                      groupingData?.response?.data?.browse_fs_grouping ?? []
                    }
                    finalSubsidiaryData={
                      groupingData?.response?.data?.project_ids_grouping ?? []
                    }
                    pnlStatementData={pnlStatementData}
                    current_tab={tab}
                    tabData={"pnl"}
                    standaloneGroupingData={standaloneGroupingData}
                  />
                </TabPanel>
              ))}
            </>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
}
