import "./App.css";
import React, { useEffect, useState } from "react";
import { Toaster } from 'react-hot-toast';
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import RetypePassword from "./pages/RetypePassword";
import ForgotPwd from "./pages/ForgotPwd";
import Inside from "./pages/Inside";
import PrivateRoute from "./authentication/PrivateRoute";
import PublicRoute from "./authentication/PublicRoute";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import CreateProject from "./PrivatePages/CreateProject/CreateProject";
import QuestionnaireNew from "./PrivatePages/setupProject/QuestionnaireNew";
import QuestionnaireExisting from "./PrivatePages/setupProject/QuestionnaireExisting";
import UploadTb from "./PrivatePages/setupProject/UploadTb";
import DataMapping from "./PrivatePages/setupProject/mappingMode";
import Practice from "./PrivatePages/setupProject/Practice";
import Conventional from "./PrivatePages/Conventional/Conventional";
import Template from "./PrivatePages/template/Template";
import NewTemplate from "./PrivatePages/template/newTemplate";
import Preview from "./PrivatePages/classificationPreview/Preview";
import PreviewPage from "./PrivatePages/classificationPreview/PreviewPage";
import FinancialStatement from "./PrivatePages/consolidatedFinancialStatement/FinancialStatement";
import Adjustments from "./PrivatePages/adjustments/Adjustments";
import AdjustmentsDeleted from "./PrivatePages/adjustments/AdjustmentsDeleted";
import AdjustmentsLog from "./PrivatePages/adjustments/AdjustmentsLog";
import Dashboard from "./PrivatePages/dashboard/Dashboard";
import CreateFs from "./PrivatePages/createFs/CreateFs";
import Presets from "./PrivatePages/Presets/Presets";
import ProfileChange from "./PrivatePages/ProfileChange/ProfileChange";
import SubscriptionMenu from "./PrivatePages/subscriptionmenu/SubscriptionMenu"
import SubscriptionMenuDetails from './PrivatePages/subscriptionmenu/SubscriptionMenuDetails'
import SubscriptionDetails from "./PrivatePages/subscriptionmenu/SubscriptionDetails";
import SubscribedPage from "./PrivatePages/subscriptionmenu/SubscribedPage";
import SupportVpage from "./PrivatePages/Support/Support";
import SupportContact from "./PrivatePages/Support/SupportContact";
import SupportTicketView from "./PrivatePages/Support/SupportTicketView";
import Folder from "./PrivatePages/folders/Folder";
import AddFiles from "./PrivatePages/folders/AddFiles";
import ThankYou from "./PrivatePages/ThankYou";
import InvestmentRegister from "./../src/Console/Component/InvestmentRegister";
import CreateInvestment from "./../src/Console/Component/createInvestment";
import AddInvestment from "./../src/Console/Component/AddInvestment";
import EditRegister from "./../src/Console/Component/EditRegister";
import AdditionRegister from "./../src/Console/Component/AdditionRegister";
import AdditionPresetMode from "./../src/Console/Component/AdditionPresetMode";
import SignUpV2 from "./WorkSpace/Components/Auth/SignUpV2";
import DashBoard from "./WorkSpace/Components/WorkspaceCompany/Dashboard/DashBoard";
import Page3 from "./WorkSpace/Components/WorkspaceCompany/selectCompany";
import WorkSpacehome from "./WorkSpace/Workspace-v2/WorkSpacehome";
// import Page2 from "./WorkSpace/Page2";
// import Page1 from "./WorkSpace/Page1";
import Sidebar from "./WorkSpace/Workspace2/Sidebar";
import ImportRegistersV2 from "./WorkSpace/Components/WorkspaceCompany/ImportRegistersV2";
// import Sidebar from "./WorkSpace/Slider";
// import WorkspaceSetup from "./../../../"
import LeaseTagAssets from "./PrivatePages/Linkregisters/LeaseTagAssets";
import WorkspaceSetup from "./WorkSpace/Components/WorkspaceCompany/WorkspaceSetup";
import InviteteamMember from "./WorkSpace/Components/WorkspaceCompany/InviteteamMember";
import GettingStarted from "./WorkSpace/Components/WorkspaceCompany/GettingStarted";
import AllWorkspace from "./WorkSpace/Components/WorkspaceCompany/AllWorkspace";
import AllCompany from "./WorkSpace/Components/WorkspaceCompany/AllCompany";
import WorkSpaceLayout from "./WorkSpace/Workspace2/WorkSpaceLayout";
import ImportPresetV2 from "./WorkSpace/Components/WorkspaceCompany/ImportPresetV2";
import AddCompanies from "./WorkSpace/Components/WorkspaceCompany/AddComaniesV2";
// import Sidebarv2 from "./WorkSpace/Workspace-v2/Sidbarv2";
import WorkspaceLayoutv2 from "./WorkSpace/Workspace-v2/WorkspaceLayoutv2";
import Overview from "./WorkSpace/Components/FSPreparetion/Overview";
import Consolidation from "./WorkSpace/Components/FSPreparetion/Consolidation";
import Myworkspace from "./WorkSpace/Workspace-v2/Myworkspace";
import InsideWorkspace from "./WorkSpace/Components/InvestRegister/InsideWorkspace";
import InsideIR from "./WorkSpace/Components/InvestRegister/InsideIR";
import AdditionInvestmentRegister from "./WorkSpace/Components/InvestRegister/AdditionInvestRegister";
// import Navbarv2
// import FileFormat from './Components/fileFormat/FileFormat'
import AddYourTeam from "./WorkSpace/Components/TeamMember/Addyour/AddYourTeam";
import axios from "axios";
import { socket } from "./services/socket/socket";
import WorkspacePreset from "./WorkSpace/Components/FSPreparetion/WorkspacePreset";
import TeamMemebers from "./WorkSpace/Components/FSPreparetion/TeamMembers";
import FinancialStatementv2 from "./WorkSpace/Components/FSPreparetion/FinancialStatementv2";
import WorkspaceDeatilsv2 from "./WorkSpace/Components/FSPreparetion/WorkspaceDeatilsv2";
import WorkspaceRegisterv2 from "./WorkSpace/Components/FSPreparetion/WorkspaceRegisterv2";
import { getAPI } from "./services/api/StandAlone/projectAPIs";
// import FinancialStatement from ".//WorkSpace/WorkspaceConsoleEntry/FinancialStatement";
import DashboardWorkspace from "././WorkSpace/Workspace-v2/DashboardWorkspace";
import Tabsystem from "./WorkSpace/Workspace-v2/Tabsystem";
import InvestmentRegisterv2 from "./WorkSpace/Components/InvestRegister/InvestmentRegisterv2";
import LeaserOverview from "./WorkSpace/Workspace-v2/Lease/LeasesOverview";
import SummaryMovement_v1 from "./WorkSpace/Workspace-v2/Lease/SummaryMovement_v1";
import AccountEntries from "./WorkSpace/Workspace-v2/Lease/AccountEntries";
import Repository from "./WorkSpace/Workspace-v2/Lease/Repository";
import Bookclosure from "./Bookclosure/Pages/Bookclosure";
import UploadTbV2 from "./Bookclosure/Pages/UploadTbV2";
import Tbc from "./Bookclosure/Pages/Tbc";
import ClassificationPreview from "./Bookclosure/Pages/ClassificationPreview";
import ImportPreset from "./Bookclosure/Pages/ImportPreset";
import BookcluosureNav from "./Bookclosure/Pages/BookcluosureNav";
import BookClosureLibrary from "./Bookclosure/Pages/BookClosureLibrary";
import BookClosureEntries from "./Bookclosure/Pages/BookClosureEntries";
import Matrix from "./Bookclosure/Pages/Matrix";
import { MatrixNav } from "./Bookclosure/Pages/MatrixNav";
import AuthorizedCapital from "./WorkSpace/Components/InvestRegister/AuthorizedCapital";
import ShareCapital from "./WorkSpace/Components/InvestRegister/ShareCapital";
import Admin from "./AdminPortalV2/Admin";
import AddTeamate from "./AdminPortalV2/AddTeamate";
import AdminUser from "./AdminPortalV2/AdminUser";
import GeneralDetails from "./AdminPortalV2/GeneralDetails";








import LginValidation from "./WorkSpace/Components/Auth/loginvalidation";
import {
  ThemeContext,
  ViewContext,
  AccessProjectContext,
} from "./helper/DarkModeContext";
// ? Redux
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "./redux/actionTypes";
import CashFlowWorking from "./Components/CashFlow/Working/CashFlowWorking";
import LeaseProject from "../src/WorkSpace/Workspace-v2/Lease/leaseProject";
import CreateLease from "./WorkSpace/Workspace-v2/Lease/CreateLease";
import Financialparameters from "./WorkSpace/Workspace-v2/Lease/Financialparameters";
import CashFlowAdjustment from "./Components/CashFlow/Adjustment/CashFlowAdjustment";
import AdjustmentLogPage from "./Components/CashFlow/Adjustment/AdjustmentLog/AdjustmentLogPage";
import Validation from "./WorkSpace/Components/Auth/validation";
import viewFs from "./../src/Console/Component/viewFs";
import Preset from "./PrivatePages/Preset/Preset";
import UploadPresetTb from "./PrivatePages/Preset/UploadPresetTb";
import populatefsNew from "./Console/Component/PopulateFS/populatefsNew";
import compareFs from "./Console/Component/CompareFS/compareFs";
import AddTeam from "./WorkSpace/Components/TeamMember/AddTeam";
import AddTeam2 from "./WorkSpace/Components/TeamMember/AddTeam2";
import Notification from "./WorkSpace/Components/Notification/Notification";
import LibraryIndAs1 from "./PrivatePages/Library/LibraryIndAs1";
import Library from "./PrivatePages/Library/Library";
import { socketTTBSUpdate } from "./redux/actions/TemplateTabs/actionBS";
import { socketTTGroupUpdate } from "./redux/actions/TemplateTabs/actionGrouping";
import { socketTTPNLUpdate } from "./redux/actions/TemplateTabs/actionPNL";
import { socketCMUpdate } from "./redux/actions/TemplateTabs/actionCM";
import { socketUpdatePrepareConsole } from "./redux/actions/actionPrepareConsole";
import IRDetails from "./WorkSpace/Components/WorkspaceCompany/IRDetails";
import CoaDetails from "./PrivatePages/COA/CoaDetails";
import { socketTTNotesUpdate } from "./redux/actions/TemplateTabs/actionNotes";
import CleanupWrapper from "./Components/CleanupWrapper";
import { socketCNUpdate } from "./redux/actions/actionCN";
import LinkRegister from './PrivatePages/Linkregisters/LinkRegister'
import FinaliseFs from "./PrivatePages/FinaliseFs/FinaliseFs";
import EditIamUser from "./WorkSpace/Components/TeamMember/EditIamUser";
import ViewLog from "./WorkSpace/Components/TeamMember/ViewLog";
import TestTable from "./Components/templateTabs/testTable";
import WorkspaceSetting from "./WorkSpace/Components/FSPreparetion/WorkspaceSetting/WorkspaceSetting";
// import ViewLogPage from "./WorkSpace/Components/TeamMember/ViewLogPage";
import DeletedIds from "./WorkSpace/Components/TeamMember/DeletedIds";
import ViewLogpage from "./WorkSpace/Components/TeamMember/ViewLogpage";
import Version from "./WorkSpace/Components/TeamMember/Version";
import ModifyAccess from "./WorkSpace/Components/WorkspaceCompany/ModifyAccess";
import FunctionalityAccess from "./WorkSpace/Components/WorkspaceCompany/FunctionalityAccess";
import SupportPage from "./WorkSpace/Components/TeamMember/SupportPage";
import Newticket from "./WorkSpace/Components/TeamMember/NewTicket";
import Viewticket from "./WorkSpace/Components/TeamMember/ViewTicket";
import UpdateTicket from "./WorkSpace/Components/TeamMember/UpdateTicket";
import CreatePassword from "./pages/Createpassword";
import SupportData from "./PrivatePages/Support/SupportDeatails";
import { getProfile } from "./services/api/apiHandler";
import CreateIRPage from "./WorkSpace/Components/InvestRegister/Update/CreateIRPage";
import conventionalTbc from "./Bookclosure/Pages/conventionalTbc";
import FetchFStable from "./WorkSpace/Components/FSPreparetion/FetchFStable";
import PresetInfo from "./WorkSpace/Components/FSPreparetion/PresetInfo";
axios.defaults.baseURL = process.env.REACT_APP_WEB_URL;
// axios.defaults.baseURL = "http://myfinalystapi.icodexa.com/";
// axios.defaults.baseURL = 'http://localhost:3000/'

function App() {
  const dispatch = useDispatch();
  const project_id = localStorage.getItem("project_id");
  const auth = localStorage.getItem("auth_token");

  let headers = {
    "x-auth-token": auth,
    // 'Content-Type' : 'application/json'
  };
  React.useEffect(() => {
    socket.on("error", (data) => {
      console.log("{USER LOGGED IN 1}", data);
    });

    socket.on("on-auto-save", (data) => {
      //To trigger in the Fetch API (Conventional Model)
      console.log("{User Logged IN 1}", data);
    });

    socket.on("message", (data) => {
      console.log("{USER LOGGED IN 1}", data);
    });

    socket.on("get-mapped-pnl-statement", (response) => {
      if (response.result.status) {
        dispatch(
          socketTTPNLUpdate(response.result.pnl_statement, response.payload)
        );
      } else {
        console.error("error", response.result.message);
      }
    });

    socket.on("get-mapped-balance-sheet", (response) => {
      if (response.result.status) {
        dispatch(
          socketTTBSUpdate(response.result, response.payload)
        );
      } else {
        console.error("error", response.result.message);
      }
    });

    socket.on("get-mapped-group-data", (response) => {
      if (response.result.status) {
        dispatch(socketTTGroupUpdate(response.result.fs_grp, response.payload));
      } else {
        console.error("error", response.result.message);
      }
    });

    socket.on("get-conventional-mode", (response) => {
      if (response.result.status) {
        dispatch(
          socketCMUpdate(response.result.data.line_items, response.payload)
        );
      } else {
        console.error("error", response.result.message);
      }
    });

    socket.on("get-prepare-console", (response) => {
      console.log("socket response", response);
      if (response.result.status) {
        dispatch(socketUpdatePrepareConsole(response.result, response.result.payload));
      } else {
        console.error("error", response.result.message);
      }
    });

    // socket.on("get-notes-data", (response) => {
    //  dispatch(socketTTNotesUpdate(response.result, response.payload));
    // });

    socket.on("get-console-notes", (response) => {
      dispatch(socketCNUpdate(response.result, response.payload));
    });

    socket.on("get-rearranged-bs-data", (response) => {
      const { groupingData, balanceSheet } = response;
      dispatch(socketTTBSUpdate(balanceSheet, balanceSheet.payload));
      dispatch(socketTTGroupUpdate(groupingData.result.fs_grp, groupingData.payload));
      socket.emit("request-fetch-console-notes", { pid: JSON.parse(balanceSheet.payload).project_id })
    })

    socket.on("get-rearranged-pnl-data", (response) => {
      console.log("re-arrange", response)
      const { groupingData, pnlStatement } = response;
      dispatch(socketTTPNLUpdate(pnlStatement.result, pnlStatement.payload));
      dispatch(socketTTGroupUpdate(groupingData.result.fs_grp, groupingData.payload));
      socket.emit("request-fetch-console-notes", { pid: JSON.parse(pnlStatement.payload).project_id })
    })

    fetchAccessProject();

    clearCache();
    return () => {
      socket.off("get-mapped-balance-sheet");
      socket.off("get-mapped-group-data");
      socket.off("get-conventional-mode");
      socket.off("get-mapped-pnl-statement");
      socket.off("get-prepare-console");
      socket.off("get-notes-data");
      socket.off("get-console-notes");
      socket.off("get-rearranged-bs-data");
    };
  }, []);

  const clearCache = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const fetchAccessProject = () => {
    if (!access) {
      getProfile().then(response => {
        if (response.status === 200) {
          const filtredAccessProjects =
            response.data.user.data.access_projects.filter((project) => {
              return project.project_id === project_id;
            });

          if (filtredAccessProjects.length > 0) {
            setAccess(filtredAccessProjects[0]);
          } else {
            setAccess({ access: "All", project_id: "" });
          }
        }
      }).catch(error => {
        console.log("error from get profile", error)
      })
    }
  };

  const [theme, setTheme] = useState(true);
  const [directoryView, setDirectoryView] = useState("list");
  const [access, setAccess] = useState({ access: "All", project_id: "" });
  const TB_ID = useSelector((initialState) => initialState.reducer.tb_id);
  useEffect(() => {
    console.log("TB id is missing");
    // ? When TB id is missing then it call restore in
    if (!TB_ID) {
      if (project_id) {
        getAPI(`/api/v1/tb-mapping/get-tb/${project_id}`).then((res) => {
          dispatch({ type: actionTypes.SET_TB_ID, payload: res.data.tb_id });
        });
      }
    }
  }, [!TB_ID]);


  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'auth_token' && event.oldValue && !event.newValue) {
        //? If the authToken is removed, trigger logout
        window.location.reload()
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <ViewContext.Provider value={{ directoryView, setDirectoryView }}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <AccessProjectContext.Provider value={{ access, setAccess }}>
          <Router>
            <ScrollToTop />
            <CleanupWrapper />
            <Toaster />
            <ToastContainer />
            <Switch>
              {/* <PublicRoute restricted= {true} path='/' exact component={SignUp}/> */}
              <PublicRoute
                restricted={true}
                path="/"
                exact
                component={SignIn}
              />
              <PublicRoute
                restricted={true}
                path="/signup"
                component={SignUpV2}
              />
              <PublicRoute
                restricted={true}
                path="/signupV2"
                component={SignUp}
              />
              <PublicRoute
                restricted={true}
                path="/validation/:id"
                component={Validation}
              />
              {/* <PublicRoute restricted={true} path="/Page2" component={Page2} /> */}
              {/* <PublicRoute restricted={true} path="/Page1" component={Page1} /> */}
              <PublicRoute
                restricted={true}
                path="/createpassword/:id"
                component={CreatePassword}
              />
              <PublicRoute
                restricted={true}
                path="/emailverify/:id"
                component={LginValidation}
              />

              <PublicRoute
                restricted={true}
                path="/Sidebar"
                component={Sidebar}
              />
              <PublicRoute
                restricted={true}
                path="/accountentries"
                component={AccountEntries}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/insideworkspace"
                component={CreateIRPage}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/insideworkspace/:id"
                component={InsideIR}
              />
              {/* <PublicRoute
                  exact
                  restricted={true}
                  path="/worksapcehome/"
                  component={WorkSpacehome}

                /> */}
              <PrivateRoute
                exact
                restricted={true}
                path="/worksapcehome/"
                component={WorkSpacehome}
              />
              {/* add new page bookclosure */}
              <PrivateRoute
                exact
                restricted={true}
                path="/bookclosure/:workspaceId"
                component={Bookclosure}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/upload-tb/:workspaceId/:companyId"
                component={UploadTbV2}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/tbc/:bookClosureTbId/:workspaceId"
                component={Tbc}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/conventional-mode-tbc/:bookClosureTbId/:workspaceId"
                component={conventionalTbc}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/classification-preview"
                component={ClassificationPreview}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/import-preset/:workspaceId/:companyId"
                component={ImportPreset}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/bookclosure-nav/:wid"
                component={BookcluosureNav}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/bookclosure-entries/:workspaceId/:companyId"
                component={BookClosureEntries}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/bookclosure-library/:workspaceId/:companyId"
                component={BookClosureLibrary}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/matrix/:workspaceId/:companyId"
                component={Matrix}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/authorized-capital"
                component={AuthorizedCapital}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/share-capital"
                component={ShareCapital}
              />

              <PrivateRoute
                exact
                restricted={true}
                path="/admin"
                component={Admin}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/add-teamate/:childUserId?"
                component={AddTeamate}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/admin-user"
                component={AdminUser}
              />

              <PrivateRoute
                exact
                restricted={true}
                path="/select-company/:childUserId/:isUpdate?"
                component={AdminUser}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/add-responsibility/:childUserId"
                component={GeneralDetails}
              />
              <PublicRoute
                restricted={true}
                path="/financialstatementv2"
                component={FinancialStatement}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/dashboardv2/workspace"
                component={DashboardWorkspace}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/investmentregister/:id"
                component={InvestmentRegisterv2}
              />
              <PublicRoute
                restricted={true}
                path="/lease/financialparameters"
                component={Financialparameters}
              />
              {/*  */}
              <PrivateRoute
                restricted={true}
                path="/preset-information"
                component={PresetInfo}
              />
              <PrivateRoute
                restricted={true}
                path="/fetch-fs-table"
                component={FetchFStable}
              />
              {/*  */}
              <PrivateRoute
                exact
                restricted={true}
                path="/gettingstarted"
                component={GettingStarted}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/library"
                component={Library}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/library-indas-1"
                component={LibraryIndAs1}
              />
              {/*  */}
              <PrivateRoute
                exact
                restricted={true}
                path="/tabsystem"
                component={Tabsystem}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/workspacesetup/:id"
                component={WorkspaceSetup}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/inviteteammember/:id"
                component={InviteteamMember}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/workspacelayout/:workspaceId?"
                component={WorkSpaceLayout}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/company"
                component={Page3}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/dashboardV2"
                component={Dashboard}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/IAM"
                component={AddTeam2}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/supports"
                component={SupportPage}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/support-newticket"
                component={Newticket}
              />

              <PrivateRoute
                exact
                restricted={true}
                path="/support-viewticket/:id"
                component={Viewticket}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/support-editticket/:id"
                component={UpdateTicket}
              />

              <PrivateRoute
                exact
                restricted={true}
                path="/notification"
                component={Notification}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/modifyacess/:uid/:wid"
                component={ModifyAccess}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/functionalityacess/:uid/:wid"
                component={FunctionalityAccess}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/edit-user/:user_id"
                component={EditIamUser}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/addyourteam"
                component={AddYourTeam}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/viewlog"
                component={ViewLog}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/deleted-Ids"
                component={DeletedIds}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/view-log"
                component={ViewLogpage}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/version-updates"
                component={Version}
              />

              <PrivateRoute
                exact
                restricted={true}
                path="/importregister/:id"
                component={ImportRegistersV2}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/importPreset/:id"
                component={ImportPresetV2}
              />
              <PrivateRoute
                exact
                path="/addCompanies/:id"
                component={AddCompanies}
              />
              {/* <PublicRoute exact path="/sidebarv2" component={Sidebarv2} /> */}
              <PublicRoute
                exact
                path="/workspaceLayoutv2"
                component={WorkspaceLayoutv2}
              />
              <PublicRoute exact path="/createlease" component={CreateLease} />
              <PublicRoute
                exact
                path="/leaseProjectv2"
                component={LeaseProject}
              />
              <PrivateRoute exact path="/overview/:id" component={Overview} />
              <PublicRoute
                exact
                path="/consolidation/:id"
                component={Consolidation}
              />
              <PublicRoute exact path="/myworkspace" component={Myworkspace} />
              <PublicRoute
                exact
                path="/workspacePreset/:id"
                component={WorkspacePreset}
              />
              <PrivateRoute
                exact
                path="/TeamMemebers/:id"
                component={TeamMemebers}
              />
              <PrivateRoute
                exact
                path="/financialstatement/:id"
                component={FinancialStatementv2}
              />
              <PrivateRoute
                exact
                path="/workspacedeatils/:id"
                component={WorkspaceSetting}
              />
              <PrivateRoute
                exact
                path="/workspaceregister/:id"
                component={WorkspaceRegisterv2}
              />
              <Route path="/thankyou/:uid" exact component={ThankYou} />
              <PublicRoute
                restricted={true}
                path="/retypepassword/:id"
                component={RetypePassword}
              />
              <PrivateRoute path="/logout" component={Inside} />
              <PrivateRoute
                exact
                path="/createproject"
                component={CreateProject}
              />
              <PrivateRoute exact path="/repository" component={Repository} />
              <PrivateRoute
                exact
                path="/leaseroverview"
                component={LeaserOverview}
              />
              <PrivateRoute
                exact
                path="/summarymovement_v1"
                component={SummaryMovement_v1}
              />
              <PrivateRoute
                exact
                path="/createproject/practice"
                component={Practice}
              />

              <PrivateRoute
                exact
                path="/createproject/questionnairenew/:wid" //workspace id
                component={QuestionnaireNew}
              />
              <PrivateRoute
                exact
                path="/createproject/questionnaireexisting"
                component={QuestionnaireExisting}
              />
              <PrivateRoute
                exact
                path="/createproject/uploadtb"
                component={UploadTb}
              />
              <PrivateRoute
                exact
                path="/createproject/datamapping/:pid"
                component={DataMapping}
              />
              <PrivateRoute
                exact
                path="/conventional/:pid"
                component={Conventional}
              />
              <PrivateRoute
                exact
                path="/allWorkspace"
                component={AllWorkspace}
              />
              <PrivateRoute exact path="/allCompany" component={AllCompany} />
              <PrivateRoute exact path="/template/:pid" component={Template} />
              <PrivateRoute
                exact
                path="/templateV2/:pid"
                component={NewTemplate}
              />
              <PrivateRoute exact path="/preview" component={Preview} />
              <PrivateRoute exact path="/previewpage" component={PreviewPage} />
              <PrivateRoute exact path="/detailsIR/:id" component={IRDetails} />
              <PrivateRoute
                exact
                path="/financialstatement"
                component={FinancialStatement}
              />
              <PrivateRoute
                exact
                path="/financialstatement/cashflow/working"
                component={CashFlowWorking}
              />
              <PrivateRoute
                exact
                path="/financialstatement/cashflow/add-new-adjustment"
                component={CashFlowAdjustment}
              />
              <PrivateRoute
                exact
                path="/financialstatement/cashflow/adjustment-log"
                component={AdjustmentLogPage}
              />
              <PrivateRoute exact path="/adjustments" component={Adjustments} />
              <PrivateRoute
                exact
                path="/deleted-adjustments"
                component={AdjustmentsDeleted}
              />
              <PrivateRoute
                exact
                path="/adjustments-log"
                component={AdjustmentsLog}
              />
              <PrivateRoute
                exact
                path="/createfinancialstatement"
                component={CreateFs}
              />
              {/* <PrivateRoute exact path="/folders" component={Folder} /> */}
              <PrivateRoute exact path="/folder/:fid" component={AddFiles} />
              <PrivateRoute exact path="/dashboard" component={DashBoard} />
              <PrivateRoute exact path="/presets" component={Presets} />
              <PrivateRoute
                exact
                path="/profile-change"
                component={ProfileChange}
              />
              <PrivateRoute
                exact
                path="/subscription"
                component={SubscriptionMenu}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/subscription/:id"
                component={SubscriptionMenuDetails}
              />
              <PrivateRoute
                exact
                restricted={true}
                path="/subscription-details/:id"
                component={SubscriptionDetails}
              />

              <PrivateRoute
                exact
                restricted={true}
                path="/subscribed"
                component={SubscribedPage}
              />
              {/* //////////////// bhabesh */}
              <PrivateRoute
                exact
                path="/support"
                component={SupportTicketView}
              />


              <PrivateRoute
                exact
                path="/support-contact/:contactId?"
                component={SupportContact}
              />

              <PrivateRoute
                exact
                path="/support-faq"
                component={SupportVpage}
              />

              <PrivateRoute
                exact
                restricted={true}
                path="/support-chats/:id"
                component={SupportData}
              />


              {/* console */}
              <PrivateRoute
                exact
                path="/investment-register"
                component={InvestmentRegister}
              />
              <PrivateRoute exact path="/view-fs/:id" component={viewFs} />
              <PrivateRoute
                exact
                path="/comparefs/:id/:id"
                component={compareFs}
              />
              <PrivateRoute
                exact
                path="/populate-fs/:id"
                component={populatefsNew}
              />
              <PrivateRoute
                exact
                path="/create-investment/:cid"
                component={CreateInvestment}
              />
              <PrivateRoute
                exact
                path="/add-investment/:cid"
                component={AddInvestment}
              />
              <PrivateRoute
                exact
                path="/edit-register/:cid"
                component={EditRegister}
              />
              <PrivateRoute
                exact
                path="/addition-register/:rid"
                component={AdditionRegister}
              />
              <PrivateRoute
                exact
                path="/additionInvestregister/:rid"
                component={AdditionInvestmentRegister}
              />
              <PrivateRoute
                exact
                path="/addition-preset-mode/:id"
                component={AdditionPresetMode}
              />
              <PrivateRoute exact path="/preset/:presetId" component={Preset} />
              <PrivateRoute
                exact
                path="/preset/upload-tb/:type/:presetId"
                component={UploadPresetTb}
              />
              <PrivateRoute path="/link-register" component={LinkRegister} />
              <PrivateRoute path="/lease-register-mapping/:leaseIndex" component={LeaseTagAssets} />
              <PrivateRoute path="/finalize-fs" component={FinaliseFs} />
              <PrivateRoute exact path="/coa-details/:coaId" component={CoaDetails} />
              <PublicRoute exact path='/test-table' component={TestTable} />
              <PrivateRoute
                exact
                path="/Upload-tb"
                component={UploadPresetTb}
              />
              <PublicRoute path="*" component={() => "404 NOT FOUND"} />

            </Switch>
          </Router>
        </AccessProjectContext.Provider>
      </ThemeContext.Provider>
    </ViewContext.Provider>
  );
}

// export default Sentry.withProfiler(App);
export default App;
