import { useEffect, useState } from "react";
import { Tabs, Tab, Accordion } from "react-bootstrap";
import CompareFsBalanceSheet from "./CompareFsBalanceSheet";
import CompareFSPnl from "./compareFsPnl";
import CompareFsBalanceNote from "./compareFsBalanceNote";
import CompareFSPnlStatementNotes from "./compareFsPnlNote";
import CompareFsSocie from "./compareFsSocie";
import { getAllIR } from "../../../WorkSpace/Components/ApiServices/apiHandler";

const CompareFsTab = (props) => {
  const [key, setKey] = useState("Balance Sheets");
  const {
    setTempValue,
    balanceSheetData,
    groupingData,
    currentCompany,
    headingData,
    notes,
    currentCompProjectId,
    subsiaryData,
    pnlStatementData,
    allcheckdata,
    consoleNotes,
    setCurrentProfit,
    setCurrentYearProfit,
    setComYearProfit,
    currentProfit,
    setComProfit,
    comYearProfit,
    comProfit,
    currentYearProfit,
    consoleData,
    pnlData,
    setCheckData,
    bsData,
    bsNotes,
    standaloneGroupingData,
    FindIr
  } = props;
  const [checkKey, setDataKey] = useState("");
  const [recentTab, setCurrentTab] = useState("");
  const [tabCheck, setTab] = useState("");

  return (
    <>
      <div>
        <Tabs
          id="uncontrolled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="Balance Sheets" title="Balance Sheets">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              <CompareFsBalanceSheet
                setTempValue={setTempValue}
                balanceSheetData={balanceSheetData}
                groupingData={groupingData}
                currentCompany={currentCompany}
                //   headingData={headingData.map((data) => data?.all_data?.company)}
                notes={notes}
                currentCompProjectId={currentCompProjectId}
                subsiaryData={subsiaryData}
                browsFs={[]}
                setCheckData={setCheckData}
                setKey={setKey}
                setDataKey={setDataKey}
                setCurrentTab={setCurrentTab}
                bsData={bsData}
                FindIr={FindIr}
              />
            </div>
          </Tab>
          <Tab eventKey="P&L Statement1" title="P&L Statement">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              <CompareFSPnl
                setTempValue={setTempValue}
                pnlStatementData={pnlStatementData}
                currentCompany={currentCompany}
                //   headingData={headingData.map((data) => data.all_data.company)}
                currentCompProjectId={currentCompProjectId}
                subsiaryData={allcheckdata}
                consoleNotes={consoleNotes}
                groupingData={groupingData}
                setCurrentProfit={setCurrentProfit}
                setCurrentYearProfit={setCurrentYearProfit}
                setComYearProfit={setComYearProfit}
                currentProfit={currentProfit}
                setComProfit={setComProfit}
                setKey={setKey}
                setDataKey={setDataKey}
                setCurrentTab={setTab}
                pnlData={pnlData}
                FindIr={FindIr}
              />
            </div>
          </Tab>
          <Tab eventKey="cashFlow" title="CashFlow">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              CashFlow
            </div>
          </Tab>
          <Tab eventKey="P&L Statement3" title="SOCIE">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              <CompareFsSocie
                allcheckdata={allcheckdata}
                subsiaryData={allcheckdata}
                currentCompProjectId={currentCompProjectId}
                currentProfit={currentProfit}
                comProfit={comProfit}
                currentYearProfit={currentYearProfit}
                comYearProfit={comYearProfit}
                currentCompany={currentCompany}
                pnlStatementData={pnlStatementData}
                headingData={allcheckdata.map((data) => data.company)}
                groupingData={groupingData}
                consoleNotes={consoleNotes}
                FindIr={FindIr}
              />
            </div>
          </Tab>
          <Tab eventKey="generalNotes" title="General Notes">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}></div>
          </Tab>
          <Tab eventKey="Notes" title="Balance Sheet Notes">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              <CompareFsBalanceNote
                consoleNotes={consoleNotes}
                balanceSheetData={balanceSheetData}
                groupingData={groupingData}
                consoleData={consoleData}
                allcheckdata={allcheckdata}
                tab={recentTab}
                pnlStatementData={pnlStatementData}
                currentCompProjectId={currentCompProjectId}
                checkKey={checkKey}
                subsiaryData={subsiaryData}
                bsNotes={bsNotes}
                standaloneGroupingData={standaloneGroupingData}
                FindIr={FindIr}
              />
            </div>
          </Tab>
          <Tab eventKey="Notes2" title="PNL Statement Notes">
            <div style={{ minHeight: "100vh", backgroundColor: "white" }}>
              <CompareFSPnlStatementNotes
                pnlStatementData={pnlStatementData}
                currentCompany={consoleData}
                currentCompProjectId={currentCompProjectId}
                subsiaryData={allcheckdata}
                consoleNotes={consoleNotes}
                groupingData={groupingData}
                setCurrentProfit={setCurrentProfit}
                setCurrentYearProfit={setCurrentYearProfit}
                setComYearProfit={setComYearProfit}
                currentProfit={currentProfit}
                setComProfit={setComProfit}
                headingData={headingData}
                allcheckdata={allcheckdata}
                tab={tabCheck}
                checkKey={checkKey}
                bsNotes={bsNotes}
                standaloneGroupingData={standaloneGroupingData}
                FindIr={FindIr}
              />
            </div>
          </Tab>

          <Tab eventKey="disclosures" title="Disclosures">
            <div style={{ minHeight: "100%", backgroundColor: "white" }}></div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
export default CompareFsTab;
