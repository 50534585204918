import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveIcon from "@mui/icons-material/Remove";
import "../../../WorkSpace/font.css";
import { useHistory, useLocation } from "react-router-dom";
import { Navbar } from "../../../Components/Navbar";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import swal from "sweetalert";
import { useState,useEffect } from "react";
import EditInvestmentRegister from "./EditInvestRegister";
import {  BrowsFsAdditional, CreateAddinalData,  fetchAddinalData, getCompany, GetConsoleEntryData, GetProjectsBasedOnUserId, getSingleAllDetails,GetConsoleEntryDataAdditional,addAllOtherOptionsAdditional,getAllOtherOptionsAdditional,getAllOtherDetailsAdditional, addOtherDetailsAdditional,deleteAllOtherDetailsAdditional, addAllOtherDetailsAdditional,saveAdditionalIrDetails } from "../ApiServices/apiHandler";
import InvestAdjustModal from "../../../Console/Component/InvestAdjustmentModal";
import BrowsFsModal from "./Modals/BrowsFSModal";
import { toast } from "react-toastify";
import ConsoleEntryModal from "./Modals/ConsoleEntryModal";
import AddOtherDetailsModal from "./Modals/AddOtherDetailsModal";
import AddNewOptionsModal from "./Modals/AddNewOptionsModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdditionalDetailsData } from "../../../redux/actions/InvestmentRegister/actionAdditionalOthersData";
import { fetchAllDetailsData } from "../../../redux/actions/InvestmentRegister/actionAllOthersData";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const companyRelationShip = ["Subsidiary Company", "Associate Company", "Investment"]
const InvestmentRegisterv2 = (props) => {
// new usestates
const user_id = localStorage.getItem("user_id");
const dispatch = useDispatch();
const [details, setDetails] = useState({
  companyCode: "",
  browseFsCompanyId: "",
  details_id: ""
})
const [isBrowsModalOpen, setIsBrowsModalOpen] = useState(false);
const [allProjects, setAllProjects] = useState([]);
const [isConsoleEntryOpen, setIsConsoleEntryOpen] = useState(false);
const [allConsoleEntry, setAllConsoleEntry] = useState([]);
const [isOtherInputOpen, setIsOtherInputOpen] = useState(false);
const [allOtherDetailsInputs, setOtherDetailsInputs] = useState([]);
const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
const [otherOptions, setOtherOptions] = useState([]);
const [options, setOptions] = useState('');
const [consoleInvestmentValue,setConsoleInvestmentValue]=useState(0);
const [allDetailsId,setAllDetailsId]=useState('');
const [showFirstTable, setShowFirstTable] = useState(true);
const [additinalData, setAdditinalData] = useState([]);
const [isAdditional,setIsAdditional]=useState(false);
const [projects,setProjects]=useState([]);

const all_othersData = useSelector((state) => state.AllOtherDetailsReducer);

const additional_othersData = useSelector((state) => state.AdditionalOtherDetailsReducer);

  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);
  const [browseOpen, setBrowseOpen] = useState(false);
  const [eqityshare, setEquityShare] = useState([]);
  const [otherequityshare, setOtherEquityShare] = useState("");
  const [otherreservesurplus, setOtherReserveSurplus] = useState("");
  const [othereserve, setOtherReserve] = useState("");
  const [setfinalsum, setFinalSum] = useState("");
  const [totaldata, setDataTotal] = useState("");
  const [interest, setInterest] = useState("");
  const [entryopenModal, setEntryOpen] = useState(false);
  const [openconsoleentry, setOpenConsoleEntry] = useState(false);
  const [finalnoofshar, setFinalNoOfShare] = useState("");
  const [finalinvestment, setFinalInvestment] = useState("");
  const [noofdevest, setNoOfDevest] = useState("");
  const [allCompany, setAllCompany] = useState([]);
  const [investvalue, setInvestValue] = useState("");
  const [totaleqity, setTotalEquity] = useState(eqityshare * -1);
  const [finalconsoleentry, setFinalConsoleEntry] = useState({});
  const [dilutiondata, setDilutionData] = useState({
    date_of_divest: "",
    no_of_share: "",
    sale: "",
  });
  const [registerdata, setRegisterDAta] = useState([]);
  const [dateofinvest, setDateOfInvest] = useState(
    registerdata?.date_Of_investment
  );
  const [investmentValue, setInvestmentValue] = useState(0);
  const [totalHoldings, setTotalHoldings] = useState(0);
  const [totalShares,setTotalShares]=useState(0);
  const [finalHoldings,setFinalHoldings]=useState(0);
  let no_of_share = Number(registerdata?.no_of_shares) + finalnoofshar;
  let final_investment =
    Number(registerdata?.value_of_investment) + finalinvestment;
  let dataValue = (Number(no_of_share) / Number(final_investment)) * noofdevest;
  let history = useHistory();
  let a = location.pathname;
  let b = a.split("/");
  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
    setBrowseOpen(false);
    setEntryOpen(false);
    setOpenConsoleEntry(false);
  };

  useEffect(() => {
    AllRegisterData();
    fetchAdditionData();
    companyData();
    GetProjects();
  }, []);

  useEffect(() => {
    setTotalHoldings(
      additinalData
        ?.filter(item => item?.investment_company_id === registerdata[0]?.investment_company_id)
        .reduce((acc, item) => acc + parseInt(item?.holdings || 0, 10), 0) +
      parseInt(registerdata[0]?.holdings || 0, 10)
    );
    setTotalShares(
      additinalData
        ?.filter(item => item?.investment_company_id === registerdata[0]?.investment_company_id)
        .reduce((acc, item) => acc + parseInt(item?.no_of_shares || 0, 10), 0) +
      parseInt(registerdata[0]?.no_of_shares || 0, 10)
    );
    
    setFinalHoldings(
      parseInt(registerdata[0]?.holdings || 0, 10) +
      additinalData
        ?.slice(0, -1)
        .filter(item => item?.investment_company_id === registerdata[0]?.investment_company_id)
        .reduce((acc, item) => acc + parseInt(item?.holdings || 0, 10), 0)
    );
    
    
    setInvestmentValue(
      additinalData
        ?.filter(item => item?.investment_company_id === registerdata[0]?.investment_company_id)
        .reduce((acc, item) => acc + parseInt(item?.value_of_investment || 0, 10), 0) +
      parseInt(registerdata[0]?.value_of_investment || 0, 10)
    );
    
  }, [additinalData]);


  const GetProjects = async () => {
    GetProjectsBasedOnUserId(user_id).then(response => {
      setProjects(response.data?.result)
    }).catch(error => {
      console.log("error", error)
    })
  };

  const companyData = async () => {
    try {
      const res = await getCompany();
      if (res.status === 200) {
        setAllCompany(res.data.getCompany); 
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const CompanyName = (c_id) => {
    try {
      const nameCompany = allCompany.filter((adj) => adj._id === c_id);
      const result = nameCompany[0]?.company_name
        ? nameCompany[0]?.company_name
        : "";
      return result;
    } catch (error) {
      return 0;
    }
  };

  const handleBAck = () => {
    history.push(`/detailsIR/${registerdata[0].ir_id}`);
  };

  // New Functionalities
  const AllRegisterData = async () => {
    getSingleAllDetails(b[2]).then(response => {
      setRegisterDAta(response.data.otherData);
      dispatch(fetchAllDetailsData(response.data.otherData[0]._id));
    }).catch(error => {
      console.log("error", error)
    })
  };

  const fetchAdditionData = async () => {
    const id =b[2];
    fetchAddinalData(id).then(response => {
      setAdditinalData(response.data.fetchAddinal);
    }).catch(error => {
      console.log("error", error)
    })
  };

  const HandleChange = (e, index) => {
    const { name, value } = e.target;
    setAdditinalData(prev =>
      prev.map((item, idx) => {
        if (idx !== index) return item;

        const updatedItem = { ...item, [name]: value };

        if (name === "investment_company_id") {
          const resultCompany = allCompany.find(
            (det) => det?._id === value
          );
          updatedItem.investment_company_code = resultCompany?.company_code ?? "";
        }

        if (name === "no_of_shares" || name === "total_no_shares") {
          const noOfShares = name === "no_of_shares"
            ? parseFloat(value)
            : parseFloat(item.no_of_shares);
          const totalNoShares = name === "total_no_shares"
            ? parseFloat(value)
            : parseFloat(item.total_no_shares);

          if (!isNaN(noOfShares) && !isNaN(totalNoShares) && totalNoShares !== 0) {
            const holdings = (noOfShares / totalNoShares) * 100;
            updatedItem.holdings = holdings;
            updatedItem.non_controlling_interest = 100 - holdings;
            if (registerdata[0]?.investment_company_id === item.investment_company_id) {
              if (totalHoldings >= 51) {
                updatedItem.nature_of_relationship = "Subsidiary Company";
              } else if (totalHoldings >= 20 && totalHoldings < 51) {
                updatedItem.nature_of_relationship = "Associate Company";
              } else {
                updatedItem.nature_of_relationship = "Investment";
              }
            }else if (holdings >= 51) {
              updatedItem.nature_of_relationship = "Subsidiary Company";
            } else if (holdings >= 20 && holdings < 51) {
              updatedItem.nature_of_relationship = "Associate Company";
            } else {
              updatedItem.nature_of_relationship = "Investment";
            }
          } else {
            updatedItem.holdings = 0;
            updatedItem.non_controlling_interest = 0;
            updatedItem.nature_of_relationship = "Investment";
          }
        }

        return updatedItem;
      })
    );
  };

  const GetAllConsoleEntry = async (ir) => {
    const data = {
      non_controlling_interest:ir.non_controlling_interest,
      investment_value: -ir.value_of_investment,
    };
    GetConsoleEntryData(ir._id, data)
      .then((response) => {
        const result = response.data?.result;
        setIsConsoleEntryOpen(true);
        setAllConsoleEntry(result?.console_entry);

        setRegisterDAta((prev) =>
          prev.map((item) => {
            if (item._id === result._id) {
              return { ...item, console_entry: result?.console_entry };
            }
            return item;
          })
        );
      })
      .catch((error) => {
        swal(
          "Error",
          error?.response?.data?.error || "something went wrong",
          "error"
        );
      });
  };

  const GetAllProjects = async () => {
    GetProjectsBasedOnUserId(user_id).then(response => {
      setIsBrowsModalOpen(true);
      setAllProjects(response.data?.result)
    }).catch(error => {
      console.log("error", error)
    })
  };

  // add Others Details new Options
  const addNewOptionsDetails = async () => {
    const data = {
      otherDatailsId: allDetailsId,
      options: options
    }
    const res = await addAllOtherOptionsAdditional(data);
    if (res.status === 201) {
      setIsOptionsModalOpen(false);
      setAdditinalData((prev) =>
        prev.map((item) => {
          if (item._id === res.data.result._id) {
            return { ...item, other_details_options: res.data.result?.other_details_options };
          }
          return item;
        })
      );
      getOthersDetailsOptions();
    }
  };
  //get other Details options
  const getOthersDetailsOptions = async () => {
    const res = await getAllOtherOptionsAdditional(allDetailsId);
    if (res.status === 201) {
      setOtherOptions(res.data.options);
    }
  };
  //get all others Details
  const getOthersDetails = async () => {
    const res = await getAllOtherDetailsAdditional(allDetailsId);
    if (res.status === 201) {
      setIsOtherInputOpen(true)
      setOtherDetailsInputs(res.data.other_items);
      getOthersDetailsOptions();
      // dispatch(fetchAdditionalDetailsData(allDetailsId));
    }
  };
  // add  new row in others details
  const addNewOthersDetails = async () => {
    const data = {
      otherDatailsId: allDetailsId,
      other_items: allOtherDetailsInputs
    }
    const res = await addOtherDetailsAdditional(data);
    if (res.status === 201) {
      getOthersDetails();
    }
  };
  //delete others details 
  const deleteOthersDetails = async (index) => {
    const data = {
      otherDatailsId: allDetailsId,
      index: index
    }
    const res = await deleteAllOtherDetailsAdditional(data);
    if (res.status === 201) {
      getOthersDetails();
    }
  };

  // add all others details data
  const addAllOthersDetails = async () => {
    const data = {
      otherDatailsId: allDetailsId,
      other_items: allOtherDetailsInputs
    }
    const res = await addAllOtherDetailsAdditional(data);
    if (res.status === 201) {
      setIsOtherInputOpen(false);
      setAdditinalData((prev) =>
        prev.map((item) => {
          if (item._id === res.data.result._id) {
            return { ...item, other_items: res.data.result?.other_items };
          }
          return item;
        })
      );
      dispatch(fetchAdditionalDetailsData(allDetailsId));
    }
  };

  const ProjectName = (p_id) => {
    const ProjectData = projects.find((adj) => adj._id === p_id);
    return ProjectData?.project_name || '';
  };

  const handleChangeInputs=(e)=>{
    setConsoleInvestmentValue(e.target.value);
  }

  const handleNext = (ir) => {
    const data = {
      non_controlling_interest:registerdata[0]?.investment_company_id === ir.investment_company_id?100-totalHoldings:ir.non_controlling_interest,
      investment_value:registerdata[0]?.investment_company_id === ir.investment_company_id?-(Number(investmentValue) + Number(consoleInvestmentValue)):-(Number(ir.value_of_investment) + Number(consoleInvestmentValue)),
    };

    GetConsoleEntryDataAdditional(ir._id, data)
      .then((response) => {
        const result = response.data?.result;
        setShowFirstTable(false);
        setAllConsoleEntry(result?.console_entry);

        setAdditinalData((prev) =>
          prev.map((item) => {
            if (item._id === result._id) {
              return { ...item, console_entry: result?.console_entry };
            }
            return item;
          })
        );
      })
      .catch((error) => {
        swal(
          "Error",
          error?.response?.data?.error || "something went wrong",
          "error"
        );
      });
  };

  const handleSubmitFs = () => {
    const data = {
      project_id: details.browseFsCompanyId,
      details_id: details.details_id
    }

    BrowsFsAdditional(data).then(response => {
      toast.success("Brows FS mapped successfully.");
      setIsBrowsModalOpen(false);
      GetProjects();
      setAdditinalData((prev) =>
        prev.map((item) => {
          if (item._id === response.data?.result._id) {
            return { ...item, prepare_preset_project_id: response.data?.result.prepare_preset_project_id };
          }
          return item;
        })
      );
    }).catch(error => {
      console.log("browsfs error", error)
    })
  };

  const GetAllConsoleEntryAdditional = async (ir) => {
    const data = {
      non_controlling_interest:registerdata[0]?.investment_company_id === ir.investment_company_id?100-totalHoldings:ir.non_controlling_interest,
      investment_value:registerdata[0]?.investment_company_id === ir.investment_company_id? -investmentValue:-ir.value_of_investment,
    };

    GetConsoleEntryDataAdditional(ir._id, data)
      .then((response) => {
        const result = response.data?.result;
        setIsConsoleEntryOpen(true);
        setAllConsoleEntry(result?.console_entry);

        setAdditinalData((prev) =>
          prev.map((item) => {
            if (item._id === result._id) {
              return { ...item, console_entry: result?.console_entry };
            }
            return item;
          })
        );
      })
      .catch((error) => {
        swal(
          "Error",
          error?.response?.data?.error || "something went wrong",
          "error"
        );
      });
  };

  function handleChangeDetails(e, field, i) {
    const { value } = e.target;
    if (value === "Add_new") {
      setIsOptionsModalOpen(true);
    } else {
      const newArray = allOtherDetailsInputs.map((item, index) => {
        if (i === index) {
          return { ...item, [field]: value };
        }
        return item;
      });
      setOtherDetailsInputs(newArray);
    }
  }

  const createNewAdditional = async () => {
    const data = {
      register_id:b[2] ,
      cid: registerdata[0]?.investment_company_id
    }
    const res = await CreateAddinalData(data);
    if (res.status === 200) {
      // history.push(`/additionInvestregister/${res.data.result._id}`)
      fetchAdditionData();
    }
  };

  const saveAllAdditionalIrsDetails = async () => {
    const data = {
      register_id: b[2],
      allIrData: additinalData,
      ir_id:registerdata[0].ir_id
    }
    const res = await saveAdditionalIrDetails(data);
    if (res.status === 200) {
      fetchAdditionData();
      AllRegisterData();
      history.push(`/detailsIR/${registerdata[0].ir_id}`);
      swal("Success", "All IR Data Save Successfully", 'success');
    }
  };


  return (
    <>
      <div className="pb-5">
        <Navbar />

        <div className="container mt-4">
          <div className="d-flex">
            <p className="fs-inter" style={{ color: "#343434" }}>
              HOME / EDIT IR /&nbsp;
              <span style={{ fontWeight: "bold", color: "#000000" }}>
              {CompanyName(registerdata[0]?.investment_company_id)}
              </span>
            </p>
          </div>
          <div className="d-flex">
            <div className="mt-1">
              <h5 className="fs-inter ir-heading">
                Investments for {CompanyName(registerdata[0]?.investment_company_id)} here
              </h5>
            </div>
            <div className="invbtndiv">
              <Button
                style={{
                  border: "2px solid #03565A",
                  color: "#03565A",
                  textTransform: "none",
                  backgroundColor: "white",
                }}
                onClick={() =>createNewAdditional() }
              >
                <AddIcon />
                Add
              </Button>

              <Button
                style={{
                  border: "2px solid #03565A",
                  marginLeft: "14px",
                  color: "#03565A",
                  textTransform: "none",
                  backgroundColor: "white",
                }}
                onClick={handleOpen}
              >
                <RemoveIcon />
                Dilute
              </Button>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-8">
              {registerdata.length > 0 &&
                registerdata.map((item, i) => (
                  <>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{ marginLeft: "22px" }}>
                            <small className="fs-inter ir-con-head">
                              TRANCH <b>{i+1}</b>{" "}
                              {item.date_Of_investment}
                            </small>
                            <br />
                            <p className="dm-sans ir-heading2">
                              Investment value:{" "}
                              {Math.round(item.value_of_investment)}
                            </p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="row">
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  investee company name
                                  <select
                                    style={{
                                      width: "336px",
                                      marginTop: "12px",
                                      borderRadius: "5px",
                                      height: "59px",
                                    }}
                                    name="investment_company_id"
                                    onChange={(e) => HandleChange(e, i)}
                                    disabled
                                  >
                                     <option>Select...</option>
                                    {allCompany.length > 0 &&
                                      allCompany.map((det) => {
                                        return (
                                          <>
                                            <option
                                              value={det._id}
                                              selected={
                                                item?.investment_company_id === det._id
                                              }
                                            >
                                              {det.company_name}
                                            </option>
                                          </>
                                        );
                                      })}
                                  </select>
                                </label>
                              </div>
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  Company code
                                  <input
                                    type="text"
                                    value={item?.investment_company_code}
                                    disabled
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-12">
                                <label className="ircon-head">
                                  Date of investment
                                  <input
                                    type="date"
                                    className="form-control"
                                    defaultValue={item.date_Of_investment}
                                    onChange={(e) => HandleChange(e, i)}
                                    name="date_Of_investment"
                                    disabled
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <label className="ircon-head">
                                  Value of investment
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={item.value_of_investment}
                                  onChange={(e) => HandleChange(e, i)}
                                  name="value_of_investment"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  No of shares bought
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={item.no_of_shares}
                                    name="no_of_shares"
                                    onChange={(e) => HandleChange(e, i)}
                                    disabled
                                  />
                                </label>
                              </div>
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  Total no shares in the company
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={item.total_no_shares}
                                    name="total_no_shares"
                                    onChange={(e) => HandleChange(e, i)}
                                    disabled
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  Percentage holding
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="holdings"
                                    value={item.holdings.toLocaleString("en-IN")}
                                    disabled
                                  />
                                </label>
                              </div>
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  Non-controling interest Percentage
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="non_controlling_interest"
                                    value={item.non_controlling_interest.toLocaleString("en-IN")}
                                    disabled
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-12">
                                <label className="text-secondary">
                                  Nature of relationship
                                </label>

                                <select
                                  style={{
                                    width: "100%",
                                    height: "59px",
                                    borderRadius: "5px",
                                  }}
                                  name="nature_of_relationship"
                                  disabled
                                >
                                  <option>Select</option>
                                  {
                                  companyRelationShip.map(nr => (
                                    <option
                                      value={nr}
                                      selected={item.nature_of_relationship === nr}
                                    >
                                      {nr}
                                    </option>
                                  ))
                                }
                                </select>
                              </div>
                            </div>
                            {
                             item.nature_of_relationship==="Subsidiary Company" && (
                              <div className="row mt-3">
                                <div
                                  className="col-md-6"
                                >
                                  <button
                                    onClick={() => {
                                      GetAllConsoleEntry(item)
                                      setIsAdditional(false)
                                    }}
                                    
                                    className="px-2 text-white shadow py-2 w-100 rounded custom-border custom-common-theme-bg-color"
                                  >
                                    Create consol entry
                                  </button>
                                </div>

                                <div className="col-md-6">
                                  <button
                                    onClick={() => {
                                      setDetails(prev => ({ ...prev, details_id: item._id }))
                                      GetAllProjects();
                                    }}
                                    className="px-2 text-white shadow py-2 w-100 rounded custom-border custom-common-theme-bg-color"
                                    disabled
                                  >
                                    Browse control date FS
                                  </button>
                                  <span> {ProjectName(item?.prepare_preset_project_id)}</span>
                                </div>
                              </div>
                            )
                          }

                          {item.nature_of_relationship==="Subsidiary Company" && (
                            <div className="row  mt-3">
                              <div className="col-md-12 ">
                                <label className="dm-sans custom-font-700 font-size-14 text-secondary mb-0">Details of net-worth on the date of control </label>
                                <input
                                  type="text"
                                  className="rounded"
                                  disabled
                                />
                              </div>

                              <div className="col-md-12 mt-1">
                                <button
                                  onClick={() => {
                                    setAllDetailsId(item._id);
                                    getOthersDetails();
                                  }}
                                  disabled
                                  className="border-0 bg-color-inherit mt-0 text-secondary d-flex justify-content-center align-items-center"
                                >
                                  Add other details
                                  <AddIcon />
                                </button>
                              </div>
                            </div>
                          )}
                           <div className="row mt-2">
                            <div className="col-md-12">
                              <label className="dm-sans custom-font-700 font-size-14 text-secondary mb-0">
                                Whether transaction is covered as common control transaction
                              </label>
                              <select
                                className="rounded"
                                style={{
                                  width: "691px",
                                  height: "59px",
                                }}
                                disabled
                              >
                                <option>Select....</option>
                                <option>Yes</option>
                                <option>No</option>
                              </select>
                            </div>
                            <div className="col-md-12 mt-3">
                              {item.nature_of_relationship==="Subsidiary Company" && (
                                <div className="table-responsive table-bordered">
                                  <table className="table" >
                                    <tbody>
                                      {all_othersData?.response && all_othersData?.response.length > 0 &&
                                        all_othersData?.response.map((item, v) => (
                                          <tr key={v}>
                                            <td>{item.select}</td>
                                            <td>{item.value}</td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                  </>
                ))}
            </div>
            
            <div className="col-md-8 mt-1">
            {additinalData.length > 0 &&
                additinalData.map((item, i) => (
                  <div className="mt-2">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{ marginLeft: "22px" }}>
                            <small className="fs-inter ir-con-head">
                              TRANCH <b>{i+2}</b>{" "}
                              {item.date_Of_investment}
                            </small>
                            <br />
                            <p className="dm-sans ir-heading2">
                              Investment value:{" "}
                              {Math.round(item.value_of_investment)}
                            </p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="row">
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  investee company name
                                  <select
                                    style={{
                                      width: "336px",
                                      marginTop: "12px",
                                      borderRadius: "5px",
                                      height: "59px",
                                    }}
                                    name="investment_company_id"
                                    onChange={(e) => HandleChange(e, i)}

                                  >
                                     <option>Select...</option>
                                    {allCompany.length > 0 &&
                                      allCompany.map((det) => {
                                        return (
                                          <>
                                            <option
                                              value={det._id}
                                              selected={
                                                item?.investment_company_id === det._id
                                              }
                                            >
                                              {det.company_name}
                                            </option>
                                          </>
                                        );
                                      })}
                                  </select>
                                </label>
                              </div>
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  Company code
                                  <input
                                    type="text"
                                    value={item?.investment_company_code}

                                  />
                                </label>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-12">
                                <label className="ircon-head">
                                  Date of investment
                                  <input
                                    type="date"
                                    className="form-control"
                                    defaultValue={item.date_Of_investment}
                                    onChange={(e) => HandleChange(e, i)}
                                    name="date_Of_investment"

                                  />
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <label className="ircon-head">
                                  Value of investment
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={item.value_of_investment}
                                  onChange={(e) => HandleChange(e, i)}
                                  name="value_of_investment"
                                />
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  No of shares bought
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={item.no_of_shares}
                                    name="no_of_shares"
                                    onChange={(e) => HandleChange(e, i)}
                                  />
                                </label>
                                {registerdata[0]?.investment_company_id === item.investment_company_id &&
                                <label>
                                  {`Total no of shares = ${additinalData.filter(item => item?.investment_company_id === registerdata[0]?.investment_company_id).map(item => item?.no_of_shares).join(' + ')} + ${registerdata[0]?.no_of_shares} = ${totalShares}`}
                                </label>
                                }
                              </div>
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  Total no shares in the company
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={item.total_no_shares}
                                    name="total_no_shares"
                                    onChange={(e) => HandleChange(e, i)}

                                  />
                                </label>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  Percentage holding
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="holdings"
                                    value={registerdata[0]?.investment_company_id === item.investment_company_id ? totalHoldings :item.holdings}
                                    disabled
                                  />
                                </label>
                              </div>
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  Non-controling interest Percentage
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="non_controlling_interest"
                                    value={registerdata[0]?.investment_company_id === item.investment_company_id ? 100-totalHoldings:item.non_controlling_interest}
                                    disabled
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-12">
                                <label className="text-secondary">
                                  Nature of relationship
                                </label>

                                <select
                                  style={{
                                    width: "100%",
                                    height: "59px",
                                    borderRadius: "5px",
                                  }}
                                  name="nature_of_relationship"
                                  disabled={false}
                                  value={ item.nature_of_relationship}
                                  onChange={(e) => HandleChange(e, i)}
                                >
                                  <option>Select</option>
                                  {
                                  companyRelationShip.map(nr => (
                                    <option value={nr}>
                                      {nr}
                                    </option>
                                  ))
                                }
                                </select>
                              </div>
                            </div>
                            {
                           ((registerdata[0]?.investment_company_id === item.investment_company_id && item.nature_of_relationship==="Subsidiary Company") || item.nature_of_relationship==="Subsidiary Company") && (
                              <div className="row mt-3">
                                <div
                                  className="col-md-6"
                                >
                                  <button
                                    onClick={() => {
                                      GetAllConsoleEntryAdditional(item)
                                      setIsAdditional(registerdata[0]?.investment_company_id === item.investment_company_id);
                                    }}
                                    className="px-2 text-white shadow py-2 w-100 rounded custom-border custom-common-theme-bg-color"
                                  >
                                    Create consol entry
                                  </button>
                                </div>

                                <div className="col-md-6">
                                  <button
                                    onClick={() => {
                                      setDetails(prev => ({ ...prev, details_id: item._id }))
                                      GetAllProjects();
                                    }}
                                    className="px-2 text-white shadow py-2 w-100 rounded custom-border custom-common-theme-bg-color"
                                  >
                                    Browse control date FS
                                  </button>
                                  <span> {ProjectName(item?.prepare_preset_project_id)}</span>
                                </div>
                              </div>
                            )
                          }

                          {((registerdata[0]?.investment_company_id === item.investment_company_id && item.nature_of_relationship==="Subsidiary Company") || item.nature_of_relationship==="Subsidiary Company") && (
                            <div className="row  mt-3">
                              <div className="col-md-12 ">
                                <label className="dm-sans custom-font-700 font-size-14 text-secondary mb-0">Details of net-worth on the date of control </label>
                                <input
                                  type="text"
                                  className="rounded"
                                />
                              </div>

                              <div className="col-md-12 mt-1">
                                <button
                                  onClick={() => {
                                    setAllDetailsId(item._id);
                                    getOthersDetails();
                                  }}
                                  className="border-0 bg-color-inherit mt-0 text-secondary d-flex justify-content-center align-items-center"
                                >
                                  Add other details
                                  <AddIcon />
                                </button>
                              </div>
                            </div>
                          )}
                           <div className="row mt-2">
                            <div className="col-md-12">
                              <label className="dm-sans custom-font-700 font-size-14 text-secondary mb-0">
                                Whether transaction is covered as common control transaction
                              </label>
                              <select
                                className="rounded"
                                style={{
                                  width: "691px",
                                  height: "59px",
                                }}
                              >
                                <option>Select....</option>
                                <option>Yes</option>
                                <option>No</option>
                              </select>
                            </div>
                            <div className="col-md-12 mt-3">
                              {((registerdata[0]?.investment_company_id === item.investment_company_id && item.nature_of_relationship==="Subsidiary Company") || item.nature_of_relationship==="Subsidiary Company") && (
                                <div className="table-responsive table-bordered">
                                  <table className="table" >
                                    <tbody>
                                      {additional_othersData?.response && additional_othersData?.response.length > 0 &&
                                        additional_othersData?.response.map((item, v) => (
                                          <tr key={v}>
                                            <td>{item.select}</td>
                                            <td>{item.value}</td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                  </div>
                ))}
            </div>
          </div>
          <hr style={{ width: "731px" }} />

          <div className="mt-4 row">
            <div className="col-md-8">
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    <label>
                      <p className="dm-sans ir-remark">Add remarks</p>
                    </label>
                    <input type="text" style={{ height: "110px" }} />
                  </Typography>
                </CardContent>
                <div
                  className="dm-sans ir-remark"
                  style={{ marginLeft: "20px" }}
                >
                  UploadAttachments
                </div>
                <CardActions>
                  <button
                    style={{
                      backgroundColor: "white",
                      color: "#03565A",
                      textTransform: "none",
                      marginLeft: "12px",
                      width: "220px",
                      height: "50px",
                      border: "2px solid #03565A",
                      borderRadius: "5px",
                      marginTop: "9px",
                    }}
                  >
                    Upload Attachment
                  </button>
                </CardActions>
              </Card>
            </div>
          </div>

          <div
            className="backicon"
            style={{ cursor: "pointer", marginLeft: "0px" }}
            onClick={() => handleBAck()}
          >
            <ArrowBackIcon />
            &nbsp;
            <div>Go Back</div>
          </div>
          <div
            className="nextbtn-v2"
            style={{ marginLeft: "-325px", marginTop: "-42px" }}
          >
            <Button
              style={{
                backgroundColor: "#03565A",
                color: "white",
                border: "2px solid #03565A",
                textTransform: "none",
                borderRadius: "5px",
                height: "54px",
              }}
              onClick={saveAllAdditionalIrsDetails}
            >
              <AddIcon />
              Save Details
            </Button>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="col-12">
            <input
              type="button"
              className="btn btn-defult"
              value="X"
              onClick={handleClose}
              style={{ float: "right" }}
            />
            <div className="col-12">
              <h5>Dilution</h5>
              <div className="form-group mb-1 col-12">
                <label>Date of divestment</label>
                <input
                  type="date"
                  className="form-control"
                  value={dilutiondata.date_of_divest}
                  // onChange={(e) => handleDilutionChange(e, "date_of_divest")}
                />
              </div>
              <div className="form-group mb-1 col-12">
                <label>No. of shares divested</label>
                <input
                  type="text"
                  className="form-control"
                  value={dilutiondata.no_of_share}
                  // onChange={(e) => handleDilutionChange(e, "no_of_share")}
                />
              </div>
              <div className="form-group mb-1 col-12">
                <label>Sale value</label>
                <input
                  type="text"
                  className="form-control"
                  value={dilutiondata.sale}
                  // onChange={(e) => handleDilutionChange(e, "sale")}
                />
              </div>
              <div className="form-group mb-1 col-12">
                <label>Cost of Investment</label>
                <input
                  type="text"
                  className="form-control"
                  value={dataValue}
                  readOnly
                />
              </div>
              <div className="form-group mb-1 col-12">
                <input
                  type="button"
                  className="btn btn-primary1"
                  value="Submit"
                  // onClick={() => handleDelution()}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <InvestAdjustModal
        handleClose={handleClose}
        entryopen={entryopenModal}
        eqityshare={eqityshare}
        otherequityshare={otherequityshare}
        investvalue={investvalue}
        totaleqity={totaleqity}
        interest={interest}
        setfinalsum={setfinalsum}
        setFinalConsoleEntry={setFinalConsoleEntry}
        otherreservesurplus={otherreservesurplus}
        othereserve={othereserve}
        setEntryOpen={setEntryOpen}
        dateofinvest={dateofinvest}
      />
      <BrowsFsModal
        setIsBrowsModalOpen={setIsBrowsModalOpen}
        isBrowsModalOpen={isBrowsModalOpen}
        allProjects={allProjects}
        details={details}
        setDetails={setDetails}
        handleSubmitFs={handleSubmitFs}
      />

      <ConsoleEntryModal
        isConsoleEntryOpen={isConsoleEntryOpen}
        setIsConsoleEntryOpen={setIsConsoleEntryOpen}
        allConsoleEntry={allConsoleEntry}
        allRegisterData={null}  // code change needed
        handleChangeInputs={handleChangeInputs}
        consoleInvestmentValue={consoleInvestmentValue}
        handleNext={handleNext}
        showFirstTable={showFirstTable}
        setShowFirstTable={setShowFirstTable}
        additinalData={additinalData}
        isAdditional={isAdditional}
        finalHoldings={finalHoldings}
      />

      <AddOtherDetailsModal
        setIsOtherInputOpen={setIsOtherInputOpen}
        isOtherInputOpen={isOtherInputOpen}
        allOtherDetailsInputs={allOtherDetailsInputs}
        allotherdata={otherOptions}
        handleChangeDetails={handleChangeDetails}
        handleNew={addNewOthersDetails}
        handleDelete={deleteOthersDetails}
        handleSave={addAllOthersDetails}
      />

      <AddNewOptionsModal
        isOptionsModalOpen={isOptionsModalOpen}
        setIsOptionsModalOpen={setIsOptionsModalOpen}
        setOptions={setOptions}
        saveData={addNewOptionsDetails}
      />
    </>
  );
};

export default InvestmentRegisterv2;
