import { libraryData } from "../../data/library";

const ActionTypes = {
    IND_AS_1_NOTE_NO: "IND_AS_1_NOTE_NO",
    IND_AS_1_FROM_BS_PNL: "IND_AS_1_FROM_BS_PNL",
};

const initialState = {
    indAs1NoteNo: 0,
    indAs1CateData: libraryData[0].category,
    session_data: "section-0",
};

const libraryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.IND_AS_1_NOTE_NO:
            return {
                ...state,
                indAs1NoteNo: action.payload,
            };
        case ActionTypes.IND_AS_1_FROM_BS_PNL:
            const filterIndex = libraryData.findIndex((element) => element.name.toLowerCase() === action.payload.toLowerCase());
            const result1 = libraryData[filterIndex].category
                .find(section => section.data.some(item => item.reference === action.payload?.refNo)) || null;
            const find_index = libraryData[filterIndex].category.findIndex(item => item === result1);
            return {
                ...state,
                indAs1NoteNo: filterIndex > -1 ? filterIndex : state.indAs1NoteNo,
                indAs1CateData: filterIndex > -1 ? libraryData[filterIndex].category : state.indAs1CateData,
                session_data: `section-${find_index}`
            };
        default:
            return state;
    }
};

export default libraryReducer;