import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import moment from "moment";
import { lineGroupStyle, totalStyle, totaledStyle, NoteStyle } from "../Styles/style";
import { CircularProgress } from "@mui/material";
import { getProjectDetailById } from "../services/apiHandler";
import { getGroupName, getNoteNumber, getPatternType, getRandomKey, getScenario, groupDataCal, manualAutoAdjustment, prepareAdjustmentData, prepareAutoPopulationData } from "../../../helper/calculation";
import { useSelector } from "react-redux";
import { CurrentProjectCy, CurrentProjectPy } from "../../../helper/pnlCalculation";
import "../../../App.css"

const groupAline = {
  textAlign: "left",
  paddingLeft: "2rem",
};


function CompareFSPnl(props) {
  const { pnlStatementData, subsiaryData, currentCompany, groupingData, consoleNotes, currentCompProjectId, setKey, setDataKey, setTab, pnlData, FindIr } = props;
  const { loading, isSuccess, response, error_message } = pnlStatementData;

  const finalSubsidiaryData = response?.data?.project_ids_pnl?.map((data) => data.pnl_statement) ?? []
  const finalBrowsFsData = response?.data?.browse_fs_pnl?.map((data) => data.pnl_statement) ?? []
  const browsFs = response.data?.pnl_statement?.pnl_statement ?? [];
  const currentProjectPnl = response.data?.pnl_statement?.pnl_statement ?? [];
  const AdjData = useSelector((state) => state.ADJReducer);
  const preAdjustment = useSelector((state) => state.PADJReducer.response);
  const preAdjustmentOther = useSelector((state) => state.PADJReducer.otherResponse);
  const APAdjustment = useSelector((state) => state.APAReducer);
  const [no, setNo] = useState([]);
  const fsRows = Object.keys(currentProjectPnl);
  const [currentProjectName, setCurrentProjectName] = useState("");
  useEffect(() => {
    getCurrentDataName(currentCompProjectId)
    let noArr = [];
    fsRows.map((row, i) => {
      currentProjectPnl[row].map((line, ix) => {
        if (
          noArr.length > 0 &&
          noArr.some(
            (user) =>
              user.notes_grp.toLowerCase() === line.notes_grp.toLowerCase()
          )
        ) {
        } else {
          noArr.push({ notes_grp: line.notes_grp });
        }
      });
    });
    setNo(noArr);
  }, [currentProjectPnl]);

  function handleSelect(key) {
    // props.setKey('Notes2')
    // props.scrollToTop()
    // props.setNote(key)
    // props.setTempValue(key);
    setKey("Notes2");
    setDataKey(key);
    setTab("pnl");
    // dispatch({type: actionTypes.SET_PLNO,payload:key})
  }

  const getCurrentDataName = async (currentCompProjectId) => {
    const res = await getProjectDetailById(currentCompProjectId);
    setCurrentProjectName(res.data.project.project_name);
  }

  const calculateGroupPnlCy = (group, total_tax = false) => {
    try {
      return finalSubsidiaryData.map((pnl_statement, sub_index) =>
        grandTotalProjectPnlCy(group, sub_index, total_tax)
      ).reduce((data, num) => data + num, 0)
    } catch (error) {
      // console.log(error.message, "error gcy")
      return 0
    }
  }

  const calculateGroupPnlPy = (group, total_tax = false) => {
    try {
      return finalSubsidiaryData.map((pnl_statement, sub_index) =>
        grandTotalProjectPnlPy(group, sub_index, total_tax)
      ).reduce((data, num) => data + num, 0)
    } catch (error) {
      // console.log(error.message, "error gcy")
      return 0
    }
  }

  const getProjectCy = (BrowsFsData, project_data, sub_index) => {
    try {
      const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData, sub_index, FindIr);
      const projectCy = project_data.cy_amt ?? 0;
      const browseFsCy = BrowsFsData.map(browse_item => {
        if (browse_item.group === project_data.group) {
          return browse_item.cy_amt;
        } else {
          return 0;
        }
      }).reduce((num, acc) => num + acc, 0);
      if (first_scenario) {
        return projectCy - browseFsCy;
      } else if (second_scenario) {
        return projectCy;
      } else {
        return projectCy
      }
    } catch (error) {

    }
  }

  const getProjectPy = (BrowsFsData, project_data, sub_index) => {
    try {
      const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData, sub_index, FindIr);
      const projectCy = project_data.py_amt ?? 0;
      const browseFsCy = BrowsFsData.map(browse_item => {
        if (browse_item.group === project_data.group) {
          return browse_item.cy_amt;
        } else {
          return 0;
        }
      }).reduce((num, acc) => num + acc, 0);
      if (first_scenario) {
        return 0;
      } else if (second_scenario) {
        return projectCy - browseFsCy;
      } else {
        return projectCy
      }
    } catch (error) {

    }
  }

  const ProjectIdPnlCy = (sub_index, note_group, note_index, total_tax = false) => {
    try {
      if (note_group) {
        let note_no = getNoteNumber(consoleNotes, note_index);
        if (note_no === "--") { return "..."; }
        const { Projects, BrowsFsData } = groupDataCal(groupingData, note_no);
        const result = Projects[sub_index].flat().map((project_data) => {
          return getProjectCy(BrowsFsData[sub_index].flat(), project_data, sub_index);
        }).reduce((num, acc) => num + acc, 0);
        return result;
      }
      return "loading..."
    } catch (error) {
      // console.log("error project pnl", error.message)
      return '00'
    }
  }

  const ProjectIdPnlPy = (sub_index, note_group, note_index, total_tax = false) => {
    try {
      if (note_group) {
        let note_no = getNoteNumber(consoleNotes, note_index);
        if (note_no === "--") { return "..."; }
        const { Projects, BrowsFsData } = groupDataCal(groupingData, note_no);
        const result = Projects[sub_index].flat().map((project_data) => {
          return getProjectPy(BrowsFsData[sub_index].flat(), project_data, sub_index);
        }).reduce((num, acc) => num + acc, 0);
        return result;
      }
      return "loading py.."
    } catch (error) {
      // console.log("error project pnl", error.message)
      return '00'
    }
  }

  const calculateTotalCy = (noteGroup) => {
    return finalSubsidiaryData.map((project_pnl, sub_index) =>
      ProjectIdPnlCy(sub_index, noteGroup.notes_grp, getNoteNoIndex(noteGroup.notes_no))
    ).reduce((data, num) => data + num, 0)
  }

  const calculateTotalPy = (noteGroup) => {
    return finalSubsidiaryData.map((project_pnl, sub_index) =>
      ProjectIdPnlPy(sub_index, noteGroup.notes_grp, getNoteNoIndex(noteGroup.notes_no))
    ).reduce((data, num) => data + num, 0)
  }

  const grandTotalProjectPnlCy = (group, sub_index, total_tax = false) => {
    if (group === "Income" || group === "Exceptional items" || group === "Profit / (loss) from discontinued operations" || group === "Other Comprehensive Income") {
      const result = currentProjectPnl[group].map((noteGroup) => {
        return ProjectIdPnlCy(sub_index, noteGroup.notes_grp, getNoteNoIndex(noteGroup.notes_no))
      }).reduce((num, acc) => num + acc, 0);
      return result;
    } else if (group === "Expenses") {
      const result = currentProjectPnl[group].map((noteGroup) => {
        if (total_tax) {
          if (noteGroup.notes_grp === "Current tax" || noteGroup.notes_grp === "Deferred tax") {
            return ProjectIdPnlCy(sub_index, noteGroup.notes_grp, getNoteNoIndex(noteGroup.notes_no))
          } else {
            return 0;
          }
        } else {
          if (noteGroup.notes_grp === "Current tax" || noteGroup.notes_grp === "Deferred tax") {
            return 0;
          } else {
            return ProjectIdPnlCy(sub_index, noteGroup.notes_grp, getNoteNoIndex(noteGroup.notes_no))
          }
        }

      }).reduce((num, acc) => num + acc, 0);
      return result;
    }
    return "loading..."
  }
  const grandTotalProjectPnlPy = (group, sub_index, total_tax = false) => {
    if (group === "Income" || group === "Exceptional items" || group === "Profit / (loss) from discontinued operations" || group === "Other Comprehensive Income") {
      const result = currentProjectPnl[group].map((noteGroup) => {
        return ProjectIdPnlPy(sub_index, noteGroup.notes_grp, getNoteNoIndex(noteGroup.notes_no))
      }).reduce((num, acc) => num + acc, 0);
      return result;
    } else if (group === "Expenses") {
      const result = currentProjectPnl[group].map((noteGroup) => {
        if (total_tax) {
          if (noteGroup.notes_grp === "Current tax" || noteGroup.notes_grp === "Deferred tax") {
            return ProjectIdPnlPy(sub_index, noteGroup.notes_grp, getNoteNoIndex(noteGroup.notes_no))
          } else {
            return 0;
          }
        } else {
          if (noteGroup.notes_grp === "Current tax" || noteGroup.notes_grp === "Deferred tax") {
            return 0;
          } else {
            return ProjectIdPnlPy(sub_index, noteGroup.notes_grp, getNoteNoIndex(noteGroup.notes_no))
          }
        }

      }).reduce((num, acc) => num + acc, 0);
      return result;
    }
    return "loading..."
  }

  const getIndexNumber = (line) => {
    try {
      let index = no.findIndex((note) => note?.notes_grp.toLowerCase() === line?.notes_grp.toLowerCase());
      return index;

    } catch (err) {
      return "00"
    }

  }

  const getNoteNoIndex = (notes_no) => {
    try {
      let resultIndex = 0;
      Object.keys(consoleNotes).forEach(function (key, index2) {
        let index = consoleNotes[key].find(data => data.note_number === notes_no);
        if (index !== undefined) {
          resultIndex = index.index;
        }
      });
      return resultIndex
    } catch (error) {
      // console.log("error", error.message)
      return "00"
    }
  }

  const adjustmentResult = (groupDataCal, adjustmentData, autoPopulateData, group_index, scenario, project_index, showManualOnly, pattern_type, groupName) => {
    try {
      const { BrowsFsData } = groupDataCal;
      const scenarioResult = subsiaryData.map((sub_data, sub_index) => {
        return {
          scenario: getScenario(currentCompany, subsiaryData, sub_index, FindIr),
          brows_fs: BrowsFsData[sub_index][group_index][project_index],
          adjustment: adjustmentData?.filter((adj) => adj.company_id === sub_data?.all_data?._id),
          autoPopulate: autoPopulateData?.filter((adj) => adj.company_id === sub_data?.all_data?._id)
        }
      });
      const browsDataRes = scenarioResult.filter(data => data.brows_fs !== undefined);
      const browsDataRes_other = scenarioResult.filter(data => data);
      if (browsDataRes.length === 0) {
        return 0
      }
      const pre_adjustment = getPreAdjustment(scenarioResult, browsDataRes, browsDataRes_other, scenario, pattern_type, groupName);
      const manual_auto_adjustment = manualAutoAdjustment(scenarioResult, browsDataRes_other, scenario);
      return pre_adjustment + manual_auto_adjustment;
    } catch (error) {
      // console.log("err adjustmentResult", error.message);
      return 0
    }
  }

  const getPreAdjustment = (scenarioResult, browsDataRes, browsDataRes_other, scenario, pattern_type, groupName) => {
    let first_scenario_data = 0;
    let second_scenario_data = 0;
    let third_scenario_data = 0;

    if (preAdjustment.includes(browsDataRes[0].brows_fs.group)) {
      const first_scenario_res = scenarioResult.filter((data) => data.scenario.first_scenario === true);
      const first_result = first_scenario_res.map(data => data.brows_fs.cy_amt).reduce((data, num) => data + num, 0);
      first_scenario_data += first_result;

      const second_scenario_res = scenarioResult.filter((data) => data.scenario.second_scenario === true);
      const second_result = second_scenario_res.map(data => data.brows_fs.cy_amt).reduce((data, num) => data + num, 0);
      second_scenario_data += second_result;
      first_scenario_data += second_result;

      const third_scenario_res = scenarioResult.filter((data) => data.scenario.first_scenario !== true && data.scenario.second_scenario !== true);
      const third_result = third_scenario_res.map(data => data.brows_fs.cy_amt).reduce((data, num) => data + num, 0);
      third_scenario_data += third_result;
      second_scenario_data += third_result;
      first_scenario_data += third_result;
    } else if (preAdjustmentOther.map(item => item.sub_group).includes(browsDataRes[0].brows_fs.group)) {
      const cal_data = preAdjustmentOther.find(item => item.sub_group === browsDataRes[0].brows_fs.group).cal_data;
      const first_scenario_res = scenarioResult.filter((data) => data.scenario.first_scenario === true);
      const first_result = first_scenario_res.map(data => Number(data[cal_data])).reduce((data, num) => data + num, 0);
      first_scenario_data += first_result;

      const second_scenario_res = scenarioResult.filter((data) => data.scenario.second_scenario === true);
      const second_result = second_scenario_res.map(data => Number(data[cal_data])).reduce((data, num) => data + num, 0);
      second_scenario_data += second_result;
      first_scenario_data += second_result;

      const third_scenario_res = scenarioResult.filter((data) => data.scenario.first_scenario !== true && data.scenario.second_scenario !== true);
      const third_result = third_scenario_res.map(data => Number(data[cal_data])).reduce((data, num) => data + num, 0);
      third_scenario_data += third_result;
      second_scenario_data += third_result;
      first_scenario_data += third_result;
    }
    const signChangeGroup = ["Equity", "Non Current Liabilities", "Current Liabilities", "Income"]
    switch (scenario) {
      case 1: {
        if (pattern_type === "pattern_2") {
          return first_scenario_data
        } else {
          if (signChangeGroup.includes(groupName) || browsDataRes_other[0].brows_fs.group === "Goodwill" || browsDataRes_other[0].brows_fs.group === "Brands/trademarks" || browsDataRes_other[0].brows_fs.group === "Investments in Equity Instruments - Unquoted") {
            return first_scenario_data;
          }
          else {
            return first_scenario_data * -1;
          }
        }
      }
      case 2: {
        if (pattern_type === "pattern_2") {
          return second_scenario_data
        } else {
          if (signChangeGroup.includes(groupName) || browsDataRes_other[0].brows_fs.group === "Goodwill" || browsDataRes_other[0].brows_fs.group === "Brands/trademarks" || browsDataRes_other[0].brows_fs.group === "Investments in Equity Instruments - Unquoted") {
            return second_scenario_data;
          } else {
            return second_scenario_data * -1;
          }
        }
      }
      case 3: {
        if (pattern_type === "pattern_2") {
          return third_scenario_data
        } else {
          if (signChangeGroup.includes(groupName) || browsDataRes_other[0].brows_fs.group === "Goodwill" || browsDataRes_other[0].brows_fs.group === "Brands/trademarks" || browsDataRes_other[0].brows_fs.group === "Investments in Equity Instruments - Unquoted") {
            return third_scenario_data;
          } else {
            return third_scenario_data * -1;;
          }
        }
      }
      default:
        return 0
    }
  }


  const totalAdjustment = (note_group_index, note_group, scenario, note_index) => {
    try {
      const GroupData = groupingData.response.data;
      if (note_group_index === 0 || note_group_index === 1 || note_group_index === 2 || note_group_index === 3 || note_group_index === 4) {
        let note_no = getNoteNumber(consoleNotes, note_index);
        if (note_no === "--") { return "..."; }
        const groupResult = groupDataCal(groupingData, note_no);
        const Groups = getGroupName(GroupData.current_company_grouping.fs_grp, note_no);
        const adjustmentData = prepareAdjustmentData(AdjData, note_no);
        const autoPopulateData = prepareAutoPopulationData(APAdjustment, note_no);
        const { currentProject } = groupResult;
        const finalResult = currentProject.map((projectGroup, group_index) =>
          projectGroup.map((data, project_index) =>
            adjustmentResult(groupResult, adjustmentData, autoPopulateData, group_index, scenario, project_index, false, getPatternType(consoleNotes, note_no))
          ).reduce((data, num) => data + num, 0), Groups[0])
        return finalResult.reduce((num, acc) => Number(num) + acc, 0);
        //note_group === "Other Intangible Assets" || note_group === "Goodwill" ||
      }
      else {
        return "--"
      }
    } catch (err) {
      // console.log("err total adj", err.message);
      return "!!"
    }
  }

  const grandTotalAdjustment = (group, scenario, total_tax = false) => {
    const GroupData = groupingData.response.data.current_company_grouping.fs_grp;
    if (group === "Income" || group === "Exceptional items" || group === "Profit / (loss) from discontinued operations" || group === "Other Comprehensive Income") {
      const CurrentGroup = GroupData.filter(data => data.fs_grp === group);
      const finalResult = CurrentGroup[0].notes_grp.map((noteGroup) => {
        const res = totalAdjustment(0, noteGroup.notes_grp, scenario, getNoteNoIndex(noteGroup.notes_no))
        if (isNaN(res)) return 0;
        else return res;
      })
      return finalResult.reduce((num, acc) => Number(num) + acc, 0)
      // return 1
    } else if (group === "Expenses") {
      const CurrentGroup = GroupData.filter(data => data.fs_grp === group);
      const finalResult = CurrentGroup[0].notes_grp.map((noteGroup) => {
        if (total_tax) {
          if (noteGroup.notes_grp === "Current tax" || noteGroup.notes_grp === "Deferred tax") {
            const res = totalAdjustment(1, noteGroup.notes_grp, scenario, getNoteNoIndex(noteGroup.notes_no))
            if (isNaN(res)) return 0;
            else return res;
          } else {
            return 0
          }
        } else {
          if (noteGroup.notes_grp === "Current tax" || noteGroup.notes_grp === "Deferred tax") {
            return 0
          } else {
            const res = totalAdjustment(1, noteGroup.notes_grp, scenario, getNoteNoIndex(noteGroup.notes_no))
            if (isNaN(res)) return 0;
            else return res;
          }
        }
      })
      return finalResult.reduce((num, acc) => Number(num) + acc, 0)
    }
    return 0
  }
  const totalData = (group, field) => {
    if (field === "cy") {
      const res = pnlData[group].map((det) => det.cy);
      return res.reduce((num, acc) => Number(num) + acc, 0);
    }
    if (field === "py") {
      const res = pnlData[group].map((det) => det.py);
      return res.reduce((num, acc) => Number(num) + acc, 0);
    }
  };

  const CalculatePBE_PNL_Data = (type) => {
    const income = "Income"
    const expenses = "Expenses"

    return (totalData(income, type) - totalData(expenses, type)) ?? "Calculation error"
  }

  const CalculatePFY_PNL_Data = (type) => {
    if (type === "cy") {
      return (CalculatePBE_PNL_Data(type) - Number(CurrentProjectCy(pnlData, "Expenses", true))) ?? "Calculation error"
    } else {
      return (CalculatePBE_PNL_Data(type) - Number(CurrentProjectPy(pnlData, "Expenses", true))) ?? "Calculation error"
    }
  }

  return (
    <>
      <div className="table_td">
        <table style={{ width: '100%' }}>
          <TableHeader currentCompany={currentCompany} subsiaryData={subsiaryData} currentProjectName={currentProjectName} />
          <tbody>
            {fsRows.map((group, groupIndex) => (
              <React.Fragment key={getRandomKey()}>
                <tr style={totaledStyle} key={getRandomKey()}>
                  <td style={groupAline}>{group}</td>
                  <td>{ }</td>

                  <td>{Number(totalData(group, "cy")).toLocaleString("en-IN")}</td>
                  <td>{Number(totalData(group, "py")).toLocaleString("en-IN")}</td>

                  <td>{
                    (Number(CurrentProjectCy(currentProjectPnl, group)) + Number(calculateGroupPnlCy(group)) + Number(grandTotalAdjustment(group, 1))).toLocaleString("en-IN")
                  }</td>
                  <td>{
                    (Number(CurrentProjectPy(currentProjectPnl, group)) + Number(calculateGroupPnlPy(group)) + Number(grandTotalAdjustment(group, 2))).toLocaleString("en-IN")
                  }</td>

                  <td>{
                    (Number(totalData(group, "cy") - (Number(CurrentProjectCy(currentProjectPnl, group)) + Number(calculateGroupPnlCy(group)) + Number(grandTotalAdjustment(group, 1))))).toLocaleString("en-IN")
                  }</td>
                  <td>{
                    (Number(totalData(group, "py")) - (Number(CurrentProjectPy(currentProjectPnl, group)) + Number(calculateGroupPnlPy(group)) + Number(grandTotalAdjustment(group, 2)))).toLocaleString("en-IN")
                  }</td>
                </tr>
                {currentProjectPnl[group].map((noteGroup, noteGroupIndex) => (
                  <React.Fragment key={getRandomKey()}>
                    <tr style={lineGroupStyle} key={getRandomKey()}>
                      <td style={groupAline}>{noteGroup.notes_grp}</td>
                      <td style={NoteStyle} onClick={() => handleSelect(getNoteNoIndex(noteGroup.notes_no))}>{getIndexNumber(noteGroup) + 36}</td>

                      <td>
                        {Number(pnlData[group][noteGroupIndex].cy).toLocaleString("en-IN")}
                      </td>
                      <td>
                        {Number(pnlData[group][noteGroupIndex].py).toLocaleString("en-IN")}
                      </td>

                      <td>{
                        (Number(noteGroup.cy) + Number(calculateTotalCy(noteGroup, noteGroupIndex)) + Number(totalAdjustment(groupIndex, noteGroup.notes_grp, 1, getNoteNoIndex(noteGroup.notes_no)))).toLocaleString("en-IN")
                      }</td>
                      <td>{
                        (Number(noteGroup.py) + Number(calculateTotalPy(noteGroup, noteGroupIndex)) + Number(totalAdjustment(groupIndex, noteGroup.notes_grp, 2, getNoteNoIndex(noteGroup.notes_no)))).toLocaleString("en-IN")
                      }</td>

                      <td>{
                        (Number(pnlData[group][noteGroupIndex].cy) - (Number(noteGroup.cy) + Number(calculateTotalCy(noteGroup, noteGroupIndex)) + Number(totalAdjustment(groupIndex, noteGroup.notes_grp, 1, getNoteNoIndex(noteGroup.notes_no))))).toLocaleString("en-IN")
                      }</td>
                      <td>{
                        (Number(pnlData[group][noteGroupIndex].py) - (Number(noteGroup.py) + Number(calculateTotalPy(noteGroup, noteGroupIndex)) + Number(totalAdjustment(groupIndex, noteGroup.notes_grp, 2, getNoteNoIndex(noteGroup.notes_no))))).toLocaleString("en-IN")
                      }</td>
                    </tr>
                    {groupIndex === 1 && noteGroupIndex === 9
                      ?
                      <tr style={totalStyle} key={getRandomKey()}>
                        <td style={groupAline}>{"Profit before exceptional items and tax"}</td>
                        <td>{ }</td>
                        <td>
                          {
                            CalculatePBE_PNL_Data("cy").toLocaleString("en-IN")
                          }
                        </td>
                        <td>
                          {CalculatePBE_PNL_Data("py").toLocaleString("en-IN")}
                        </td>
                        <td>{((Number(CurrentProjectCy(currentProjectPnl, "Income")) - Number(CurrentProjectCy(currentProjectPnl, "Expenses")))
                          + (Number(calculateGroupPnlCy("Income")) - Number(calculateGroupPnlCy("Expenses")))
                          + (Number(grandTotalAdjustment("Income", 1)) - Number(grandTotalAdjustment("Expenses", 1)))
                        ).toLocaleString("en-IN")}</td>
                        <td>{((Number(CurrentProjectPy(currentProjectPnl, "Income")) - Number(CurrentProjectPy(currentProjectPnl, "Expenses")))
                          + (Number(calculateGroupPnlPy("Income")) - Number(calculateGroupPnlPy("Expenses")))
                          + (Number(grandTotalAdjustment("Income", 2)) - Number(grandTotalAdjustment("Expenses", 2)))
                        ).toLocaleString("en-IN")}</td>

                        <td>
                          {(
                            (CalculatePBE_PNL_Data("cy")) -
                            ((Number(CurrentProjectCy(currentProjectPnl, "Income")) - Number(CurrentProjectCy(currentProjectPnl, "Expenses")))
                              + (Number(calculateGroupPnlCy("Income")) - Number(calculateGroupPnlCy("Expenses")))
                              + (Number(grandTotalAdjustment("Income", 1)) - Number(grandTotalAdjustment("Expenses", 1)))
                            )
                          ).toLocaleString("en-IN")}
                        </td>
                        <td>
                          {
                            (
                              (CalculatePBE_PNL_Data("py")) -
                              ((Number(CurrentProjectPy(currentProjectPnl, "Income")) - Number(CurrentProjectPy(currentProjectPnl, "Expenses")))
                                + (Number(calculateGroupPnlPy("Income")) - Number(calculateGroupPnlPy("Expenses")))
                                + (Number(grandTotalAdjustment("Income", 2)) - Number(grandTotalAdjustment("Expenses", 2)))
                              )
                            ).toLocaleString("en-IN")
                          }
                        </td>
                      </tr>
                      : groupIndex === 1 && noteGroupIndex === 11
                        ?
                        <React.Fragment key={getRandomKey()}>
                          <tr style={totaledStyle} key={getRandomKey()}>
                            <td style={groupAline}>{"Total Tax"}</td>
                            <td>{ }</td>
                            <td>
                              {
                                Number(CurrentProjectCy(pnlData, "Expenses", true)).toLocaleString("en-IN")
                              }
                            </td>
                            <td>
                              {
                                Number(CurrentProjectPy(pnlData, "Expenses", true)).toLocaleString("en-IN")
                              }
                            </td>

                            <td>{(Number(CurrentProjectCy(currentProjectPnl, "Expenses", true)) + Number(calculateGroupPnlCy("Expenses", true)) + Number(grandTotalAdjustment("Expenses", 1, true))).toLocaleString("en-IN")}</td>
                            <td>{(Number(CurrentProjectPy(currentProjectPnl, "Expenses", true)) + Number(calculateGroupPnlPy("Expenses", true)) + Number(grandTotalAdjustment("Expenses", 2, true))).toLocaleString("en-IN")}</td>

                            <td>
                              {
                                (
                                  Number(CurrentProjectCy(pnlData, "Expenses", true)) -
                                  (Number(CurrentProjectCy(currentProjectPnl, "Expenses", true)) + Number(calculateGroupPnlCy("Expenses", true)) + Number(grandTotalAdjustment("Expenses", 1, true)))
                                ).toLocaleString("en-IN")
                              }
                            </td>
                            <td>
                              {
                                (
                                  Number(CurrentProjectPy(pnlData, "Expenses", true)) -
                                  (Number(CurrentProjectPy(currentProjectPnl, "Expenses", true)) + Number(calculateGroupPnlPy("Expenses", true)) + Number(grandTotalAdjustment("Expenses", 2, true)))
                                ).toLocaleString("en-IN")
                              }
                            </td>
                          </tr>
                          <tr style={totalStyle} key={getRandomKey()}>
                            <td style={groupAline}>{"Profit for the Year"}</td>
                            <td>{ }</td>
                            <td>
                              {
                                CalculatePFY_PNL_Data("cy").toLocaleString("en-IN")
                              }
                            </td>
                            <td>
                              {
                                CalculatePFY_PNL_Data("py").toLocaleString("en-IN")
                              }
                            </td>
                            <td>
                              {(((Number(CurrentProjectCy(currentProjectPnl, "Income")) - Number(CurrentProjectCy(currentProjectPnl, "Expenses"))) + (Number(calculateGroupPnlCy("Income")) - Number(calculateGroupPnlCy("Expenses"))))
                                -
                                (Number(CurrentProjectCy(currentProjectPnl, "Expenses", true)) + Number(calculateGroupPnlCy("Expenses", true)))
                                + (Number(grandTotalAdjustment("Income", 1)) - Number(grandTotalAdjustment("Expenses", 1))) - Number(grandTotalAdjustment("Expenses", 1, true))
                              ).toLocaleString("en-IN")
                              }
                            </td>
                            <td>
                              {(((Number(CurrentProjectPy(currentProjectPnl, "Income")) - Number(CurrentProjectPy(currentProjectPnl, "Expenses"))) + (Number(calculateGroupPnlPy("Income")) - Number(calculateGroupPnlPy("Expenses"))))
                                -
                                (Number(CurrentProjectPy(currentProjectPnl, "Expenses", true)) + Number(calculateGroupPnlPy("Expenses", true)))
                                + (Number(grandTotalAdjustment("Income", 2)) - Number(grandTotalAdjustment("Expenses", 2))) - Number(grandTotalAdjustment("Expenses", 2, true))
                              ).toLocaleString("en-IN")
                              }
                            </td>

                            <td>
                              {
                                (
                                  CalculatePFY_PNL_Data("cy") -
                                  (((Number(CurrentProjectCy(currentProjectPnl, "Income")) - Number(CurrentProjectCy(currentProjectPnl, "Expenses"))) + (Number(calculateGroupPnlCy("Income")) - Number(calculateGroupPnlCy("Expenses"))))
                                    -
                                    (Number(CurrentProjectCy(currentProjectPnl, "Expenses", true)) + Number(calculateGroupPnlCy("Expenses", true)))
                                    + (Number(grandTotalAdjustment("Income", 1)) - Number(grandTotalAdjustment("Expenses", 1))) - Number(grandTotalAdjustment("Expenses", 1, true))
                                  )
                                ).toLocaleString("en-IN")
                              }
                            </td>
                            <td>
                              {
                                (
                                  CalculatePFY_PNL_Data("py") -
                                  (((Number(CurrentProjectPy(currentProjectPnl, "Income")) - Number(CurrentProjectPy(currentProjectPnl, "Expenses"))) + (Number(calculateGroupPnlPy("Income")) - Number(calculateGroupPnlPy("Expenses"))))
                                    -
                                    (Number(CurrentProjectPy(currentProjectPnl, "Expenses", true)) + Number(calculateGroupPnlPy("Expenses", true)))
                                    + (Number(grandTotalAdjustment("Income", 2)) - Number(grandTotalAdjustment("Expenses", 2))) - Number(grandTotalAdjustment("Expenses", 2, true))
                                  )
                                ).toLocaleString("en-IN")
                              }
                            </td>
                          </tr>
                        </React.Fragment>
                        :
                        ""
                    }
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default CompareFSPnl;


const TableHeader = (props) => {
  const { currentCompany, subsiaryData, currentProjectName } = props;
  const colStyle = {
    width: "25%",
    textTransform: "capitalize"
  }
  const particlarTab = {
    width: "300px",
    height: "50px",
    textAlign: "left",
    paddingLeft: "2rem"
  }

  const thBgColor = { backgroundColor: "#A2C0C2" }

  return (
    <thead>
      <tr style={thBgColor} key={getRandomKey()}>
        <th rowSpan="2" style={particlarTab}>
          <strong>Particulars </strong>
        </th>
        <th rowSpan="2" style={particlarTab}>
          <strong>Notes</strong>
        </th>
        <th colSpan="2" style={particlarTab}>
          <strong>Project 1</strong>
        </th>
        <th colSpan="2" style={particlarTab}>
          <strong>Project 2</strong>
        </th>
        <th colSpan="2" style={particlarTab}>
          <strong>Difference</strong>
        </th>

      </tr>

      <tr style={thBgColor}>
        <th style={{ width: "15%" }}>
          {dateFormat(currentCompany.current_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{ width: "15%" }}>
          {dateFormat(currentCompany.previous_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{ width: "15%" }}>
          {dateFormat(currentCompany.current_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{ width: "15%" }}>
          {dateFormat(currentCompany.previous_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{ width: "15%" }}>
          {dateFormat(currentCompany.current_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{ width: "15%" }}>
          {dateFormat(currentCompany.previous_financial_year, "mmmm dS, yyyy")}
        </th>
      </tr>
    </thead>
  );
}

