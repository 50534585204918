import { React, useEffect, useState } from "react";
import NavBarV2 from "../../../NavBarV2";
import Card from "react-bootstrap/Card";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import TableContainer from "@mui/material/TableContainer";
import Tab from "@mui/material/Tab";
import Button from "react-bootstrap/Button";
import DashboardLogo from "../../../../assets/dashboard1.svg";
import CloudIcon from "@mui/icons-material/Cloud";
import CardContent from "@mui/material/CardContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InputAdornment, TextField } from "@mui/material";
import Table from "react-bootstrap/Table";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import "../../../../WorkSpace/SignUp2.css";
// import AddCompanyModal from "../../Modal/editCompanyModal";
import "../../../../WorkSpace/font.css";
import Tabsystem from "../../../Workspace-v2/Tabsystem";
import Tabs from "@mui/material/Tabs";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Navbar } from "../../../../Components/Navbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import swal from "sweetalert";
import InvestmentModal from "../../Modal/investmentModal";
import { IrRegister, } from "../../../../Console/Component/services/apiHandler";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  GetAllCompanyData,
  GetProject,
  cities,
  createDuplicate,
  deleteIr,
  deletePreset,
  fetchCoa,
  fetchCoaById,
  state,
  GetReceivable,
  GetPayable,
  getActivePlan,
  renameInvestmentRegister
} from "../../ApiServices/apiHandler";
import NestedModal from "../../Modal/addAccount";
import RegisterModal from "../../Modal/RegisterModal";
import moment from "moment";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import axios from "axios";
import * as actionTypes from "../../../../redux/actionTypes";
import ShareScreen from "../../../../Components/shareScreen/ShareScreen";
import PaginationRounded from "../../../../Components/Common/Pagination/Pagination"

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 250,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const IRTab = (props) => {
  const dispatch = useDispatch();
  const {
    nextStep,
    allIr,
    value,
    handleChange,
    a11yProps,
    CustomTabPanel,
    handleDuplicate,
    // id,
    loader,
    popClose,
    opentab,
    // anchorEl,
    handleClosetab,
    // handleClick,
    // handleClose,
    allInvestRegister,
    multipleCompany,
    countCompany,
    setAddCompany,
    handleAllCompany,
    addCompanyshow,
    style,
    addCompany,
    setAnchorEl3,
    anchorEl3,
    handleShareScreen,
    oneCheckWorkspace,
    setOneCheckWorkspace,
    presetsData,
    insideWorskpace,
    workspaceID,
    setAllIR,
    userAccessIr,
    setLoan,
    loansData,
    loan,
    lease,
    setLease,
    leasesData,
    checkLoandata,
    checkedLoans,
    checkedLeases,
    setCheckLeasedata,
    setCheckLoandata,
    checkLeasedata,
    checkReceivabledata,
    checkPaybledata,
    checkInvestmentdata,
    setCheckInvestmentdata,
    checkedInvestment,
    deleteInvestmentRegisters,
    getAll,
    getAllIrData
  } = props;

  const user = JSON.parse(localStorage.getItem('user'));
  const [checkContinueBotton, setCheckContinueBotton] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);
  const [allProject, setAllProject] = useState([]);
  const [allCoaData, setAllCoa] = useState([]);
  const [checkCoa, SetCheckCoa] = useState([]);
  const [allcities, setCitiesData] = useState([]);
  const [allstate, setStateData] = useState([]);
  const [openInvest, setOpenInvest] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [allCompany, setAllCompany] = useState([]);
  const [anchorEl4, setAnchorEl4] = useState(null);
  const [presetId, setPresetID] = useState(null);
  const [anchorEl5, setAnchorEl5] = useState(null);
  const [Irid, setId] = useState("");
  const [IrDataID, setIRId] = useState("");
  const [receivable, setReceivable] = useState([]);
  const [payable, setPayable] = useState([]);
  const [paginateData, setPaginateData] = useState({
    all_coa: []
  });
  const [checkedCoa, setCheckedCoa] = useState({
    all_coa: []
  });
  const [pageData, setPageData] = useState({
    all_project: []
  });
  const [receivableData, setReceivableData] = useState({
    all_receivable: []
  });
  const [paybleData, setPaybleData] = useState({
    all_paybles: []
  });

  const [activePlandata, setActiveplanData] = useState([]);
  const [openRenameModal,setOpenRenameModal] =useState(false);
  const [updatedIRName,setUpdatedIRName] =useState("");

  const open = Boolean(anchorEl3);
  const id = open ? "simple-popover" : undefined;

  const open4 = Boolean(anchorEl4);
  const id4 = open4 ? "simple-popover" : undefined;

  const open5 = Boolean(anchorEl5);
  const id5 = open5 ? "simple-popover" : undefined;
  const history = useHistory();
  useEffect(() => {
    activePlan();
    AllProject();
    companyData();
    allCoa();
    allCheckedCoa();
    ReceivableData();
    PayableData();
  }, []);

  useEffect(() => {
    const IRName = allIr.filter((adj) => adj.ir_id === IrDataID);
    setUpdatedIRName(IRName[0]?.ir_name || "NA");
  }, [IrDataID, allIr]); 

  const handleCloseModal = () => {
    setOpenInvest(false);
  };

  const companyData = async () => {
    try {
      const res = await GetAllCompanyData();
      if (res.status === 200) {
        setAllCompany(res.data.getAllCompanyData);
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const handleChangeData = (field, data) => {
    if (data.length > 0) {
      setCheckContinueBotton(true);
    }

    setSearchTerm([...searchTerm, data]);
  };

  const citiesData = async () => {
    const rescities = await cities();
    const citiesall = rescities.data.CitiesData.cities.map((data) => data.name);
    setCitiesData(citiesall);
  };
  const onInputChangecity = (event, value) => {
    if (value && value.length > 3) {
      citiesData();
    } else {
      setCitiesData([]);
    }
  };
  const handleOpenInvestment = () => {
    setOpenInvest(true);
  };
  const stateData = async () => {
    const res = await state();
    const stateall = res.data.StateData.state.map((data) => data.name);
    setStateData(stateall);
  };
  const onInputChange = (event, value) => {
    if (value && value.length > 3) {
      stateData();
    } else {
      setStateData([]);
    }
  };

  const handleRegisterModalOpen = () => {
    if (user.responsibilities === "approver") {
      swal("Warning", "You Don't have Access to Create", "warning");
    } else {
      setRegisterModalOpen(true);
    }
  };
  const handleRegisterModalClose = () => {
    setRegisterModalOpen(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const AllProject = async () => {
    const res = await GetProject();
    if (res.status === 200) {
      // setAllProject(res.data.project);
      setPageData({ ...pageData, all_project: res.data.project })
    }
  };

  const CompanyName = (c_id) => {
    try {
      const nameCompany = allCompany.filter((adj) => adj._id === c_id);
      const result = nameCompany[0]?.company_name
        ? nameCompany[0]?.company_name
        : "";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };

  const allCoa = async () => {
    const res = await fetchCoa();
    if (res.status === 200) {
      // setAllCoa(res.data.coa);
      setPaginateData({ ...paginateData, all_coa: res.data.coa })
    }
  };

  const allCheckedCoa = async () => {
    const res = await fetchCoaById(workspaceID[2]);
    if (res.status === 200) {
      // SetCheckCoa(res.data.coa);
      setCheckedCoa({ ...checkedCoa, all_coa: res.data.coa })
    }
  }

  const CompanyType = (p_id, field) => {
    try {
      if (field === "company_type") {
        const nameOfType = pageData.all_project.filter((adj) => adj._id === p_id);
        const result = nameOfType[0]?.company_type
          ? nameOfType[0]?.company_type
          : "";
        return result;
      } else if (field === "name") {
        const nameOfType = pageData.all_project.filter((adj) => adj._id === p_id);
        const result = nameOfType[0]?.project_name
          ? nameOfType[0]?.project_name
          : "";
        return result;
      } else if (field === "current_yr") {
        const nameOfType = pageData.all_project.filter((adj) => adj._id === p_id);
        const result = nameOfType[0]?.current_year
          ? nameOfType[0]?.current_year
          : "";
        return result;
      } else if (field === "previous_yr") {
        const nameOfType = pageData.all_project.filter((adj) => adj._id === p_id);
        const result = nameOfType[0]?.previous_year
          ? nameOfType[0]?.previous_year
          : "";
        return result;
      } else {
        const nameCompany = pageData.all_project.filter((adj) => adj._id === p_id);
        const result = nameCompany[0]?.company ? nameCompany[0]?.company : "";
        const companyname = allCompany.filter((adj) => adj._id === result);
        const resultName = companyname[0]?.company_name
          ? companyname[0]?.company_name
          : "";
        return resultName;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const detailsIr = (ir_id) => {
    history.push(`/detailsIR/${ir_id}`);
  };



  const handleEditIr = (ir_id) => {
    history.push(`/investmentregisterv2/${ir_id}`);
  };

  const handleClick = (event, id, ir_id) => {
    setAnchorEl3(event.currentTarget);
    setId(id);
    setIRId(ir_id);
  };

  const handleOpenRenameModal = () => setOpenRenameModal(true);
  const handleCloseRenameModal = () => {
    setOpenRenameModal(false);
    setAnchorEl3(null);
  }

  const handleClick4 = (event, preset_id) => {
    setAnchorEl4(event.currentTarget);
    setPresetID(preset_id);
  };
  const handleClose = () => {
    setAnchorEl3(null);
    setId();
    setAnchorEl5(null);
    setAnchorEl4(null);
  };
  const handleClickCoa = (event, coa_id) => {
    setAnchorEl5(event.currentTarget);
  };

  const handleDeleteIr = async (Ir_id) => {
    const res = await deleteIr(Ir_id);
    if (res.status === 200) {
      setAllIR();
      setAnchorEl3(null);
    }
  };

  const handleAddIR = (ir_id) => {
    history.push(`/insideworkspace/${ir_id}`);
  };

  const handleDeletePreset = async (preset_id) => {
    swal({
      title: "Are you sure?",
      text: "do you want to delete this Preset ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, archive it!",
      cancelButtonText: "No, cancel please!",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then(async (result) => {
      if (result === true) {
        const res = await deletePreset(preset_id);
        if (res.status === 200) {
          AllProject();
          setAnchorEl4(null);
        }
      }
    });
  };

  const hash = allIr.reduce(
    (p, c) => (p[c.ir_id] ? p[c.ir_id].push(c) : (p[c.ir_id] = [c]), p),
    {}
  );
  const newData = Object.keys(hash).map((k) => ({ ir_id: k, car: hash[k] }));
  // const newDataIR = Object.keys(userAccessIr()).map((k) => ({ ir_id: k, car: hash[k] }));

  const IRName = (ir_id, field) => {
    if (field === "name") {
      const nameOfType = allIr.filter((adj) => adj.ir_id === ir_id);
      const result = nameOfType[0]?.ir_name ? nameOfType[0]?.ir_name : "NA";
      return result;
    } else if (field === "id") {
      const nameOfType = allIr.filter((adj) => adj.ir_id === ir_id);
      const result = nameOfType[0]?._id ? nameOfType[0]?._id : "NA";
      return result;
    } else {
      const nameOfType = allIr.filter((adj) => adj.ir_id === ir_id);
      const result = nameOfType[0]?.parent_company_id
        ? nameOfType[0]?.parent_company_id
        : "NA";

      const companyName = allCompany.filter((adj) => adj._id === result);
      const result2 = companyName[0]?.company_name
        ? companyName[0]?.company_name
        : "NA";
      return result2;
    }
  };

  const InvestmentName = (_id, field) => {
    if (field === "name") {
      const nameOfType = allIr.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.ir_name ? nameOfType[0]?.ir_name : "NA";
      return result;
    } else if (field === "id") {
      const nameOfType = allIr.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?._id ? nameOfType[0]?._id : "NA";
      return result;
    } else {
      const nameOfType = allIr.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.parent_company_id
        ? nameOfType[0]?.parent_company_id
        : "NA";

      const companyName = allCompany.filter((adj) => adj._id === result);
      const result2 = companyName[0]?.company_name
        ? companyName[0]?.company_name
        : "NA";
      return result2;
    }
  };

  const LoanName = (_id, field) => {
    if (field === "name") {
      const nameOfType = loansData.all_loans.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.name ? nameOfType[0]?.name : "NA";
      return result;
    } else if (field === "company") {
      const nameOfType = loansData.all_loans.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.company ? nameOfType[0]?.company : "NA";
      return result;
    } else if (field === "startDate") {
      const nameOfType = loansData.all_loans.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.startDate ? nameOfType[0]?.startDate : "NA";
      return result;
    } else if (field === "endDate") {
      const nameOfType = loansData.all_loans.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.endDate ? nameOfType[0]?.endDate : "NA";
      return result;
    } else {
      const nameOfType = loansData.all_loans.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.endDate
        ? nameOfType[0]?.endDate
        : "NA";
      return result;
    }
  };

  const LeaseName = (_id, field) => {
    if (field === "name") {
      const nameOfType = leasesData.all_leases.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.name ? nameOfType[0]?.name : "NA";
      return result;
    } else if (field === "company") {
      const nameOfType = leasesData.all_leases.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.company ? nameOfType[0]?.company : "NA";
      return result;
    } else if (field === "createdAt") {
      const nameOfType = leasesData.all_leases.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.createdAt ? nameOfType[0]?.createdAt : "NA";
      return result;
    } else {
      const nameOfType = leasesData.all_leases.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.company
        ? nameOfType[0]?.company
        : "NA";
      return result;
    }
  };

  const ReceivableName = (_id, field) => {
    if (field === "name") {
      const nameOfType = receivableData.all_receivable.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.name ? nameOfType[0]?.name : "NA";
      return result;
    } else if (field === "company") {
      const nameOfType = receivableData.all_receivable.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.company ? nameOfType[0]?.company : "NA";
      return result;
    } else if (field === "asOnDate") {
      const nameOfType = receivableData.all_receivable.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.asOnDate ? nameOfType[0]?.asOnDate : "NA";
      return result;
    } else if (field === "createdAt") {
      const nameOfType = receivableData.all_receivable.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.createdAt ? nameOfType[0]?.createdAt : "NA";
      return result;
    } else {
      const nameOfType = receivableData.all_receivable.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.name
        ? nameOfType[0]?.name
        : "NA";
      return result;
    }
  };

  const PayableName = (_id, field) => {
    if (field === "name") {
      const nameOfType = paybleData.all_paybles.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.name ? nameOfType[0]?.name : "NA";
      return result;
    } else if (field === "company") {
      const nameOfType = paybleData.all_paybles.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.company ? nameOfType[0]?.company : "NA";
      return result;
    } else if (field === "asOnDate") {
      const nameOfType = paybleData.all_paybles.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.asOnDate ? nameOfType[0]?.asOnDate : "NA";
      return result;
    } else if (field === "createdAt") {
      const nameOfType = paybleData.all_paybles.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.createdAt ? nameOfType[0]?.createdAt : "NA";
      return result;
    } else {
      const nameOfType = paybleData.all_paybles.filter((adj) => adj._id === _id);
      const result = nameOfType[0]?.name
        ? nameOfType[0]?.name
        : "NA";
      return result;
    }
  };

  const IRNameIR = (ir_id, field) => {
    if (field === "name") {
      const nameOfType = allInvestRegister.filter((adj) => adj.ir_id === ir_id);
      const result = nameOfType[0]?.ir_name ? nameOfType[0]?.ir_name : "NA";
      return result;
    } else if (field === "id") {
      const nameOfType = allInvestRegister.filter((adj) => adj.ir_id === ir_id);
      const result = nameOfType[0]?._id ? nameOfType[0]?._id : "NA";
      return result;
    } else {
      const nameOfType = allInvestRegister.filter((adj) => adj.ir_id === ir_id);
      const result = nameOfType[0]?.parent_company_id
        ? nameOfType[0]?.parent_company_id
        : "NA";

      const companyName = allCompany.filter((adj) => adj._id === result);
      const result2 = companyName[0]?.company_name
        ? companyName[0]?.company_name
        : "NA";
      return result2;
    }
  };


  function handleEnter(e, id) {
    const auth = localStorage.getItem("auth_token");
    let headers = {
      "x-auth-token": auth,
    };
    axios
      .get(`api/v1/tb-onboarding/get-tb/${id}`, { headers })
      .then((response) => {
        // dispatch({type: actionTypes.SET_RESTART,payload:'arr'})
        // dispatch({type: actionTypes.SET_NOTERESTART,payload:'arr'})
        console.log(response.data, "response response");
        dispatch({ type: actionTypes.SET_TB_ID, payload: response.data.tb_id });
        window.localStorage.setItem("tb_id", response.data.tb_id);
        localStorage.setItem("project_id", response.data.project_id);
        axios
          .get(`api/v1/grouping/fs/${id}`, { headers })
          .then((res) => {
            if (res.data.fs_grp === []) {
              return;
            }
            const gp_data = {};
            gp_data.grp = res.data.fs_grp;
            dispatch({
              type: actionTypes.SET_EDITSUBGROUPING,
              payload: gp_data,
            });
          })
          .catch((error) => {
            console.log(error.response, "error");
          });
        history.push(`/createProject/DataMapping/${id}`);
      })
      .catch((error) => {
        console.log(error.response, "error");
        swal("", "Cannot found any TB inside this project", "error");
      });
  }

  const CoaName = (p_id) => {
    const nameOfCoa = allCoaData.filter((adj) => adj.project_id === p_id);
    const result = nameOfCoa[0]?.name ? nameOfCoa[0]?.name : "NA";
    return result;
  };

  //receivable//
  const ReceivableData = async () => {
    try {
      const res = await GetReceivable();
      if (res.status === 200) {
        // setReceivable(res.data.receivables);
        setReceivableData({ ...receivableData, all_receivable: res.data.receivables })
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };
  //payable//
  const PayableData = async () => {
    try {
      const res = await GetPayable();
      if (res.status === 200) {
        // setPayable(res.data.payables);
        setPaybleData({ ...paybleData, all_paybles: res.data.payables })
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

// show tabs according to active plans here
  const activePlan = async () => {
    try {
      const res = await getActivePlan();
      setActiveplanData(res.data?.categorizedBenefits?.dataset || "");
    } catch (error) {
      console.log(error.message)
    }
  }
  const getTabIndex = (name) => activePlandata.indexOf(name);
  // rename Investment register
  const handleRenameIr = (event) => {
    setUpdatedIRName(event.target.value); 
  };

  const RenameIR = () => {
    const data ={
      ir_name : updatedIRName,
    }
    renameInvestmentRegister(IrDataID, data)
      .then((response) => {
        handleCloseRenameModal();
        getAll();
        getAllIrData();
        userAccessIr();
        swal("Success","IR Rename Successfully","success");
      })
      .catch((error) => {
        swal("Error","IR Rename Failed","error");
      });
  };
  

  return (
    <>
      <div className="row">
        <div
          className={
            allIr?.length > 0 || userAccessIr()?.length > 0 || loansData.all_loans.length > 0 || leasesData.all_leases.length > 0
              ? "col-md-8"
              : "col-md-12"
          }
        >
          <h5 className="dbwcardhead font-sans">Data Registers  </h5>
        </div>
        {loader ? (
          ""
        ) : allIr?.length > 0 || userAccessIr()?.length > 0 || loansData.all_loans.length > 0 || leasesData.all_leases.length > 0 ? (
          <div className="col-4 text-right">
            <button className="rwp-btn font-sans" onClick={handleRegisterModalOpen}>
              <span>
                <AddIcon />
              </span>
              Create New Register
            </button>
          </div>
        ) : (
          ""
        )}
      </div>

      {loader ? (
        ""
      ) : allIr?.length > 0 || userAccessIr()?.length > 0 || loansData.all_loans.length > 0 || leasesData.all_leases.length > 0 ? (
        <div className="row mt-2">
          <div className="col-md-12 db-ir-tabs">
            <Card style={{ marginTop: "10px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }} >
              <CardContent className="IR-tabs-card">
                <Box sx={{}}>
                  <Tabs
                    className="IR-tabs"
                    sx={{
                      "& button": {
                        borderRadius: 1,
                        color: "black",
                        fontWeight: "500",
                        opacity: "1"
                      },
                      "& button.Mui-selected": {
                        backgroundColor: "#03565A",
                        color: "white",
                        opacity: "1",
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                  > {activePlandata.includes("Sales") && (
                    <Tab
                      className="font-sans"
                      sx={{
                        textTransform: "none",
                        fontWeight: "400",
                      }}
                      label="Sales"
                      {...a11yProps(getTabIndex("Sales"))}
                    />
                  )}
                    {activePlandata.includes("Purchases") && (
                      <Tab
                        className="font-sans"
                        sx={{
                          textTransform: "none",
                          fontWeight: "400",
                        }}
                        label="Purchases"
                        {...a11yProps(getTabIndex("Purchases"))}
                      />
                    )}
                    {activePlandata.includes("Leases") &&
                      <Tab
                        className="font-sans"
                        sx={{
                          textTransform: "none",
                          fontWeight: "400",
                        }}
                        label="Leases"
                        {...a11yProps(getTabIndex("Leases"))}
                      />
                    }
                    {activePlandata.includes('Investment') &&
                      <Tab
                        className="font-sans"
                        sx={{
                          textTransform: "none",
                          fontWeight: "400",
                        }}
                        label="Investments"
                        {...a11yProps(getTabIndex("Investment"))}
                      />
                    }

                    {activePlandata.includes('Fixed Assets') &&
                      <Tab
                        className="font-sans"
                        sx={{
                          textTransform: "none",
                          fontWeight: "400",
                        }}
                        label="Fixed Assets"
                        {...a11yProps(getTabIndex("Fixed Assets"))}
                      />
                    }

                    {activePlandata.includes('Receivables') && <Tab
                      className="font-sans"
                      sx={{
                        textTransform: "none",
                        fontWeight: "400",
                      }}
                      label="Receivables"
                      {...a11yProps(getTabIndex("Receivables"))}
                    />
                    }

                    {activePlandata.includes('Payables') &&
                      <Tab
                        className="font-sans"
                        sx={{
                          textTransform: "none",
                          fontWeight: "400",
                        }}
                        label="Payables"
                        {...a11yProps(getTabIndex("Payables"))}
                      />
                    }

                    {activePlandata.includes('Loans') &&
                      <Tab
                        className="font-sans"
                        sx={{
                          textTransform: "none",
                          fontWeight: "400",
                          width: "182px",
                        }}
                        label="Loans"
                        {...a11yProps(getTabIndex("Loans"))}
                      />
                    }

                   

                    {activePlandata.includes('COA') &&
                      <Tab
                        className="font-sans"
                        sx={{
                          textTransform: "none",
                          fontWeight: "400",
                          width: "182px",
                        }}
                        label="COA"
                        {...a11yProps(getTabIndex("Chart of Accounts"))}
                      />
                    }

                   {activePlandata.includes('Presets') &&
                      <Tab
                        className="font-sans"
                        sx={{
                          textTransform: "none",
                          fontWeight: "400",
                        }}
                        label="Presets"
                        {...a11yProps(getTabIndex("Presets"))}
                      />
                    }

                    {activePlandata.includes('Book closure') &&
                      <Tab
                        className="font-sans"
                        sx={{
                          textTransform: "none",
                          fontWeight: "400",
                          width: "182px",
                        }}
                        label="Book closure"
                        {...a11yProps(getTabIndex("Book closure"))}
                      />
                    }


                  </Tabs>
                </Box>
              </CardContent>
            </Card>
            <CustomTabPanel value={value} index={getTabIndex("Sales")}>
              <TableContainer component={Paper} style={{ borderEndEndRadius: "10px", borderEndStartRadius: "10px" }}>
                <Table
                  style={{ marginBottom: "0px" }}
                  sx={{}}
                  aria-label="caption table"

                >
                  <TableHead>
                    <TableRow>
                      <TableCell>REGISTER NAME</TableCell>
                      <TableCell align="left">COMPANY NAME</TableCell>
                      <TableCell align="center">CURRENT YEAR</TableCell>
                      <TableCell align="center">PREVIOUS YEAR</TableCell>
                      <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody></TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={getTabIndex("Purchases")}>
              <TableContainer component={Paper}>
                <Table
                  style={{ marginBottom: "0px" }}
                  sx={{}}
                  aria-label="caption table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>REGISTER NAME</TableCell>
                      <TableCell align="left">COMPANY NAME</TableCell>
                      <TableCell align="center">CURRENT YEAR</TableCell>
                      <TableCell align="center">PREVIOUS YEAR</TableCell>
                      <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody></TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={getTabIndex("Leases")}>
              <TableContainer component={Paper}>
                <Table
                  style={{ marginBottom: "0px" }}
                  sx={{}}
                  aria-label="caption table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="font-inter">NAME</TableCell>
                      <TableCell className="font-inter">COMPANY</TableCell>
                      <TableCell className="font-inter">CREATEDAT</TableCell>
                      <TableCell className="font-inter" align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <>
                      {insideWorskpace === "no" && lease.length > 0 &&
                        lease.map((lease) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#03565a",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                  }}
                                >

                                  <a href={`https://lease.test.myfinalyst.com/overview/${lease._id}`} className="font-sans">{lease.name}</a>

                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <div className="font-sans">{lease.company}</div>

                                </TableCell>

                                <TableCell
                                  className="font-sans"
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <div className="font-sans">{lease.createdAt}</div>
                                </TableCell>

                                <TableCell
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        IRName(lease.ir_id, "id"),
                                        lease.ir_id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl3}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShareScreen(IrDataID);
                                        }}
                                      >
                                        Share
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleDuplicate(IrDataID);
                                        }}
                                      >
                                        Duplicate
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Extract
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        onClick={() => handleAddIR(IrDataID)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Add More IR
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Edit
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}>
                                        Delete
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>

                              </TableRow>
                            </>
                          );
                        })}

                      {insideWorskpace === "yes" && checkLeasedata.length > 0 &&
                        checkLeasedata.map((lease) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#03565a",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                  }}
                                >

                                  <a href={`https://lease.test.myfinalyst.com/overview/${lease}`} className="font-sans">{LeaseName(lease, "name")}</a>

                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <div className="font-sans">{LeaseName(lease, "company")}</div>

                                </TableCell>

                                <TableCell
                                  className="font-sans"
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <div className="font-sans">{LeaseName(lease, "createdAt")}</div>
                                </TableCell>

                                <TableCell
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        IRName(lease.ir_id, "id"),
                                        lease.ir_id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl3}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShareScreen(IrDataID);
                                        }}
                                      >
                                        Share
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleDuplicate(IrDataID);
                                        }}
                                      >
                                        Duplicate
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Extract
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        onClick={() => handleAddIR(IrDataID)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Add More IR
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Edit
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}>
                                        Delete
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>

                              </TableRow>
                            </>
                          );
                        })}
                      {/* {userAccessIr()?.length > 0 &&
                        userAccessIr()?.map((lease) => {
                          return (
                            <>
                              <TableRow>
                         
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#03565a",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                  }}
                                  onClick={() => vercelIr(lease[0]?.ir_id)}
                                >
                                  {IRNameIR(lease[0]?.ir_id, "name")}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span>
                                    {IRNameIR(lease[0]?.ir_id, "createdAt")}
                                  </span>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span>
                                    {IRNameIR(lease[0]?.ir_id, "createdAt")}
                                  </span>
                                </TableCell>

                                <TableCell
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        IRNameIR(lease[0]?.ir_id, "id"),
                                        lease[0]?.ir_id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl3}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                      
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShareScreen(IrDataID);
                                        }}
                                      >
                                        Share
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Duplicate
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Extract
                                      </span>
                                      <br />
                                      <span
                                        onClick={() => handleAddIR(IrDataID)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Add More IR
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>

                              </TableRow>
                            </>
                          );
                        })} */}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="m-2">
                {insideWorskpace === "no" ?
                  <PaginationRounded data={leasesData.all_leases.length > 0 && leasesData.all_leases} setData={setLease} />
                  : <PaginationRounded data={checkedLeases.all_leases.length > 0 && checkedLeases.all_leases} setData={setCheckLeasedata} />
                }
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={getTabIndex("Investment")}>
              <TableContainer component={Paper}>
                <Table
                  style={{ marginBottom: "0px" }}
                  sx={{}}
                  aria-label="caption table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="font-inter">INVESTMENT REGISTER NAME</TableCell>
                      <TableCell className="font-inter">PARENT COMPANY</TableCell>
                      <TableCell className="font-inter" align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {/* {insideWorskpace === "no" && newData.length > 0 &&
                        newData.map((invest) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#03565a",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                  }}
                                  onClick={() => detailsIr(invest.ir_id)}
                                >
                                  <span className="font-sans">{IRName(invest.ir_id, "name")}</span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">{IRName(invest.ir_id, "company")}</span>
                                </TableCell>
                                <TableCell
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        IRName(invest.ir_id, "id"),
                                        invest.ir_id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl3}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                      className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShareScreen(IrDataID);
                                        }}
                                      >
                                        Share
                                      </span>
                                      <br />
                                      <span
                                      className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleDuplicate(IrDataID);
                                        }}
                                      >
                                        Duplicate
                                      </span>
                                      <br />
                                      <span
                                      className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Extract
                                      </span>
                                      <br />
                                      <span
                                      className="font-sans"
                                        onClick={() => handleAddIR(IrDataID)}
                                        style={{
                                          cursor: "pointer", 
                                          fontSize: "12px",
                                        }}
                                      >
                                        Add More IR
                                      </span>
                                      
                                    </Typography>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })} */}
                      {insideWorskpace === "no" && userAccessIr()?.length > 0 &&
                        userAccessIr()?.map((invest) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#03565a",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                  }}
                                  onClick={invest[0]?.ir_id ? () => detailsIr(invest[0]?.ir_id) : () => detailsIr(invest?.ir_id)}
                                >
                                  {invest[0]?.ir_id ? IRNameIR(invest[0]?.ir_id, "name") : IRName(invest.ir_id, "name")}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span>
                                    {invest[0]?.ir_id ? IRNameIR(invest[0]?.ir_id, "company") : IRName(invest.ir_id, "company")}
                                  </span>
                                </TableCell>
                                <TableCell
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={invest[0]?.ir_id ? (e) =>
                                      handleClick(
                                        e,
                                        IRNameIR(invest[0]?.ir_id, "id"),
                                        invest[0]?.ir_id
                                      ) : (e) =>
                                      handleClick(
                                        e,
                                        IRNameIR(invest?.ir_id, "id"),
                                        invest?.ir_id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl3}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShareScreen(IrDataID);
                                        }}
                                      >
                                        Share
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleDuplicate(IrDataID);
                                        }}
                                      >
                                        Duplicate
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Extract
                                      </span>
                                      <br />
                                      <span
                                        onClick={() => handleAddIR(IrDataID)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Add More IR
                                      </span>
                                      <br/>
                                      <span
                                        onClick={() => deleteInvestmentRegisters(IrDataID)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Delete IR
                                      </span>
                                      <br/>
                                      <span
                                        onClick={() => handleOpenRenameModal()}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                      Rename
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}

                      {insideWorskpace === "yes" && checkInvestmentdata?.length > 0 &&
                        checkInvestmentdata?.map((invest) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#03565a",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                  }}
                                  onClick={() => detailsIr(invest)}
                                >
                                  <span className="font-sans">{InvestmentName(invest, "name")}</span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">{InvestmentName(invest, "company")}</span>
                                </TableCell>
                                <TableCell
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        InvestmentName(invest, "id"),
                                        invest
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl3}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShareScreen(IrDataID);
                                        }}
                                      >
                                        Share
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleDuplicate(IrDataID);
                                        }}
                                      >
                                        Duplicate
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Extract
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        onClick={() => handleAddIR(IrDataID)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Add More IR
                                      </span>
                                      <br/>
                                      <span
                                        // onClick={() => deleteInvestmentRegisters(IrDataID)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Delete IR
                                      </span>
                                      <br/>
                                      <span
                                        // onClick={() => handleOpenRenameModal()}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                      Rename
                                      </span>

                                    </Typography>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="m-2">
                {insideWorskpace === "no" ? ""
                  : <PaginationRounded data={checkedInvestment.all_investments.length > 0 && checkedInvestment.all_investments} setData={setCheckInvestmentdata} />
                }
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={getTabIndex("Fixed Assets")}>
              <TableContainer component={Paper}>
                <Table
                  style={{ marginBottom: "0px" }}
                  sx={{}}
                  aria-label="caption table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>REGISTER NAME</TableCell>
                      <TableCell align="left">COMPANY NAME</TableCell>
                      <TableCell align="center">CURRENT YEAR</TableCell>
                      <TableCell align="center">PREVIOUS YEAR</TableCell>
                      <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody></TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={getTabIndex("Receivables")}>
              <TableContainer component={Paper}>
                <Table
                  style={{ marginBottom: "0px" }}
                  sx={{}}
                  aria-label="caption table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>REGISTER NAME</TableCell>
                      <TableCell align="left">COMPANY NAME</TableCell>
                      <TableCell align="center">asOnDate</TableCell>
                      {/* <TableCell align="center">PREVIOUS YEAR</TableCell> */}
                      <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {insideWorskpace === "no" && receivable.length > 0 &&
                        receivable.map((receivable) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    color: "#03565a",
                                  }}
                                >
                                  <span className="font-sans">{receivable.name}</span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">
                                    {receivable.company}
                                  </span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">
                                    {receivable.asOnDate}
                                  </span>
                                </TableCell>
                                {/* <TableCell
                                 component="th"
                                 scope="row"
                                 style={{
                                   backgroundColor: "white",
                                 }}
                                >
                                <span className="font-sans"> {receivable.createdAt}</span>
                                </TableCell> */}
                                <TableCell
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    style={{
                                      marginInline: "-21px",
                                    }}
                                    id={id5}
                                    open={open5}
                                    anchorEl={anchorEl5}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        className="font-sans"
                                        style={{
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Edit
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Delete
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}

                      {insideWorskpace === "yes" && checkReceivabledata.length > 0 &&
                        checkReceivabledata.map((receivable) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    color: "#03565a",
                                  }}
                                >
                                  <span className="font-sans">{ReceivableName(receivable, "name")}</span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">
                                    {ReceivableName(receivable, "company")}
                                  </span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">
                                    {ReceivableName(receivable, "asOnDate")}
                                  </span>
                                </TableCell>
                                {/* <TableCell
                                 component="th"
                                 scope="row"
                                 style={{
                                   backgroundColor: "white",
                                 }}
                                >
                                <span className="font-sans"> {ReceivableName(receivable, "createdAt")}</span>
                                </TableCell> */}
                                <TableCell
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    style={{
                                      marginInline: "-21px",
                                    }}
                                    id={id5}
                                    open={open5}
                                    anchorEl={anchorEl5}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        className="font-sans"
                                        style={{
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Edit
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Delete
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="m-2">
                {insideWorskpace === "no" ?
                  <PaginationRounded data={receivableData.all_receivable.length > 0 && receivableData.all_receivable} setData={setReceivable} />
                  : ""
                }
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={getTabIndex("Payables")}>
              <TableContainer component={Paper}>
                <Table
                  style={{ marginBottom: "0px" }}
                  sx={{}}
                  aria-label="caption table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>REGISTER NAME </TableCell>
                      <TableCell align="left">COMPANY NAME</TableCell>
                      <TableCell align="center">asOnDate</TableCell>
                      {/* <TableCell align="center">PREVIOUS YEAR</TableCell> */}
                      <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {insideWorskpace === "no" && payable.length > 0 &&
                        payable.map((payable) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    color: "#03565a",
                                  }}
                                >
                                  <span className="font-sans">{payable.name}</span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">
                                    {payable.company}
                                  </span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">
                                    {payable.asOnDate}
                                  </span>
                                </TableCell>
                                {/* <TableCell
                                 component="th"
                                 scope="row"
                                 style={{
                                   backgroundColor: "white",
                                 }}
                                >
                                <span className="font-sans"> {payable.createdAt}</span>
                                </TableCell> */}
                                <TableCell
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    style={{
                                      marginInline: "-21px",
                                    }}
                                    id={id5}
                                    open={open5}
                                    anchorEl={anchorEl5}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        className="font-sans"
                                        style={{
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Edit
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Delete
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}

                      {insideWorskpace === "yes" && checkPaybledata.length > 0 &&
                        checkPaybledata.map((payable) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    color: "#03565a",
                                  }}
                                >
                                  <span className="font-sans">{PayableName(payable, "name")}</span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">
                                    {PayableName(payable, "company")}
                                  </span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">
                                    {PayableName(payable, "asOnDate")}
                                  </span>
                                </TableCell>
                                {/* <TableCell
                                 component="th"
                                 scope="row"
                                 style={{
                                   backgroundColor: "white",
                                 }}
                                >
                                <span className="font-sans"> {PayableName(payable, "createdAt")}</span>
                                </TableCell> */}
                                <TableCell
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    style={{
                                      marginInline: "-21px",
                                    }}
                                    id={id5}
                                    open={open5}
                                    anchorEl={anchorEl5}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        className="font-sans"
                                        style={{
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Edit
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Delete
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="m-2">
                {insideWorskpace === "no" ?
                  <PaginationRounded data={paybleData.all_paybles.length > 0 && paybleData.all_paybles} setData={setPayable} />
                  : ""
                }
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={getTabIndex("Loans")}>
              <TableContainer component={Paper}>
                <Table
                  style={{ marginBottom: "0px" }}
                  sx={{}}
                  aria-label="caption table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>NAME</TableCell>
                      <TableCell>COMPANY</TableCell>
                      <TableCell>START DATE</TableCell>
                      <TableCell>END DATE</TableCell>
                      <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <>

                      {insideWorskpace === "no" && loan.length > 0 &&
                        loan.map((loan) => {
                          return (
                            <>
                              <TableRow>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#03565a",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                  }}
                                // onClick={() => detailsIr(loan.ir_id)}
                                >
                                  <span className="font-sans"> {loan.name}</span>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans"> {loan.company}</span>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">{loan.startDate}</span>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans"> {loan.endDate}</span>
                                </TableCell>

                                <TableCell
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        IRName(loan.ir_id, "id"),
                                        loan.ir_id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl3}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShareScreen(IrDataID);
                                        }}
                                      >
                                        Share
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleDuplicate(IrDataID);
                                        }}
                                      >
                                        Duplicate
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Extract
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        onClick={() => handleAddIR(IrDataID)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Add More IR
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>

                              </TableRow>
                            </>
                          );
                        })}

                      {insideWorskpace === "yes" && checkLoandata.length > 0 &&
                        checkLoandata.map((loan) => {
                          return (
                            <>
                              <TableRow>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#03565a",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                  }}
                                // onClick={() => detailsIr(loan.ir_id)}
                                >
                                  <span className="font-sans">{LoanName(loan, "name")}</span>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">{LoanName(loan, "company")}</span>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">{LoanName(loan, "startDate")}</span>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">{LoanName(loan, "endDate")}</span>
                                </TableCell>

                                <TableCell
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        IRName(loan.ir_id, "id"),
                                        loan.ir_id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl3}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShareScreen(IrDataID);
                                        }}
                                      >
                                        Share
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleDuplicate(IrDataID);
                                        }}
                                      >
                                        Duplicate
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Extract
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        onClick={() => handleAddIR(IrDataID)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Add More IR
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>

                              </TableRow>
                            </>
                          );
                        })}
                      {/* {userAccessIr()?.length > 0 &&
                        userAccessIr()?.map((invest) => {
                          return (
                            <>      
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#03565a",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                  }}
                                  onClick={() => vercelIr(invest[0]?.ir_id)}
                                >
                                  {IRNameIR(invest[0]?.ir_id, "name")}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span>
                                    {IRNameIR(invest[0]?.ir_id, "company")}
                                  </span>
                                </TableCell>

                                <TableCell
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        IRNameIR(invest[0]?.ir_id, "id"),
                                        invest[0]?.ir_id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl3}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShareScreen(IrDataID);
                                        }}
                                      >
                                        Share
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Duplicate
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Extract
                                      </span>
                                      <br />
                                      <span
                                        onClick={() => handleAddIR(IrDataID)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Add More IR
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })} */}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="m-2">
                {insideWorskpace === "no" ?
                  <PaginationRounded data={loansData.all_loans.length > 0 && loansData.all_loans} setData={setLoan} />
                  : <PaginationRounded data={checkedLoans.all_loans.length > 0 && checkedLoans.all_loans} setData={setCheckLoandata} />
                }
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={getTabIndex("Chart of Accounts")}>
              <TableContainer component={Paper}>
                <Table
                  style={{ marginBottom: "0px" }}
                  sx={{}}
                  aria-label="caption table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>NAME OF THE COA</TableCell>
                      <TableCell align="left">COMPANY TYPE</TableCell>
                      <TableCell align="center">COMPANY NAME</TableCell>
                      <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {insideWorskpace === "no" && allCoaData.length > 0 &&
                        allCoaData.map((coa) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    color: "#03565a",
                                  }}
                                  onClick={() => {
                                    dispatch({
                                      type: "COA_DATA",
                                      payload: coa?.coa,
                                    });
                                    history.push(`/coa-details/${coa?._id}`);
                                  }}
                                >
                                  <span className="font-sans">{coa.name}</span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">
                                    {CompanyType(
                                      coa.project_id,
                                      "company_type"
                                    )}
                                  </span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">
                                    {CompanyType(
                                      coa.project_id,
                                      "company_name"
                                    )}
                                  </span>
                                </TableCell>
                                <TableCell
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={handleClickCoa}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    style={{
                                      marginInline: "-21px",
                                    }}
                                    id={id5}
                                    open={open5}
                                    anchorEl={anchorEl5}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        className="font-sans"
                                        style={{
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Edit
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Delete
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}

                      {insideWorskpace === "yes" && checkCoa.length > 0 &&
                        checkCoa.map((coa) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    color: "#03565a",
                                  }}
                                  onClick={() => {
                                    dispatch({
                                      type: "COA_DATA",
                                      payload: coa?.coa,
                                    });
                                    history.push(`/coa-details/${coa?._id}`);
                                  }}
                                >
                                  <span className="font-sans">{coa.name}</span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">
                                    {CompanyType(
                                      coa.project_id,
                                      "company_type"
                                    )}
                                  </span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">
                                    {CompanyType(
                                      coa.project_id,
                                      "company_name"
                                    )}
                                  </span>
                                </TableCell>
                                <TableCell
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={handleClickCoa}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    style={{
                                      marginInline: "-21px",
                                    }}
                                    id={id5}
                                    open={open5}
                                    anchorEl={anchorEl5}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        className="font-sans"
                                        style={{
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Edit
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          fontSize: "12px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Delete
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="m-2">
                {insideWorskpace === "no" ?
                  <PaginationRounded data={paginateData.all_coa.length > 0 && paginateData.all_coa} setData={setAllCoa} />
                  : <PaginationRounded data={checkedCoa.all_coa.length > 0 && checkedCoa.all_coa} setData={SetCheckCoa} />
                }
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={getTabIndex("Presets")}>
              <TableContainer component={Paper}>
                <Table sx={{}} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>PRESET NAME </TableCell>
                      <TableCell align="left">COMPANY TYPE</TableCell>
                      <TableCell align="left">COMPANY NAME</TableCell>
                      <TableCell align="center">CURRENT YEAR</TableCell>
                      <TableCell align="center">PREVIOUS YEAR</TableCell>
                      <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {insideWorskpace === "no" && allProject?.length > 0 && allProject?.map((det) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            textDecoration: "underline",
                            color: "#03565a",
                            cursor: "pointer",
                            backgroundColor: "white",
                          }}
                          onClick={(e) => handleEnter(e, det._id)}
                        >
                          <span className="font-sans"> {det.project_name}({CoaName(det._id)})</span>
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            backgroundColor: "white",
                          }}
                        >
                          <span className="font-sans"> {det.company_type}</span>
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            backgroundColor: "white",
                          }}
                        >
                          <span className="font-sans">{CompanyName(det.company)}</span>
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            backgroundColor: "white",
                          }}
                        >
                          <span className="font-sans"> {moment(det.current_year).format(
                            "MMMM DD,YYYY"
                          )}</span>
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            backgroundColor: "white",
                          }}
                        >
                          <span className="font-sans"> {moment(det.previous_year).format(
                            "MMMM DD,YYYY"
                          )}</span>
                        </TableCell>

                        <TableCell
                          style={{
                            backgroundColor: "white",
                          }}
                          align="center"
                        >
                          <MoreVertIcon
                            onClick={(e) => handleClick4(e, det._id)}
                            style={{ cursor: "pointer" }}
                          />
                          <Popover
                            style={{
                              marginInline: "-21px",
                            }}
                            id={id4}
                            open={open4}
                            anchorEl={anchorEl4}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Typography sx={{ p: 2 }}>
                              {/* <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      Edit
                                    </span>
                                    <br /> */}
                              <span
                                className="font-sans"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "12px",
                                }}
                                onClick={() =>
                                  handleDeletePreset(presetId)
                                }
                              >
                                Delete
                              </span>
                            </Typography>
                          </Popover>
                        </TableCell>
                      </TableRow>
                    )
                    )}

                    {insideWorskpace === "yes" && oneCheckWorkspace?.length > 0 && oneCheckWorkspace?.map((det) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            textDecoration: "underline",
                            color: "#03565a",
                            cursor: "pointer",
                            backgroundColor: "white",
                          }}
                          onClick={(e) => handleEnter(e, det)}
                        >
                          {CompanyType(det, "name")}
                          {/* ({CoaName(det)}) */}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            backgroundColor: "white",
                          }}
                        >
                          {CompanyType(det, "company_type")}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            backgroundColor: "white",
                          }}
                        >
                          {CompanyType(det)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            backgroundColor: "white",
                          }}
                        >
                          {moment(CompanyType(det, "current_yr")).format(
                            "MMMM DD,YYYY"
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            backgroundColor: "white",
                          }}
                        >
                          {moment(CompanyType(det, "previous_yr")).format(
                            "MMMM DD,YYYY"
                          )}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "white",
                          }}
                          align="center"
                        >
                          <MoreVertIcon
                            onClick={(e) => handleClick4(e, det)}
                            style={{ cursor: "pointer" }}
                          />
                          <Popover
                            style={{
                              marginInline: "-21px",
                            }}
                            id={id4}
                            open={open4}
                            anchorEl={anchorEl4}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Typography sx={{ p: 2 }}>
                              {/* <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      Edit
                                    </span>
                                    <br /> */}
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "12px",
                                }}
                                onClick={() =>
                                  handleDeletePreset(presetId)
                                }
                              >
                                Delete
                              </span>
                            </Typography>
                          </Popover>
                        </TableCell>
                      </TableRow>
                    )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="m-2">
                {insideWorskpace === "no" ?
                  <PaginationRounded data={pageData.all_project?.length > 0 && pageData.all_project} setData={setAllProject} />
                  : <PaginationRounded data={presetsData.all_presets.length > 0 && presetsData.all_presets} setData={setOneCheckWorkspace} />
                }
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={getTabIndex("Book closure")}>
              <TableContainer component={Paper}>
                <Table
                  style={{ marginBottom: "0px" }}
                  sx={{}}
                  aria-label="caption table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>BOOK CLOSURE REGISTER NAME</TableCell>
                      <TableCell>PARENT COMPANY</TableCell>
                      <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {newData.length > 0 &&
                        newData.map((invest) => {
                          return (
                            <>
                              <TableRow>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#03565a",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                  }}
                                  onClick={() => detailsIr(invest.ir_id)}
                                >
                                  <span className="font-sans">{IRName(invest.ir_id, "name")}</span>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span className="font-sans">{IRName(invest.ir_id, "company")}</span>
                                </TableCell>

                                <TableCell
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        IRName(invest.ir_id, "id"),
                                        invest.ir_id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl3}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShareScreen(IrDataID);
                                        }}
                                      >
                                        Share
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleDuplicate(IrDataID);
                                        }}
                                      >
                                        Duplicate
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Extract
                                      </span>
                                      <br />
                                      <span
                                        className="font-sans"
                                        onClick={() => handleAddIR(IrDataID)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Add More IR
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>

                              </TableRow>
                            </>
                          );
                        })}
                      {userAccessIr()?.length > 0 &&
                        userAccessIr()?.map((invest) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#03565a",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                  }}
                                  onClick={() => detailsIr(invest[0]?.ir_id)}
                                >
                                  {IRNameIR(invest[0]?.ir_id, "name")}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                >
                                  <span>
                                    {IRNameIR(invest[0]?.ir_id, "company")}
                                  </span>
                                </TableCell>
                                <TableCell
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        IRNameIR(invest[0]?.ir_id, "id"),
                                        invest[0]?.ir_id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl3}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          handleShareScreen(IrDataID);
                                        }}
                                      >
                                        Share
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Duplicate
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Extract
                                      </span>
                                      <br />
                                      <span
                                        onClick={() => handleAddIR(IrDataID)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Add More IR
                                      </span>
                                    </Typography>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>

          </div>

        </div>
      ) : (
        <Card style={{ height: "324px" }}>
          <Card.Header className="ir-empty-cardheader">
            <Table sx={{}} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>REGISTER NAME </TableCell>
                  <TableCell align="left">COMPANY NAME</TableCell>
                  <TableCell align="center">CURRENT YEAR</TableCell>
                  <TableCell align="center">PREVIOUS YEAR</TableCell>
                  <TableCell align="center">ACTION</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Card.Header>
          <Card.Body style={{ textAlign: "center" }}>
            <Card.Title>
              <img src={DashboardLogo} alt="logo" />
            </Card.Title>
            <Card.Text style={{ textAlign: "center" }}>
              There are no registers created
            </Card.Text>
            <Button className="wp-btn-fill" onClick={handleRegisterModalOpen}>
              <AddIcon /> Create register
            </Button>
            &nbsp; &nbsp;
            <Button className="wp-btn-no-fill">
              <CloudIcon /> Upload register
            </Button>
            <div
              style={{
                textAlign: "center",
              }}
            ></div>
          </Card.Body>
        </Card>
      )}

      {/* <InvestmentModal
        openInvest={openInvest}
        handleClose={handleCloseModal}
        continueStep={continueStep}
      /> */}
      <RegisterModal
        registerModalOpen={registerModalOpen}
        handleRegisterModalClose={handleRegisterModalClose}
        continueStep={nextStep}
        activePlandata = {activePlandata}
      />
      <Modal
        open={openRenameModal}
        onClose={handleCloseRenameModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div className="col-12">
            <h5>Rename Investment Register</h5>
            <form>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={updatedIRName}
                  onChange={(e) => handleRenameIr(e)}
                />
              </div>
              <input
                type="button"
                className="btn btn-primary1"
                value="Submit"
                onClick={() => RenameIR()}
              />
              <input
                type="button"
                className="btn btn-defult"
                value="Close"
                onClick={handleCloseRenameModal}
              />
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default IRTab;
