import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "@mui/material/Card";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const borderStyle = {
  height: "50px",
  backgroundColor: "#F8F8F8",
  border: "1px solid #8692A6",
};
const CardStyle = {
  width: "85%",
  borderRadius: "24px",
};
const CardHeaderStyle = {
  height: "38px",
  backgroundColor: "#F0DBC8",
};
const StarStyle = {
  color: "red",
};
const CustomDropdown = {
  height: "50px",
  width: "100%",
  padding: "0 15px",
  border: "1px solid #8692A6",
  appearance: "none",
  color: "#1D1D1D",
};
const FetchFStable = () => {
  let history = useHistory();
  const goBack = () => {
    history.push("/preset-information");
  };
  return (
    <>
      <div className="container py-3">
        <div onClick={goBack} style={{ cursor: "pointer" }}>
          <p className="font-size-16 custom-font-600 font-sans custom-common-theme-text-color">
            <span className="me-4">
              <ArrowBackIcon />
            </span>
            Back to workspace
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Card className="mt-2" style={CardStyle}>
            <div style={CardHeaderStyle}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ gap: "8px" }}
              >
                <p className="mt-2 mb-0 font-size-12 custom-font-500 font-sans ">
                  2022-23 present was selected
                </p>
                <p className="mt-2 mb-0 font-size-12 custom-font-700 font-sans custom-common-theme-text-color">
                  Change preset
                </p>
              </div>
            </div>
            <div className="px-4 py-4">
              <div>
                <p className="font-size-24 custom-font-700 font-sans mt-0 mb-0">
                  Preset information
                </p>
              </div>

              <div className="mt-3">
                <div class="row">
                  <div class="col">
                    <div className="font-size-14 custom-font-500 font-sans text-secondary">
                      Company<span style={{ color: "red" }}>*</span>
                    </div>
                    <div>
                      <input
                        className="radius-6px"
                        style={borderStyle}
                        type="text"
                        placeholder="xyz pvt ltd"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div className="font-size-14 custom-font-500 font-sans text-secondary">
                      Name of the Financials
                      <span style={StarStyle}>*</span>
                    </div>
                    <div>
                      <input
                        className="radius-6px"
                        style={borderStyle}
                        type="text"
                        placeholder="xyz pvt ltd"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div class="col">
                    <div className="font-size-14 custom-font-500 font-sans text-secondary">
                      Nature of Company <span style={StarStyle}>*</span>
                    </div>
                    <div>
                      <input
                        className="radius-6px"
                        style={borderStyle}
                        type="text"
                        placeholder="Manufacturing"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div className="font-size-14 custom-font-500 font-sans text-secondary">
                      Select the GAAP
                      <span style={StarStyle}>*</span>
                    </div>
                    <div>
                      <input
                        className="radius-6px"
                        style={borderStyle}
                        type="text"
                        placeholder="Manufacturing"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="mt-3">
                <div class="row">
                  <div class="col">
                    <div className="d-flex flex-column">
                      <h3 className="font-size-14 custom-font-500 mt-0 mb-0 text-secondary">
                        Current Financial Year{" "}
                        <span className="text-danger">*</span>
                      </h3>
                      <div className="d-flex  align-items-center ">
                        <div className="link-icon">
                          <CalendarMonthIcon
                            className="mt-2 ml-2 calndr"
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "black",
                            }}
                          />
                        </div>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["DatePicker", "MobileDatePicker"]}
                            >
                              <MobileDatePicker
                                className="datepicker-color"
                                sx={{
                                  width: "430px",
                                  border: "1px solid #F8F8F8",
                                  borderRadius: "6px",
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div className="d-flex flex-column">
                      <h3 className="font-size-14 custom-font-500 mt-0 mb-0 text-secondary">
                        Previous Financial Year
                        <span className="text-danger">*</span>
                      </h3>
                      <div className="d-flex  align-items-center ">
                        <div className="link-icon">
                          <CalendarMonthIcon
                            className="mt-2 ml-2 calndr"
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "black",
                            }}
                          />
                        </div>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["DatePicker", "MobileDatePicker"]}
                            >
                              <MobileDatePicker
                                className="datepicker-color"
                                sx={{
                                  width: "430px",
                                  border: "1px solid #F8F8F8",
                                  borderRadius: "6px",
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="mt-3">
                <div class="row">
                  <div class="col">
                    <div className="font-size-14 custom-font-500 font-sans text-secondary">
                      Select presents /Chart of accounts{" "}
                      <span style={StarStyle}>*</span>
                    </div>
                    <div>
                      <input
                        className="radius-6px"
                        style={{ height: "50px", backgroundColor: "#F8F8F8" }}
                        type="text"
                        placeholder="xyz pvt ltd"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div className="font-size-14 custom-font-500 font-sans text-secondary">
                      Functional Currency
                      <span style={StarStyle}>*</span>
                    </div>
                    <div>
                      <input
                        className="radius-6px"
                        style={{ height: "50px", backgroundColor: "#F8F8F8" }}
                        type="text"
                        placeholder="xyz pvt ltd"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="font-size-14 custom-font-500 font-sans text-secondary">
                      Reporting Currency
                      <span style={StarStyle}>*</span>
                    </div>
                    <div
                      className="mt-1"
                      style={{ position: "relative", width: "100%" }}
                    >
                      <select
                        className="font-sans radius-6px bg-white font-size-16"
                        style={CustomDropdown}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select from the Currency
                        </option>
                        <option value="usd">USD</option>
                        <option value="eur">EUR</option>
                        <option value="inr">INR</option>
                        <option value="gbp">GBP</option>
                      </select>
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          width: "30px",
                          height: "30px",
                          backgroundColor: "#f8f8f8",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            border: "solid #333",
                            borderWidth: "0 2px 2px 0",
                            display: "inline-block",
                            padding: "5px",
                            transform: "rotate(45deg)",
                            marginTop: "-6px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col"></div>
                </div>
              </div>
              <div className="mt-5">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="mt-0 mb-0 font-size-24  custom-font-700">
                      Translation information : USD to INR
                    </p>
                    <p
                      className="mt-0 mb-0 font-size-12 custom-font-500"
                      style={{ color: "#ABA9A9" }}
                    >
                      As per today's exchange rates: 1 USD ⁓ 82.65 INR
                    </p>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      className="custom-font-700 font-size-16 text-capitalize font-sans custom-common-theme-text-color bg-white radius-6px shadow-none custom-border"
                      style={{
                        width: "180px",
                        height: "40px",
                      }}
                    >
                      fetch rate
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <Card
                    className="radius-16px"
                    style={{ backgroundColor: "#F8F8F8" }}
                  >
                    <div className="px-4 py-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <p className="mt-0 mb-0 custom-font-700 font-size-18 ">
                            Fill the details
                          </p>
                        </div>
                        <div
                          className="d-flex"
                          style={{ gap: "5px", alignItems: "center" }}
                        >
                          <div className="col" style={{ flex: 1 }}>
                            <div
                              className="mt-1"
                              style={{ position: "relative", width: "100%" }}
                            >
                              <select
                                className="custom-dropdown"
                                style={{
                                  height: "40px",
                                  width: "300px",
                                  padding: "0 15px",
                                  border: "1px solid #d9d9d9",
                                  borderRadius: "6px",
                                  backgroundColor: "#fff",
                                  appearance: "none",
                                  color: "#1D1D1D",
                                  fontSize: "14px",
                                }}
                                defaultValue=""
                              >
                                <option value="" disabled>
                                  Import historical numbers
                                </option>
                                <option value="usd">USD</option>
                                <option value="eur">EUR</option>
                                <option value="inr">INR</option>
                                <option value="gbp">GBP</option>
                              </select>
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "10px",
                                  transform: "translateY(-50%)",
                                  width: "30px",
                                  height: "30px",
                                  backgroundColor: "#f8f8f8",
                                  borderRadius: "6px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  style={{
                                    border: "solid #333",
                                    borderWidth: "0 2px 2px 0",
                                    display: "inline-block",
                                    padding: "5px",
                                    transform: "rotate(45deg)",
                                    marginTop: "-6px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <Button
                            variant="contained"
                            className="custom-font-700 font-size-16 text-capitalize font-sans custom-common-theme-text-color bg-white radius-6px shadow-none primary-border mt-1"
                            style={{
                              width: "80px",
                              height: "38px",
                            }}
                          >
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <Card>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow style={{ backgroundColor: "#FFEBD9" }}>
                                <TableCell
                                  style={{
                                    color: "#737373",
                                  }}
                                  className="font-size-12 custom-font-600 text-uppercase text-center font-inter"
                                >
                                  Date
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                    color: "#737373",
                                  }}
                                  className="font-size-12 custom-font-600 text-uppercase text-center font-inter"
                                >
                                  Opening rate
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                    color: "#737373",
                                  }}
                                  className="font-size-12 custom-font-600 text-uppercase text-center font-inter"
                                >
                                  Closing rate
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                    color: "#737373",
                                  }}
                                  className="font-size-12 custom-font-600 text-uppercase text-center font-inter"
                                >
                                  Average rate
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell className="font-size-16 custom-font-500 font-sans text-center ">
                                  02/11/2024
                                </TableCell>
                                <TableCell className="font-size-16 custom-font-500 font-sans text-center ">
                                  82
                                </TableCell>
                                <TableCell className="font-size-16 custom-font-500 font-sans text-center ">
                                  82
                                </TableCell>
                                <TableCell className="font-size-16 custom-font-500 font-sans text-center "></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="font-size-16 custom-font-500 font-sans text-center ">
                                  02/11/2024
                                </TableCell>
                                <TableCell className="font-size-16 custom-font-500 font-sans text-center ">
                                  82
                                </TableCell>
                                <TableCell className="font-size-16 custom-font-500 font-sans text-center ">
                                  82
                                </TableCell>
                                <TableCell className="font-size-16 custom-font-500 font-sans text-center "></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Card>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                      <Button
                        variant="contained"
                        className="custom-font-700 font-size-16 text-capitalize custom-common-theme-bg-color font-sans radius-6px mb-5"
                        style={{ width: "200px", height: "60px" }}
                      >
                        Save and translate
                      </Button>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default FetchFStable;
