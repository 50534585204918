import { Navbar } from "../../../Components/Navbar";
import Button from "@mui/material/Button";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHistory, useLocation } from "react-router";
import { useState } from "react";
import RegisterModal from "../Modal/RegisterModal";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import swal from "sweetalert";
import {
  GetAllCompanyData,
  GetCompanyDataIR,
  deleteIr,
  deleteOthersDetailsIr,
  getAllIR,
} from "../ApiServices/apiHandler";
import { useEffect } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Card } from "react-bootstrap";
import emptyicon from "../../../assets/Image/emptyicon.png";
// import { useHistory, useLocation } from "react-router";
import "./ir.css";

const IRDetails = () => {
  //
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  //
  const history = useHistory();
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [allIr, setAllIR] = useState([]);
  const [IrData, setIrData] = useState([]);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [IrOneData, setIRData] = useState("");
  const location = useLocation();
  let a = location.pathname;
  let b = a.split("/");
  const [allCompany, setAllCompany] = useState([]);
  const [data, setData] = useState("");
  useEffect(() => {
    getAllIrData();
    // allIrData();
    AllRegisterData();
    companyData();
    showdata();
  }, []);

  const showdata = () => {
    const item = localStorage.getItem("name");
    // console.log(item, "itemitem--");
    setData(item);
  };

  const open = Boolean(anchorEl3);
  const id = open ? "simple-popover" : undefined;

  const handleRegisterModalOpen = () => {
    // setRegisterModalOpen(true);
    history.push(`/insideworkspace/${b[2]}`);
  };
  const handleRegisterModalClose = () => {
    setRegisterModalOpen(false);
  };
  const back = () => {
    history.push(`/dashboard`);
  };
  const companyData = async () => {
    try {
      const res = await GetAllCompanyData();
      if (res.status === 200) {
        setAllCompany(res.data.getAllCompanyData);
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };
  const continueStep = () => {
    history.push("/insideworkspace");
  };
  const getAllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIR(res.data.fetchIR);
    }
  };
  const AllRegisterData = async () => {
    GetCompanyDataIR(b[2]).then(response => {
      setIrData(response.data.updatedOtherData);
    }).catch(error => {
      console.log("error", error)
    })
  };

  const IRName = (ir_id) => {
    const nameOfType = allIr.filter((adj) => adj.ir_id === ir_id);
    const result = nameOfType[0]?.ir_name ? nameOfType[0]?.ir_name : "";
    return result;
  };
  const CompanyName = (c_id) => {
    try {
      const nameCompany = allCompany.filter((adj) => adj._id === c_id);
      const result = nameCompany[0]?.company_name
        ? nameCompany[0]?.company_name
        : "";
      return result;
    } catch (error) {
      // console.log(error, "2222");
      return 0;
    }
  };
  const handleClick = (event, data) => {
    setAnchorEl3(event.currentTarget);
    setIRData(data);
  };
  const handleClose = () => {
    setAnchorEl3(null);
  };

  const handleEditIr = (data) => {
    history.push(`/investmentregister/${data._id}`);
  };
  const handleDeleteIr = async (data) => {
    swal({
      title: "Are you sure to delete this IR?",
      text: "You won't be able to restore it!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const Ir_id = data._id;
        try {
          await deleteOthersDetailsIr(Ir_id);
          AllRegisterData();
          setAnchorEl3(null);
          swal("IR Deleted Successfully", {
            icon: "success",
          });
        } catch (err) {
          swal("Error!", err.message || "Something went wrong.", "error", {
            button: "OK",
          });
        }
      }
    });
  };
  
  return (
    <>
      <div>
        <div>
          <Navbar />
        </div>

        <div className="d-flex">
          <div className="container">
            <div className="d-flex justify-content-between mt-5">
              <span
                style={{
                  fontWeight: "600",
                  color: "#03565A",
                  cursor: "pointer",
                }}
                onClick={() => back()}
              >
                <ArrowBackIcon /> Back to {IRName(b[2])}
              </span>
              {IrData.length===0 ?"":
              <Button
                style={{
                  border: "2px solid #03565A",
                  width: "200px",
                  height: "40px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={handleRegisterModalOpen}
              >
                <AddIcon style={{ color: "#03565A" }} />
                <span
                  className="font-sans"
                  style={{
                    color: "#03565A",
                    textTransform: "none",
                    fontWeight: "550",
                  }}
                >
                  Create investment
                </span>
              </Button>}
            </div>
            {/* <Tabsystem/> */}
            {/*  */}
            {IrData.length === 0 ? (
              <div className="mt-3 ">
                <p className="mt-0 mb-1 font-size-18 custom-font-600">
                  Investment
                </p>
                <Card
                  className="w-100 mb-4 radius-8px"
                  style={{ height: "400px" }}
                >
                  <div className="d-flex flex-column justify-content-center align-items-center h-100">
                    <div
                      className="d-flex justify-content-center align-items-center rounded-circle"
                      style={{
                        backgroundColor: "#FFE3BF",
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <img
                        src={emptyicon}
                        alt="empty icon"
                        style={{ width: "44px", height: "44px" }}
                      />
                    </div>
                    <p className="mt-3 mt-0 mb-0 font-size-16 custom-font-500">
                      No investment details uploaded
                    </p>
                    <div className="mt-1">
                      <Button
                        variant="outlined"
                        style={{ width: "300px", height: "48px" }}
                        className="custom-border font-size-16 custom-font-600 custom-common-theme-text-color text-capitalize radius-6px mt-2"
                        onClick={handleRegisterModalOpen}
                      >
                        <AddIcon className="mr-2" />
                        <span>Add investment</span>
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            ) : (
              <>
                <div className="mt-2">
                  <table
                    style={{
                      borderCollapse: "separate",
                      borderSpacing: 0,
                      borderRadius: 10,
                      overflow: "hidden",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter"
                          style={{
                            borderTopLeftRadius: 10,
                            backgroundColor: "#f0f0f0",
                            color: "#717171",
                          }}
                        >
                          Investees
                        </th>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter"
                          style={{
                            backgroundColor: "#f0f0f0",
                            color: "#717171",
                          }}
                        >
                          Investment value
                        </th>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter"
                          style={{
                            backgroundColor: "#f0f0f0",
                            color: "#717171",
                          }}
                        >
                          Number of shares
                        </th>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter"
                          style={{
                            backgroundColor: "#f0f0f0",
                            color: "#717171",
                          }}
                        >
                          Percentage holding
                        </th>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter"
                          style={{
                            backgroundColor: "#f0f0f0",
                            color: "#717171",
                          }}
                        >
                          Nature of relationship
                        </th>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter"
                          style={{
                            padding: 10,
                            borderTopRightRadius: 10,
                            backgroundColor: "#f0f0f0",
                            color: "#717171",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {IrData.map((row, index) => (
                        <tr key={index}>
                          <td
                            style={{ borderBottomLeftRadius: "10px" }}
                            className="bg-white font-size-16 custom-font-500"
                          >
                            {CompanyName(row.investment_company_id)}
                          </td>
                          <td className="bg-white font-size-16 custom-font-500">
                            {(Number(row?.value_of_investment)) + (Number(row?.totalValueOfInvestment))}{" "}
                          </td>
                          <td className="bg-white font-size-16 custom-font-500">
                            {Number(row.no_of_shares) + Number(row.totalHoldings)}
                          </td>
                          <td className="bg-white font-size-16 custom-font-500">
                            {Number(row.holdings) + Number(row.totalHoldings)}
                          </td>
                          <td className="bg-white font-size-16 custom-font-500">
                            {row.nature_of_relationship}
                          </td>
                          <td
                            style={{ borderBottomRightRadius: "10px" }}
                            className="bg-white font-size-16 custom-font-500"
                          >
                            <MoreVertIcon
                              onClick={(e) => handleClick(e, row)}
                              style={{ cursor: "pointer", marginLeft: 10 }}
                            />
                            <Popover
                              id={id}
                              open={open}
                              // anchorEl={anchorEl}
                              anchorEl={anchorEl3}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography sx={{ p: 2 }}>
                                <span
                                  className="font-sans"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "12px",
                                  }}
                                  onClick={() => {
                                    handleEditIr(IrOneData);
                                    handleClose();
                                  }}
                                >
                                  Edit
                                </span>
                                <br />
                                <span
                                  className="font-sans"
                                  onClick={() => {
                                    handleDeleteIr(IrOneData);
                                    handleClose();
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "12px",
                                  }}
                                >
                                  Delete
                                </span>
                              </Typography>
                            </Popover>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="mt-4" style={{ textAlign: "center" }}>
                  <Button
                    style={{
                      border: "2px solid #03565A",
                      width: "150px",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    disabled
                  >
                    <span
                      className="font-sans"
                      style={{
                        color: "#03565A",
                        textTransform: "none",
                        fontWeight: "550",
                      }}
                    >
                      View SH Pattern
                    </span>
                  </Button>
                  <Button
                    style={{
                      border: "2px solid #03565A",
                      width: "150px",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    className="ml-4"
                    disabled
                  >
                    <span
                      className="font-sans"
                      style={{
                        color: "#03565A",
                        textTransform: "none",
                        fontWeight: "550",
                      }}
                    >
                      Export IR
                    </span>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-3 container ">
          <p className="mt-0 mb-1 font-size-18 custom-font-600">
            Share Capital
          </p>
          {data ? (
            <div>
              <Card className="w-100 mb-4 radius-8px">
                <div className="px-3 ">
                  <div className="d-flex justify-content-between w-75 mt-3">
                    <p className="mt-0 mb-0  font-size-16 custom-font-600">
                      Authorized Capital
                    </p>
                    <Button
                      variant="contained"
                      className="bg-white primary-border radius-6px custom-common-theme-text-color font-size-16 custom-font-600 text-capitalize font-sans"
                      sx={{
                        width: "102px",
                        height: "40px",
                        boxShadow: "none",
                        "&:hover": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      Add/Edit
                    </Button>
                  </div>
                  {/* card and table */}
                  <table className="custom-table w-75 mt-2">
                    <thead style={{ backgroundColor: "#F8F8F8" }}>
                      <tr>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter border-top-left-radius text-left header-text"
                          style={{ width: "25%" }}
                        >
                          Class of share
                        </th>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                          style={{ width: "25%" }}
                        >
                          Number of shares
                        </th>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                          style={{ width: "25%" }}
                        >
                          face value
                        </th>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                          style={{ width: "25%" }}
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr className="last-row" >
                          <td className="bg-white font-size-12 custom-font-500 text-left ">
                            <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              Class A
                            </p>
                          </td>
                          <td className="bg-white font-size-12 custom-font-500 text-left">
                            <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              10000
                            </p>
                          </td>
                          <td className="bg-white font-size-12 custom-font-500 text-left">
                            <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              100
                            </p>
                          </td>
                          <td className="bg-white font-size-14 custom-font-500 text-left">
                            <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              1000000
                            </p>
                          </td>
                        </tr>
                      
                    
                    </tbody>
                  </table>
                </div>
                {/* 2nd table */}
                <div className="px-3 mt-2 ">
                  <div className="d-flex justify-content-between w-100 mt-3">
                    <p className="mt-0 mb-0  font-size-16 custom-font-600">
                      Issued Capital
                    </p>
                    <Button
                      variant="contained"
                      className="bg-white primary-border radius-6px custom-common-theme-text-color font-size-16 custom-font-600 text-capitalize font-sans"
                      sx={{
                        width: "102px",
                        height: "40px",
                        boxShadow: "none",
                        "&:hover": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      Add/Edit
                    </Button>
                  </div>
                  {/* card and table */}
                  <div style={{ overflowX: "auto", width: "100%" }}>
                    <table
                      className="custom-table mt-2"
                      style={{ minWidth: "1200px" }}
                    >
                      <thead style={{ backgroundColor: "#F8F8F8" }}>
                        <tr>
                          <th
                            className="text-uppercase font-size-12 custom-font-600 font-inter border-top-left-radius text-left header-text"
                            style={{ width: "300px" }}
                          >
                            Share Holder
                          </th>
                          <th
                            className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                            style={{ width: "250px" }}
                          >
                            Relationship
                          </th>
                          <th
                            className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                            style={{ width: "300px" }}
                          >
                            Class of share
                          </th>
                          <th
                            className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                            style={{ width: "300px" }}
                          >
                            Number of share
                          </th>
                          <th
                            className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                            style={{ width: "200px" }}
                          >
                            Face value
                          </th>
                          <th
                            className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                            style={{ width: "200px" }}
                          >
                            Amount
                          </th>
                          <th
                            className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                            style={{ width: "800px" }}
                          >
                            Holding (fully diluted/ equity share )%
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr className="last-row">
                            <td className="bg-white text-left">
                              <div className="d-flex align-items-center ml-2">
                                <OpenInNewIcon style={{ color:"#1E80EB" ,height:"18px",width:"18px"}} />
                                <p className="mt-0 mb-0 ml-1 font-size-12 custom-font-500 "style={{ color:"#1E80EB" }}>ABC</p>
                              </div>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                                class A
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              999
                              </p>
                            </td>
                            <td className="bg-white text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              100
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              999,000
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              99.90%
                              </p>
                            </td>
                          </tr>
                          <tr className="last-row">
                            <td className="bg-white text-left">
                              <div className="d-flex align-items-center ml-2">
                                <OpenInNewIcon style={{ color:"#1E80EB" ,height:"18px",width:"18px"}} />
                                <p className="mt-0 mb-0 ml-1 font-size-12 custom-font-500 "style={{ color:"#1E80EB" }}>XYZ</p>
                              </div>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                                class A
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              1
                              </p>
                            </td>
                            <td className="bg-white text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              100
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              1,000
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              0.01%
                              </p>
                            </td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* 3rd table */}
                <div className="px-3 mb-4 ">
                  <div className="d-flex justify-content-between w-75 mt-3">
                    <p className="mt-0 mb-0  font-size-16 custom-font-600">
                    Key Managerial Personnel
                    </p>
                    <Button
                      variant="contained"
                      className="bg-white primary-border radius-6px custom-common-theme-text-color font-size-16 custom-font-600 text-capitalize font-sans"
                      sx={{
                        width: "102px",
                        height: "40px",
                        boxShadow: "none",
                        "&:hover": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      Add/Edit
                    </Button>
                  </div>
                  {/* card and table */}
                  <table className="custom-table w-75 mt-2">
                    <thead style={{ backgroundColor: "#F8F8F8" }}>
                      <tr>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter border-top-left-radius text-left header-text"
                          style={{ width: "25%" }}
                        >
                         name
                        </th>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                          style={{ width: "25%" }}
                        >
                          designation
                        </th>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                          style={{ width: "25%" }}
                        >
                          date of appointment
                        </th>
                        <th
                          className="text-uppercase font-size-12 custom-font-600 font-inter text-left header-text"
                          style={{ width: "25%" }}
                        >
                          date of resignation
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr className="last-row">
                            <td className="bg-white text-left">
                              <div className="d-flex align-items-center ml-2">
                                <p className="mt-0 mb-0 ml-1 font-size-12 custom-font-500 ">Mr ABC</p>
                              </div>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              Managing Director
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              April 01, 2018
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              </p>
                            </td>
                        </tr>
                        <tr className="last-row">
                            <td className="bg-white text-left">
                              <div className="d-flex align-items-center ml-2">
                                <p className="mt-0 mb-0 ml-1 font-size-12 custom-font-500 ">Mr LMN</p>
                              </div>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              Company Secretary
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              September 30, 2023
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              </p>
                            </td>
                        </tr>
                        <tr className="last-row">
                            <td className="bg-white text-left">
                              <div className="d-flex align-items-center ml-2">
                                <p className="mt-0 mb-0 ml-1 font-size-12 custom-font-500 ">Mrs PQR</p>
                              </div>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              Chief Financial Officer 
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              April 01, 2024
                              </p>
                            </td>
                            <td className="bg-white  text-left">
                              <p className="mt-0 mb-0 ml-2 font-size-12 custom-font-500">
                              </p>
                            </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </Card>
            </div>
          ) : (
            <Card
              className="w-100 mb-4 radius-8px "
              style={{ height: "400px" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle"
                  style={{
                    backgroundColor: "#FFE3BF",
                    width: "100px",
                    height: "100px",
                  }}
                >
                  <img
                    src={emptyicon}
                    alt="empty icon"
                    style={{ width: "44px", height: "44px" }}
                  />
                </div>
                <p className="mt-3 mt-0 mb-0 font-size-16 custom-font-500">
                  No shared capital details uploaded
                </p>
                <div className="mt-1">
                  <Button
                    variant="outlined"
                    style={{ width: "300px", height: "48px" }}
                    className="custom-border font-size-16 custom-font-600 custom-common-theme-text-color text-capitalize mt-2 radius-6px"
                    onClick={() => history.push("/authorized-capital")}
                  >
                    <AddIcon className="mr-2" />
                    Add Authorized Capital
                  </Button>
                </div>
              </div>
            </Card>
          )}
        </div>
      </div>

      <RegisterModal
        registerModalOpen={registerModalOpen}
        handleRegisterModalClose={handleRegisterModalClose}
        continueStep={continueStep}
      />
    </>
  );
};
export default IRDetails;
