import { React, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import "../../../../WorkSpace/SignUp2.css";
// import AddCompanyModal from "../../Modal/editCompanyModal";
import "../../../../WorkSpace/font.css";
import Tabsystem from "../../../Workspace-v2/Tabsystem";
import Tabs from "@mui/material/Tabs";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { fetchWorkspace } from "../../../../redux/actions/workspace/actionAllWrokspace";
import { useSelector, useDispatch } from "react-redux";

import {
  AllWorkspace,
  GetLease,
  GetLoan,
  GetUser,
  accessWorkspacedata,
  createCompany,
  createDuplicate,
  deleteInvestmentRegister,
  duplicateInvestmentRegister,
  getAllIR,
  getAllInvestRegister,
  getAllWorkspace,
  getCompany,
  getWorkspaceMultiCompany,
} from "../../ApiServices/apiHandler";

import { getProfile } from "../../../../services/api/apiHandler";
import { Navbar } from "../../../../Components/Navbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import swal from "sweetalert";
import InvestmentModal from "../../Modal/investmentModal";
import { IrRegister } from "../../../../Console/Component/services/apiHandler";
import WorkspaceAllData from "../WorkspaceTrue";
import WorkspaceDataFalse from "../WorkspaceEmptydata";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router";
import WorkSpaceData from "../workspaceData";
// import IRData from "../companyDetails";
import IRTab from "../Dashboard/IRTab";
import CompanyDetails from "../Dashboard/companyDetails";
import ShareScreen from "../../../../Components/shareScreen/ShareScreen";
import ShareInvestRegister from "../Dashboard/shareInvestRegister";
import { setCookieUser } from "../../../../services/api/apiHandler";




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 789,
  height: 451,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
// const style1 = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const DashBoard = (props) => {
  const users = JSON.parse(localStorage.getItem('user'));
  console.log(users,"users----------->")
  const [value, setValue] = useState(0);
  const all_workSpace = useSelector((state) => state.AllWSReducer);
  const dispatch = useDispatch();
  const userId = localStorage.getItem("user_id");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //
  const [anchorEl, setAnchorEl] = useState(null);

  const [user, setUser] = useState('');

  const opentab = Boolean(anchorEl);
  const id = opentab ? "simple-popover" : undefined;

  const handleClicktab = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosetab = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = useState(false);
  const [openInvest, setOpenInvest] = useState(false);
  const [openWorkspace, setOpenWorkspace] = useState(false);
  const [continueButton, setContinue] = useState(false);
  const [newcompany, setCompany] = useState("");
  const handleOpen = () => setOpen(true);
  const [allWorkspace, setWorkspace] = useState([]);
  const [allAcessWorkspace, setAllAcessWorkspace] = useState([]);
  const [workspaceAll, setWorkspaceAll] = useState([]);
  const [shareScreen, SetShareScreen] = useState(false);
  const [irId, setIrId] = useState("");
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [loan, setLoan] = useState([]);
  const [lease, setLease] = useState([]);
  const [leasesData,setLeasesData] = useState({
    all_leases:[]
  });
  const [loansData,setLoansData] = useState({
    all_loans:[]
  });
  const handleClose = () => {
    setOpen(false);
    setOpenWorkspace(false);
  };
 
  
  // const [show, setShow] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");
  // const handleChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  const [multipleCompany, setMultipleCompany] = useState([]);
  const [company, setAllCompany] = useState([]);
  const [companydata, setAllCompanyData] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [allIr, setAllIR] = useState([]);
  const [allInvestRegister, setAllInvestRegister] = useState([]);
  const [popClose,setPopClose] = useState(false)
  
  const history = useHistory();
  useEffect(() => {
    if (all_workSpace.isSuccess === false) {
      dispatch(fetchWorkspace());
    }
    allWorkSpace();
    allMultiCompany();
    allCompany();
    accessWorkspace();
    WorkSpaceAll();
    GetAllUser();
    getAllIrData();
    userAccessIr();
    getAll();
    LoanData();
    LeaseData();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('auth_token');
  
    if ( token) {
      setCookieUser(token)
        .catch(error => {
          swal("Error", error.response?.data?.error || "Cookies Error", "error");
        });
    }
  }, []);

  const allMultiCompany = async () => {
    const res = await getCompany();
    if (res.status === 200) {
      const allCompanyData = res?.data?.getCompany?.map(
        (data) => data.company_name
      );
      setMultipleCompany(allCompanyData);
    } else {
      console.log("error");
    }
  };
  const countCompany = (multipleCompany) => {
    const dataAll = multipleCompany.map((det) => det?.length);
    return dataAll?.length;
  };

  const nextStepWorkspace = () => {
    if(users.responsibilities==="approver"){
      swal("Warning", "You Don't have Access to Create Workspace", "warning");
    }else{
      history.push("/workspacelayout");
    }
  };

  const handleClick = () => {
    setOpenWorkspace(true);
  };

  // const addCompany = () => {
  //   setAddCompany(true);
  // };
  const allCompany = async () => {
    const dataAll = await getCompany();
    const allCompanyData = dataAll?.data?.getCompany?.map(
      (data) => data.company_name
    );
    setAllCompanyData(dataAll.data.getCompany);
    setAllCompany(allCompanyData);
  };
  const handleChangeData = (e) => {
    setCompany(e.target.value);
  };

  

  const continueStep = () => {
    props.history.push("/insideworkspace");
  };

  const allWorkSpace = async () => {
    const res = await getAllWorkspace();
    if (res.status === 200) {
      setWorkspace(res.data.getWorkspace);
    }
  };
  const WorkSpaceAll = async () => {
    const res = await AllWorkspace();
    if (res.status === 200) {
      setWorkspaceAll(res.data.workspace);
    }
  };
  const handleClickData = (w_id) => {
    const statusSteps = all_workSpace.response.filter((adj) => adj._id === w_id);
    if (statusSteps[0]?.steps_status === "step1") {
      props.history.push(`/workspacesetup/${w_id}`);
    } else if (statusSteps[0]?.steps_status === "step2") {
      props.history.push(`/addCompanies/${w_id}`);
    } else if (statusSteps[0]?.steps_status === "step3") {
      props.history.push(`/importregister/${w_id}`);
    } else if (statusSteps[0]?.steps_status === "step4") {
      props.history.push(`/importPreset/${w_id}`);
    } else if (statusSteps[0]?.steps_status === "step5") {
      props.history.push(`/inviteteammember/${w_id}`);
    } else if(statusSteps[0]?.steps_status === "step6" && statusSteps[0]?.status==="waiting" || statusSteps[0]?.status==="cancelled" ){
      props.history.push(`/inviteteammember/${w_id}`);
    }else {
      props.history.push(`/financialstatement/${w_id}`);
    }
  };

  const handleAllCompany = () => {
    props.history.push(`/allCompany`);
    window.location.reload();
  };

  const accessWorkspace = async () => {
    const res = await accessWorkspacedata();
    if (res.status === 200) {
      setAllAcessWorkspace(res.data.getAccessWorkspace);
    }
  };

  const workspace = (wid, data) => {
    try {
      const nameWorkspace = workspaceAll?.filter((adj) => adj._id === wid);
      const result = nameWorkspace[0]?.workspace_name
        ? nameWorkspace[0]?.workspace_name
        : "unknown";
      if (data === "id") {
        return result;
      }
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };
  const workspaceCy = (wid, data) => {
    try {
      const cyWorkspace = workspaceAll.filter((adj) => adj._id === wid);
      const result = cyWorkspace[0]?.standalone_cy
        ? cyWorkspace[0]?.standalone_cy
        : "";
      if (data === "id") {
        return result;
      }
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };
  const workspacePy = (wid, data) => {
    try {
      const cyWorkspace = workspaceAll.filter((adj) => adj._id === wid);
      const result = cyWorkspace[0]?.standalone_py
        ? cyWorkspace[0]?.standalone_py
        : "";
      if (data === "id") {
        return result;
      }
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };

  const GetAllUser = async () => {
    const res = await GetUser();
    if (res.status === 200) {
      setAllUser(res.data.fetchUser);
    }
  };

  const UserName = (uid) => {
    try {
      const user = allUser.filter((adj) => adj._id === uid);
      const result = user[0]?.email ? user[0]?.email : "unknown";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };

  const nextWorkspace = () => {
    props.history.push(`/allWorkspace`);
  };

  

  const getAllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIR(res.data.fetchIR);
    }
  };

  const getAll = async () => {
    const res = await getAllInvestRegister();
    if (res.status === 200) {
      console.log(res.data);
      setAllInvestRegister(res.data.fetchIR);
    }
  };

  const handleShareScreen = (id)=>{
    SetShareScreen(true);
    setIrId(id);
    setAnchorEl3(null)
  }
  const hash = allIr.reduce(
    (p, c) => (p[c.ir_id] ? p[c.ir_id].push(c) : (p[c.ir_id] = [c]), p),
    {}
  );
  const newData = Object.keys(hash).map((k) => ({ ir_id: k, car: hash[k] }));
  

  const userAccessIr = ()=>{
    if (allUser.length > 0) {
      const user = allUser.filter((adj) => adj._id === userId);
    const data = user[0]?.access_investregister?.map((det)=>det.investId);
    const Ir_data = data && data.length > 0 ? data.map((red)=>allInvestRegister.filter((det)=>det.ir_id === red)) : [];
     const combinedData = Ir_data.concat(newData);
     return combinedData;
    }
  }

  const handleDuplicate =async (ir_id)=>{
    swal({
      title: "Are you sure?",
      text: " you want to Duplicate this InvestmentRegister?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async(willDelete) => {
      if (willDelete) {
        const res =await duplicateInvestmentRegister(ir_id);
        if (res.status === 200) {
          getAllIrData();
          setAnchorEl3(null)
          swal("Success", "Duplicate Created Successfully", "success");
        }
      }
    });
    
  }

  const LoanData = async () => {
    try {
      const res = await GetLoan();
      if (res.status === 200) {
        // setLoan(res.data.loans);
        setLoansData({...loansData, all_loans:res.data.loans})
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const LeaseData = async () => {
    try {
      const res = await GetLease();
      if (res.status === 200) {
        // setLease(res.data.projects);
        setLeasesData({...leasesData, all_leases:res.data.projects}) 
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };
  
  // delete investment register
  const deleteInvestmentRegisters = async (Ir_id) => {
    swal({
      title: "Are you sure to delete this IR?",
      text: "You won't be able to restore it!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await deleteInvestmentRegister(Ir_id);
          swal("IR Deleted Successfully", {icon: "success",});
          getAll();
          getAllIrData();
          userAccessIr();
          setAnchorEl3(null);
        } catch (err) {
          swal("Error!", err.message || "Something went wrong.", "error", {
            button: "OK",
          });
        }
      }
    });
  };

  return (
    <>
      <div className="pb-2">
        <div>
          <Navbar/>
        </div>
        
      {shareScreen ? <ShareInvestRegister SetShareScreen={SetShareScreen} isInvest={irId} /> : null}
        <WorkSpaceData
          allWorkspace={all_workSpace.response}
          WorkSpaceAll={WorkSpaceAll}
          workspaceData={all_workSpace}
          nextStep={nextStepWorkspace}
          handleClickData={handleClickData}
          allAcessWorkspace={allAcessWorkspace}
          workspace={workspace}
          workspaceCy={workspaceCy}
          workspacePy={workspacePy}
          UserName={UserName}
          handleClick={handleClick}
          allIr={allIr}
          nextWorkspace={nextWorkspace}
          value={value}
          handleChange={handleChange}
          a11yProps={a11yProps}
          handleClicktab={handleClicktab}
          CustomTabPanel={CustomTabPanel}
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          setAllIR={setAllIR}
          company={companydata}
        />
        <div className="dahboardHeaderV2">
          <div className="row">
            <div className={allIr?.length > 0 || userAccessIr()?.length > 0 || loansData.all_loans.length > 0 || leasesData.all_leases.length > 0? "col-md-12" : "col-md-7"}>
              <IRTab
                allWorkspace={all_workSpace.response}
                nextStep={continueStep}
                handleClickData={handleClickData}
                allAcessWorkspace={allAcessWorkspace}
                workspace={workspace}
                UserName={UserName}
                handleClick={handleClick}
                allIr={allIr}
                nextWorkspace={nextWorkspace}
                value={value}
                handleChange={handleChange}
                a11yProps={a11yProps}
                handleClicktab={handleClicktab}
                CustomTabPanel={CustomTabPanel}
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                setAllIR={setAllIR}
                company={companydata}
                insideWorskpace={"no"}
                workspaceID={""}
                handleShareScreen={handleShareScreen}
                shareScreen={shareScreen}
                userAccessIr={userAccessIr}
                allInvestRegister={allInvestRegister}
                handleDuplicate={handleDuplicate}
                popClose={popClose}
                setAnchorEl3={setAnchorEl3}
                anchorEl3={anchorEl3}
                setLoan={setLoan}
                loansData={loansData}
                loan={loan}
                lease={lease}
                setLease={setLease}
                leasesData={leasesData}
                deleteInvestmentRegisters={deleteInvestmentRegisters}
                getAll={getAll}
                getAllIrData={getAllIrData}
              />
            </div>
            {allIr?.length > 0 || userAccessIr()?.length > 0  || loansData.all_loans.length > 0 || leasesData.all_leases.length > 0 ? "" : (
            <div className="col-md-5">
              <CompanyDetails
                  allWorkspace={all_workSpace.response}
                  // nextStep={nextStep}
                  handleClickData={handleClickData}
                  allAcessWorkspace={allAcessWorkspace}
                  workspace={workspace}
                  UserName={UserName}
                  handleClick={handleClick}
                  allIr={allIr}
                  nextWorkspace={nextWorkspace}
                  value={value}
                  handleChange={handleChange}
                  a11yProps={a11yProps}
                  handleClicktab={handleClicktab}
                  CustomTabPanel={CustomTabPanel}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  setAllIR={setAllIR}
                  company={companydata}
                  multipleCompany={multipleCompany}
                  companyAll={allMultiCompany}
                  style={style}
                  handleAllCompany={handleAllCompany}
                  countCompany={countCompany(multipleCompany)}
                />
            </div>
             )}
          </div>
        </div>
        <div className="blankspace50"></div>
      </div>
    </>
  );
};
export default DashBoard;
