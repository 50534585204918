import React, { useState, useEffect } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import moment from "moment";
import {
  PYTableData,
  CYTableData,
  TotalPYTableData,
  TotalCYTableData,
  Opening,
} from "../Socie/soccieTableData";
import { getProjectDetailById } from "../services/apiHandler";
import { useSelector, useDispatch } from "react-redux";
import { fetchSocieData } from "../../../redux/actions/actionSocie";
import { getRandomKey } from "../../../helper/calculation";

import {
  getGroupName,
  getNoteNumber,
  getPatternType,
  getScenario,
  groupDataCal,
  manualAutoAdjustment,
  prepareAdjustmentData,
  prepareAutoPopulationData,
} from "../../../helper/calculation";

const CompareFsSocie = (props) => {
  const {
    allcheckdata,
    currentCompProjectId,
    subsiaryData,
    currentCompany,
    pnlStatementData,
    headingData,
    groupingData,
    consoleNotes,
    FindIr
  } = props;

  const dispatch = useDispatch();
  const getSoccie = useSelector((state) => state.SoccieReducer);
  const AdjData = useSelector((state) => state.ADJReducer);
  const APAdjustment = useSelector((state) => state.APAReducer);
  const preAdjustment = useSelector((state) => state.PADJReducer.response);
  const preAdjustmentOther = useSelector(
    (state) => state.PADJReducer.otherResponse
  );
  const [currentProjectSoccie, setCurrentProjectSoccie] = useState([]);
  const [projectIdsSoccie, setProjectIdsSoccie] = useState([]);
  const [browseFsSoccie, setBrowseFsSoccie] = useState([]);
  const [currentProjectName, setCurrentProjectName] = useState("");
  const [soccieTotalData, setSoccieTotalData] = useState([]);
  const [no, setNo] = useState([]);
  const SoccieProject = currentProjectSoccie[0];
  const currentProjectPnl =
    pnlStatementData.response.data?.pnl_statement?.pnl_statement ?? [];
  const projectIdsPnl =
    pnlStatementData.response?.data?.project_ids_pnl?.map(
      (data) => data.pnl_statement
    ) ?? [];
  const projectFssPnl =
    pnlStatementData.response?.data?.browse_fs_pnl?.map(
      (data) => data.pnl_statement
    ) ?? [];

  const heading = {
    color: "var(--clr-accent)",
    fontWeight: "700",
  };

  useEffect(() => {
    if (allcheckdata?.length > 0) {
      const browse_fs = allcheckdata.map((data) => FindIr(data.Ir_id, true));
      const payload = {
        project_ids: allcheckdata.map((data) => data.project_id),
        browse_fs_ids: browse_fs.map(
          (data) => data[0]?.prepare_preset_project_id
        ),
        current_comp_project_id: currentCompProjectId,
      };
      if (
        !getSoccie.isSuccess ||
        getSoccie.payload !== JSON.stringify(payload)
      ) {
        dispatch(fetchSocieData(payload));
      }
    }
  }, []);

  useEffect(() => {
    if (getSoccie.isSuccess) {
      CurrentProjectData();
      ProjectIdsData();
      BrowseFsData();
      getCurrentDataName(currentCompProjectId);
      // OpeningValue();
    }
  }, [getSoccie]);

  useEffect(() => {
    if (currentProjectSoccie.length > 0 && projectIdsSoccie.length > 0) {
      MergeSoccieTable();
    }
  }, [currentProjectSoccie, projectIdsSoccie]);

  useEffect(() => {
    let noArr = [];
    fsRows.map((row, i) => {
      currentProjectPnl[row].map((line, ix) => {
        if (
          noArr.length > 0 &&
          noArr.some(
            (user) =>
              user.notes_grp.toLowerCase() === line.notes_grp.toLowerCase()
          )
        ) {
        } else {
          noArr.push({ notes_grp: line.notes_grp });
        }
      });
    });
    setNo(noArr);
  }, []);

  const getNoteNoIndex = (notes_no) => {
    try {
      let resultIndex = 0;
      Object.keys(consoleNotes).forEach(function (key, index2) {
        let index = consoleNotes[key].find(
          (data) => data.note_number === notes_no
        );
        if (index !== undefined) {
          resultIndex = index.index;
        }
      });
      return resultIndex;
    } catch (error) {
      return "00";
    }
  };

  const MergeSoccieTable = () => {
    try {
      const finalResult = [];
      currentProjectSoccie.forEach((dataset, index) => {
        const keyData = {};
        Object.keys(dataset).forEach((key, index2) => {
          dataset[key].forEach((item, index3) => {
            if (keyData[key] === undefined) {
              keyData[key] = [];
            }
            const newData = {};
            Object.keys(item).forEach((keyname) => {
              if (keyname === "row_header") {
                newData[keyname] = item[keyname];
              } else if (keyname === "sub_grp") {
                newData[keyname] = item[keyname];
              } else {
                newData[keyname] =
                  Number(item[keyname]) +
                  projectIdsSoccie
                    .map((item2) => Number(item2[index][key][index3][keyname]))
                    .reduce((num, acc) => num + acc, 0);
              }
            });
            keyData[key].push(newData);
          });
        });
        finalResult.push(keyData);
      });
      setSoccieTotalData(finalResult);
    } catch (error) {
    }
  };

  const CurrentProjectData = () => {
    const currentProjectSoccie =
      getSoccie.response?.data?.curent_comp_project?.socie_list ?? [];
    setCurrentProjectSoccie(currentProjectSoccie);
    try {
      const previousYearData = currentProjectSoccie[0].previous_row_header.map(
        (data) => {
          if (data.row_header === "Profit for the year") {
            return {
              ...data,
              "Retained Earnings": PreviousYearProfit().toFixed(2),
            };
          }
          return { ...data };
        }
      );
      const currentYearData = currentProjectSoccie[0].current_row_header.map(
        (data) => {
          if (data.row_header === "Profit for the year") {
            return {
              ...data,
              "Retained Earnings": currentYearProfit().toFixed(2),
            };
          }
          return { ...data };
        }
      );
      currentProjectSoccie[0].previous_row_header = previousYearData;
      currentProjectSoccie[0].current_row_header = currentYearData;
      setCurrentProjectSoccie(currentProjectSoccie);
    } catch (error) {
    }
  };

  const ProjectIdsData = () => {
    const projectIdsSoccie =
      getSoccie.response?.data?.final_balance_sheet
        ?.flat()
        .map((data) => data.socie_list) ?? [];
    setProjectIdsSoccie(projectIdsSoccie);
    try {
      const projectSoccie = projectIdsSoccie.map((projectSoccie, index) =>
        ProjectIdTotalProfit(projectSoccie, index)
      );
      setProjectIdsSoccie(projectSoccie);
    } catch (error) {
    }
  };

  const BrowseFsData = () => {
    const browseFsSoccie =
      getSoccie.response?.data?.browse_fs_socie
        ?.flat()
        .map((data) => data.socie_list) ?? [];
    setBrowseFsSoccie(browseFsSoccie);
  };

  const ProjectIdTotalProfit = (projectSoccie, pIndex) => {
    const data_type_1 = "Debt instruments through Other Comprehensive Income";
    const data_type_2 = "Equity Instruments through Other Comprehensive Income";
    const data_type_3 = "Effective portion of Cash Flow Hedges";
    const data_type_4 = "Revaluation Surplus";
    const data_type_5 =
      "Exchange differences on translating the financial statements of a foreign operation";
    try {
      const { first_scenario, second_scenario } = getScenario(
        currentCompany,
        subsiaryData,
        pIndex,
        FindIr
      );
      const previousYearData = projectSoccie[0].previous_row_header.map(
        (data) => {
          if (first_scenario) {
            Object.keys(data).map((keyname, index) => {
              if (keyname !== "row_header") {
                data[keyname] = 0;
              }
            });
            return { ...data };
          } else if (second_scenario) {
            if (data.row_header === "Profit for the year") {
              return {
                ...data,
                "Retained Earnings":
                  ProjectIdCurrentYearProfit(pIndex).toFixed(2),
              };
            } else if (data.row_header === "Other comprehensive income") {
              return {
                ...data,
                [data_type_1]: getPnlValue(
                  pIndex,
                  64,
                  "Fair value changes in Debt Instruments through other comprehensive income",
                  "py_amt"
                ),
                [data_type_2]: getPnlValue(
                  pIndex,
                  63,
                  "Fair value changes on Equity Instruments through other comprehensive income",
                  "py_amt"
                ),
                [data_type_3]: getPnlValue(
                  pIndex,
                  64,
                  "The effective portion of gain and loss on hedging instruments in a cash flow hedge",
                  "py_amt"
                ),
                [data_type_4]: getPnlValue(
                  pIndex,
                  63,
                  "Changes in revaluation surplus",
                  "py_amt"
                ),
                [data_type_5]: getPnlValue(
                  pIndex,
                  64,
                  "Exchange differences in translating the financial statements of a foreign operation",
                  "py_amt"
                ),
                ["Retained Earnings"]: 0,
              };
            }
            return { ...data };
          } else {
            if (data.row_header === "Profit for the year") {
              return {
                ...data,
                "Retained Earnings":
                  ProjectIdPreviousYearProfit(pIndex).toFixed(2),
              };
            }
            return { ...data }
          }
        }
      );
      const currentYearData = projectSoccie[0].current_row_header.map(
        (data, cIndex) => {
          if (second_scenario) {
            if (
              data.row_header ===
              "Balance at the beginning of the previous reporting period"
            ) {
              Object.keys(data).map((keyname, index) => {
                if (keyname !== "row_header") {
                  data[keyname] = (
                    RestatedBalanceCalPy(projectSoccie[0], 2, keyname) +
                    ComprehensiveCalPy(projectSoccie[0], 4, keyname) +
                    TotalCalPy(projectSoccie[0], 9, keyname)
                  ).toFixed(2);
                }
              });
              return { ...data };
            }
          }
          if (data.row_header === "Profit for the year") {
            return {
              ...data,
              "Retained Earnings":
                ProjectIdCurrentYearProfit(pIndex).toFixed(2),
            };
          } else if (data.row_header === "Other comprehensive income") {
            return {
              ...data,
              [data_type_1]: getPnlValue(
                pIndex,
                64,
                "Fair value changes in Debt Instruments through other comprehensive income"
              ),
              [data_type_2]: getPnlValue(
                pIndex,
                63,
                "Fair value changes on Equity Instruments through other comprehensive income"
              ),
              [data_type_3]: getPnlValue(
                pIndex,
                64,
                "The effective portion of gain and loss on hedging instruments in a cash flow hedge"
              ),
              [data_type_4]: getPnlValue(
                pIndex,
                63,
                "Changes in revaluation surplus"
              ),
              [data_type_5]: getPnlValue(
                pIndex,
                64,
                "Exchange differences in translating the financial statements of a foreign operation"
              ),
              ["Retained Earnings"]: 0,
            };
          }
          return { ...data };
        }
      );
      projectSoccie[0].previous_row_header = previousYearData;
      projectSoccie[0].current_row_header = currentYearData;
      return projectSoccie;
    } catch (error) {
      return 0;
    }
  };

  const getCurrentDataName = async (currentCompProjectId) => {
    const res = await getProjectDetailById(currentCompProjectId);
    setCurrentProjectName(res.data.project.project_name);
  };

  const rows =
    pnlStatementData.response?.data?.pnl_statement?.pnl_statement ?? [];
  const fsRows = Object.keys(rows);

  function sum(obj) {
    delete obj["_id"];
    delete obj["row_header"];
    return Object.keys(obj).reduce(
      (sum, key) => sum + parseInt(obj[key] || 0),
      0
    );
  }

  const objectData = (socie_data) => {
    const res =
      socie_data.response?.data?.curent_comp_project?.socie_list[0]?.previous_row_header.map(
        (data, key) => sum(Object.assign({}, data))
      );
    return res;
  };

  const RestatedBalanceCalPy = (soccieData, previous_row_index, soccie_key) => {
    try {
      const result = soccieData.previous_row_header
        .slice(0, previous_row_index)
        .map((data) => Number(data[soccie_key]))
        .reduce((data, num) => data + num, 0);
      return result;
    } catch (error) {
      return "00";
    }
  };

  const RestatedBalanceCalCy = (soccieData, current_row_index, soccie_key) => {
    try {
      return soccieData.current_row_header
        .slice(0, current_row_index)
        .map((data) => Number(data[soccie_key]))
        .reduce((data, num) => data + num, 0);
    } catch (error) {
      return "00";
    }
  };

  const TotalRestatedBalanceCalPy = (prIndex) => {
    try {
      const currentProjectRBP = currentProjectSoccie[0].previous_row_header.map(
        (soccie, pr_index) => {
          if (pr_index === prIndex) {
            return Object.keys(soccie).map((keyname, soccie_index) => {
              if (keyname !== "row_header" && keyname !== "sub_grp") {
                return RestatedBalanceCalPy(
                  currentProjectSoccie[0],
                  pr_index,
                  keyname
                );
              } else {
                return 0;
              }
            });
          } else {
            return 0;
          }
        }
      );

      const projectIdsRBP = projectIdsSoccie.map(
        (projectSocie, project_index) => {
          return projectSocie[0].previous_row_header
            .map((soccie, pr_index) => {
              if (pr_index === prIndex) {
                return Object.keys(soccie).map((keyname, soccie_index) => {
                  if (keyname !== "row_header" && keyname !== "sub_grp") {
                    return RestatedBalanceCalPy(
                      currentProjectSoccie[0],
                      pr_index,
                      keyname
                    );
                  } else {
                    return 0;
                  }
                });
              } else {
                return 0;
              }
            })
            .flat();
        }
      );
      const currentProjectTotal = currentProjectRBP
        .flat()
        .reduce((data, num) => data + num, 0);
      const projectIdTotal = projectIdsRBP
        .flat()
        .reduce((data, num) => data + num, 0);
      return (currentProjectTotal + projectIdTotal).toFixed(2);
    } catch (error) {
      return "--";
    }
  };

  const TotalRestatedBalanceCalCy = (prIndex) => {
    try {
      const currentProjectRBP = currentProjectSoccie[0].current_row_header.map(
        (soccie, pr_index) => {
          if (pr_index === prIndex) {
            return Object.keys(soccie).map((keyname, soccie_index) => {
              if (keyname !== "row_header" && keyname !== "sub_grp") {
                return RestatedBalanceCalCy(
                  currentProjectSoccie[0],
                  pr_index,
                  keyname
                );
              } else {
                return 0;
              }
            });
          } else {
            return 0;
          }
        }
      );

      const projectIdsRBP = projectIdsSoccie.map(
        (projectSocie, project_index) => {
          return projectSocie[0].current_row_header
            .map((soccie, pr_index) => {
              if (pr_index === prIndex) {
                return Object.keys(soccie).map((keyname, soccie_index) => {
                  if (keyname !== "row_header" && keyname !== "sub_grp") {
                    return RestatedBalanceCalCy(
                      currentProjectSoccie[0],
                      pr_index,
                      keyname
                    );
                  } else {
                    return 0;
                  }
                });
              } else {
                return 0;
              }
            })
            .flat();
        }
      );
      const currentProjectTotal = currentProjectRBP
        .flat()
        .reduce((data, num) => data + num, 0);
      const projectIdTotal = projectIdsRBP
        .flat()
        .reduce((data, num) => data + num, 0);
      return (currentProjectTotal + projectIdTotal).toFixed(2);
    } catch (error) {
      return "--";
    }
  };

  const TotalRowCalPy = (prIndex) => {
    try {
      const currentProjectTRC = currentProjectSoccie[0].previous_row_header.map(
        (soccie, pr_index) => {
          if (pr_index === prIndex) {
            return Object.keys(soccie).map((keyname, soccie_index) => {
              if (keyname !== "row_header" && keyname !== "sub_grp") {
                return Number(soccie[keyname]);
              } else {
                return 0;
              }
            });
          } else {
            return 0;
          }
        }
      );

      const projectIdsTRC = projectIdsSoccie.map(
        (projectSocie, project_index) => {
          return projectSocie[0].previous_row_header
            .map((soccie, pr_index) => {
              if (pr_index === prIndex) {
                return Object.keys(soccie).map((keyname, soccie_index) => {
                  if (keyname !== "row_header" && keyname !== "sub_grp") {
                    return Number(soccie[keyname]);
                  } else {
                    return 0;
                  }
                });
              } else {
                return 0;
              }
            })
            .flat();
        }
      );

      const currentProjectTotal = currentProjectTRC
        .flat()
        .reduce((data, num) => data + num, 0);
      const projectIdTotal = projectIdsTRC
        .flat()
        .reduce((data, num) => data + num, 0);
      return (currentProjectTotal + projectIdTotal).toFixed(2);
    } catch (error) {
      return "--";
    }
  };

  const TotalCalCy = (soccieData, current_row_index, soccie_key) => {
    try {
      return soccieData.current_row_header
        .slice(6, current_row_index)
        .map((data) => Number(data[soccie_key]))
        .reduce((data, num) => data + num, 0);
    } catch (error) {
      return "00";
    }
  };

  const TotalRowCalCy = (prIndex) => {
    try {
      const currentProjectTRC = currentProjectSoccie[0].current_row_header.map(
        (soccie, pr_index) => {
          if (pr_index === prIndex) {
            return Object.keys(soccie).map((keyname, soccie_index) => {
              if (keyname !== "row_header" && keyname !== "sub_grp") {
                return Number(soccie[keyname]);
              } else {
                return 0;
              }
            });
          } else {
            return 0;
          }
        }
      );

      const projectIdsTRC = projectIdsSoccie.map(
        (projectSocie, project_index) => {
          return projectSocie[0].current_row_header
            .map((soccie, pr_index) => {
              if (pr_index === prIndex) {
                return Object.keys(soccie).map((keyname, soccie_index) => {
                  if (keyname !== "row_header" && keyname !== "sub_grp") {
                    return Number(soccie[keyname]);
                  } else {
                    return 0;
                  }
                });
              } else {
                return 0;
              }
            })
            .flat();
        }
      );

      const currentProjectTotal = currentProjectTRC
        .flat()
        .reduce((data, num) => data + num, 0);
      const projectIdTotal = projectIdsTRC
        .flat()
        .reduce((data, num) => data + num, 0);
      return (currentProjectTotal + projectIdTotal).toFixed(2);
    } catch (error) {
      return "--";
    }
  };

  const TotalCalPy = (soccieData, previous_row_index, soccie_key) => {
    try {
      return soccieData.previous_row_header
        .slice(6, previous_row_index)
        .map((data) => Number(data[soccie_key]))
        .reduce((data, num) => data + num, 0);
    } catch (error) {
      return "00";
    }
  };

  const TotalOfTotalPy = (prIndex) => {
    try {
      const currentProjectTTP = currentProjectSoccie[0].previous_row_header.map(
        (soccie, pr_index) => {
          if (pr_index === prIndex) {
            return Object.keys(soccie).map((keyname, soccie_index) => {
              if (keyname !== "row_header" && keyname !== "sub_grp") {
                return TotalCalPy(currentProjectSoccie[0], pr_index, keyname);
              } else {
                return 0;
              }
            });
          } else {
            return 0;
          }
        }
      );

      const projectIdsTTP = projectIdsSoccie.map(
        (projectSocie, project_index) => {
          return projectSocie[0].previous_row_header
            .map((soccie, pr_index) => {
              if (pr_index === prIndex) {
                return Object.keys(soccie).map((keyname, soccie_index) => {
                  if (keyname !== "row_header" && keyname !== "sub_grp") {
                    return TotalCalPy(
                      currentProjectSoccie[0],
                      pr_index,
                      keyname
                    );
                  } else {
                    return 0;
                  }
                });
              } else {
                return 0;
              }
            })
            .flat();
        }
      );
      const currentProjectTotal = currentProjectTTP
        .flat()
        .reduce((data, num) => data + num, 0);
      const projectIdTotal = projectIdsTTP
        .flat()
        .reduce((data, num) => data + num, 0);
      return (currentProjectTotal + projectIdTotal).toFixed(2);
    } catch (error) {
      return "--";
    }
  };

  const TotalOfTotalCy = (prIndex) => {
    try {
      const currentProjectTTP = currentProjectSoccie[0].current_row_header.map(
        (soccie, pr_index) => {
          if (pr_index === prIndex) {
            return Object.keys(soccie).map((keyname, soccie_index) => {
              if (keyname !== "row_header" && keyname !== "sub_grp") {
                return TotalCalCy(
                  currentProjectSoccie[0],
                  pr_index + 1,
                  keyname
                );
              } else {
                return 0;
              }
            });
          } else {
            return 0;
          }
        }
      );
      const projectIdsTTP = projectIdsSoccie.map(
        (projectSocie, project_index) => {
          return projectSocie[0].current_row_header
            .map((soccie, pr_index) => {
              if (pr_index === prIndex) {
                return Object.keys(soccie).map((keyname, soccie_index) => {
                  if (keyname !== "row_header" && keyname !== "sub_grp") {
                    return TotalCalCy(
                      currentProjectSoccie[0],
                      pr_index + 1,
                      keyname
                    );
                  } else {
                    return 0;
                  }
                });
              } else {
                return 0;
              }
            })
            .flat();
        }
      );
      const currentProjectTotal = currentProjectTTP
        .flat()
        .reduce((data, num) => data + num, 0);
      const projectIdTotal = projectIdsTTP
        .flat()
        .reduce((data, num) => data + num, 0);
      return (currentProjectTotal + projectIdTotal).toFixed(2);
    } catch (error) {
      return "--";
    }
  };

  const ComprehensiveCalPy = (soccieData, previous_row_index, soccie_key) => {
    try {
      return soccieData.previous_row_header
        .slice(2, previous_row_index)
        .map((data) => Number(data[soccie_key]))
        .reduce((data, num) => data + num, 0);
    } catch (error) {
      return "00";
    }
  };

  const ComprehensiveCalCy = (soccieData, current_row_index, soccie_key) => {
    try {
      return soccieData.current_row_header
        .slice(2, current_row_index)
        .map((data) => Number(data[soccie_key]))
        .reduce((data, num) => data + num, 0);
    } catch (error) {
      return "00";
    }
  };

  const TotalComprehensiveCalPy = (prIndex) => {
    try {
      const currentProjectCCP = currentProjectSoccie[0].previous_row_header.map(
        (soccie, pr_index) => {
          if (pr_index === prIndex) {
            return Object.keys(soccie).map((keyname, soccie_index) => {
              if (keyname !== "row_header" && keyname !== "sub_grp") {
                return ComprehensiveCalPy(
                  currentProjectSoccie[0],
                  pr_index,
                  keyname
                );
              } else {
                return 0;
              }
            });
          } else {
            return 0;
          }
        }
      );

      const projectIdsCCP = projectIdsSoccie.map(
        (projectSocie, project_index) => {
          return projectSocie[0].previous_row_header
            .map((soccie, pr_index) => {
              if (pr_index === prIndex) {
                return Object.keys(soccie).map((keyname, soccie_index) => {
                  if (keyname !== "row_header" && keyname !== "sub_grp") {
                    return ComprehensiveCalPy(
                      currentProjectSoccie[0],
                      pr_index,
                      keyname
                    );
                  } else {
                    return 0;
                  }
                });
              } else {
                return 0;
              }
            })
            .flat();
        }
      );
      const currentProjectTotal = currentProjectCCP
        .flat()
        .reduce((data, num) => data + num, 0);
      const projectIdTotal = projectIdsCCP
        .flat()
        .reduce((data, num) => data + num, 0);
      return (currentProjectTotal + projectIdTotal).toFixed(2);
    } catch (error) {
      return "--";
    }
  };

  const TotalComprehensiveCalCy = (prIndex) => {
    try {
      const currentProjectCCP = currentProjectSoccie[0].current_row_header.map(
        (soccie, pr_index) => {
          if (pr_index === prIndex) {
            return Object.keys(soccie).map((keyname, soccie_index) => {
              if (keyname !== "row_header" && keyname !== "sub_grp") {
                return ComprehensiveCalCy(
                  currentProjectSoccie[0],
                  pr_index,
                  keyname
                );
              } else {
                return 0;
              }
            });
          } else {
            return 0;
          }
        }
      );

      const projectIdsCCP = projectIdsSoccie.map(
        (projectSocie, project_index) => {
          return projectSocie[0].current_row_header
            .map((soccie, pr_index) => {
              if (pr_index === prIndex) {
                return Object.keys(soccie).map((keyname, soccie_index) => {
                  if (keyname !== "row_header" && keyname !== "sub_grp") {
                    return ComprehensiveCalCy(
                      currentProjectSoccie[0],
                      pr_index,
                      keyname
                    );
                  } else {
                    return 0;
                  }
                });
              } else {
                return 0;
              }
            })
            .flat();
        }
      );
      const currentProjectTotal = currentProjectCCP
        .flat()
        .reduce((data, num) => data + num, 0);
      const projectIdTotal = projectIdsCCP
        .flat()
        .reduce((data, num) => data + num, 0);
      return (currentProjectTotal + projectIdTotal).toFixed(2);
    } catch (error) {
      return "--";
    }
  };

  const AcquisitionSubsidiaryPy = (project_index, keyname) => {
    try {
      const row_data =
        "Balance at the beginning of the previous reporting period";
      const { first_scenario, second_scenario } = getScenario(
        currentCompany,
        subsiaryData,
        project_index,
        FindIr
      );
      if (second_scenario) {
        const BrowseData = browseFsSoccie[
          project_index
        ][0].previous_row_header.find((data) => data.row_header === row_data);
        if (BrowseData) {
          return BrowseData[keyname];
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const AcquisitionSubsidiaryCy = (project_index, keyname) => {
    try {
      const row_data =
        "Balance at the beginning of the previous reporting period";
      const { first_scenario, second_scenario } = getScenario(
        currentCompany,
        subsiaryData,
        project_index,
        FindIr
      );
      if (first_scenario) {
        const BrowseData = browseFsSoccie[
          project_index
        ][0].current_row_header.find((data) => data.row_header === row_data);
        if (BrowseData) {
          return BrowseData[keyname];
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const CurrentProjectCy = (group_name, total_tax = false) => {
    if (group_name === "Expenses") {
      let res = 0;
      if (total_tax) {
        res = currentProjectPnl[group_name].filter(
          (data) =>
            data.notes_grp === "Current tax" ||
            data.notes_grp === "Deferred tax"
        );
      } else {
        res = currentProjectPnl[group_name].filter(
          (data) =>
            data.notes_grp !== "Current tax" &&
            data.notes_grp !== "Deferred tax"
        );
      }
      return res.map((data) => data.cy).reduce((num, acc) => num + acc, 0);
    }
    return currentProjectPnl[group_name]
      .map((data) => data.cy)
      .reduce((num, acc) => num + acc, 0);
  };

  const CurrentProjectPy = (group_name, total_tax = false) => {
    if (group_name === "Expenses") {
      let res = 0;
      if (total_tax) {
        res = currentProjectPnl[group_name].filter(
          (data) =>
            data.notes_grp === "Current tax" ||
            data.notes_grp === "Deferred tax"
        );
      } else {
        res = currentProjectPnl[group_name].filter(
          (data) =>
            data.notes_grp !== "Current tax" &&
            data.notes_grp !== "Deferred tax"
        );
      }
      return res.map((data) => data.py).reduce((num, acc) => num + acc, 0);
    }
    return currentProjectPnl[group_name]
      .map((data) => data.py)
      .reduce((num, acc) => num + acc, 0);
  };

  const PreviousYearProfit = () => {
    try {
      const profitBeforeExceptionalItem =
        CurrentProjectPy("Income") - CurrentProjectPy("Expenses");
      const TotalTax = CurrentProjectPy("Expenses", true);
      return profitBeforeExceptionalItem - TotalTax;
    } catch (error) {
      return "-";
    }
  };

  const currentYearProfit = () => {
    try {
      const profitBeforeExceptionalItem =
        CurrentProjectCy("Income") - CurrentProjectCy("Expenses");
      const TotalTax = CurrentProjectCy("Expenses", true);
      return profitBeforeExceptionalItem - TotalTax;
    } catch (error) {
      return "-";
    }
  };

  const ProjectIDCy = (
    pnl_statement,
    group_name,
    project_index,
    total_tax = false
  ) => {
    const { first_scenario, second_scenario } = getScenario(
      currentCompany,
      subsiaryData,
      project_index,
      FindIr
    );
    let result = 0;
    if (group_name === "Expenses") {
      let res = 0;
      if (total_tax) {
        res = pnl_statement[group_name].filter(
          (data) =>
            data.notes_grp === "Current tax" ||
            data.notes_grp === "Deferred tax"
        );
      } else {
        res = pnl_statement[group_name].filter(
          (data) =>
            data.notes_grp !== "Current tax" &&
            data.notes_grp !== "Deferred tax"
        );
      }
      result = res.map((data) => data.cy).reduce((num, acc) => num + acc, 0);
    } else {
      result = pnl_statement[group_name]
        .map((data) => data.cy)
        .reduce((num, acc) => num + acc, 0);
    }
    if (first_scenario) {
      return result;
    } else if (second_scenario) {
      return result;
    } else {
      return result;
    }
  };

  const ProjectIDPy = (
    pnl_statement,
    group_name,
    project_index,
    total_tax = false
  ) => {
    const { first_scenario, second_scenario } = getScenario(
      currentCompany,
      subsiaryData,
      project_index,
      FindIr
    );
    let result = 0;
    if (group_name === "Expenses") {
      let res = 0;
      if (total_tax) {
        res = pnl_statement[group_name].filter(
          (data) =>
            data.notes_grp === "Current tax" ||
            data.notes_grp === "Deferred tax"
        );
      } else {
        res = pnl_statement[group_name].filter(
          (data) =>
            data.notes_grp !== "Current tax" &&
            data.notes_grp !== "Deferred tax"
        );
      }
      result = res.map((data) => data.py).reduce((num, acc) => num + acc, 0);
    } else {
      result = pnl_statement[group_name]
        .map((data) => data.py)
        .reduce((num, acc) => num + acc, 0);
    }
    if (first_scenario) {
      //   return 0;
      return result;
    } else if (second_scenario) {
      return result;
    } else {
      return result;
    }
  };

  const getProjectCy = (BrowsFsData, project_data, sub_index) => {
    try {
      const { first_scenario, second_scenario } = getScenario(
        currentCompany,
        subsiaryData,
        sub_index,
        FindIr
      );
      const projectCy = project_data.cy_amt ?? 0;
      const browseFsCy = BrowsFsData.map((browse_item) => {
        if (browse_item.group === project_data.group) {
          return browse_item.cy_amt;
        } else {
          return 0;
        }
      }).reduce((num, acc) => num + acc, 0);
      if (first_scenario) {
        return projectCy - browseFsCy;
      } else if (second_scenario) {
        return projectCy;
      } else {
        return projectCy;
      }
    } catch (error) { }
  };

  const ProjectIdPnlCy = (
    sub_index,
    note_group,
    note_index,
    total_tax = false
  ) => {
    try {
      if (note_group) {
        let note_no = getNoteNumber(consoleNotes, note_index);
        if (note_no === "--") {
          return "...";
        }
        const { Projects, BrowsFsData } = groupDataCal(groupingData, note_no);
        const result = Projects[sub_index]
          .flat()
          .map((project_data) => {
            return getProjectCy(
              BrowsFsData[sub_index].flat(),
              project_data,
              sub_index
            );
          })
          .reduce((num, acc) => num + acc, 0);
        return result;
      }
      return "loading...";
    } catch (error) {
      return "00";
    }
  };

  const grandTotalProjectPnlCy = (group, sub_index, total_tax = false) => {
    if (
      group === "Income" ||
      group === "Exceptional items" ||
      group === "Profit / (loss) from discontinued operations" ||
      group === "Other Comprehensive Income"
    ) {
      const result = currentProjectPnl[group]
        .map((noteGroup) => {
          return ProjectIdPnlCy(
            sub_index,
            noteGroup.notes_grp,
            getNoteNoIndex(noteGroup.notes_no)
          );
        })
        .reduce((num, acc) => num + acc, 0);
      return result;
    } else if (group === "Expenses") {
      const result = currentProjectPnl[group]
        .map((noteGroup) => {
          if (total_tax) {
            if (
              noteGroup.notes_grp === "Current tax" ||
              noteGroup.notes_grp === "Deferred tax"
            ) {
              return ProjectIdPnlCy(
                sub_index,
                noteGroup.notes_grp,
                getNoteNoIndex(noteGroup.notes_no)
              );
            } else {
              return 0;
            }
          } else {
            if (
              noteGroup.notes_grp === "Current tax" ||
              noteGroup.notes_grp === "Deferred tax"
            ) {
              return 0;
            } else {
              return ProjectIdPnlCy(
                sub_index,
                noteGroup.notes_grp,
                getNoteNoIndex(noteGroup.notes_no)
              );
            }
          }
        })
        .reduce((num, acc) => num + acc, 0);
      return result;
    }
    return "loading...";
  };

  const ProjectIdPreviousYearProfit = (project_index) => {
    try {
      const project_pnl = projectIdsPnl[project_index];
      const profitBeforeExceptionalItem =
        ProjectIDPy(project_pnl, "Income", project_index) -
        ProjectIDPy(project_pnl, "Expenses", project_index);
      const TotalTax = ProjectIDPy(
        project_pnl,
        "Expenses",
        project_index,
        true
      );
      return profitBeforeExceptionalItem - TotalTax;
    } catch (error) {
      return 0;
    }
  };

  const ProjectIdCurrentYearProfit = (project_index) => {
    try {
      return (
        grandTotalProjectPnlCy("Income", project_index) -
        grandTotalProjectPnlCy("Expenses", project_index) -
        grandTotalProjectPnlCy("Expenses", project_index, true)
      );
    } catch (error) {
      return 0;
    }
  };
  const OpeningValue = () => {
    const scenarioResult = subsiaryData.map((sub_data, sub_index) => {
      return {
        scenario: getScenario(currentCompany, subsiaryData, sub_index, FindIr),
        brows_fs:
          groupingData.response.data.browse_fs_grouping[sub_index]?.fs_grp[9]
            .notes_grp[2].sub_grp[0],
        brows_fs_equity:
          groupingData.response.data.browse_fs_grouping[sub_index]?.fs_grp[9]
            .notes_grp[3].sub_grp,
      };
    });
    const browsDataRes = scenarioResult.filter(
      (data) => data.brows_fs_equity !== undefined
    );
  };

  const getPnlValue = (project_index, note_index, group, key = "cy_amt") => {
    try {
      let note_no = getNoteNumber(consoleNotes, note_index);
      if (note_no === "--") {
        return "...";
      }
      const { first_scenario, second_scenario } = getScenario(
        currentCompany,
        subsiaryData,
        project_index,
        FindIr
      );
      const { Projects, BrowsFsData } = groupDataCal(groupingData, note_no);
      const project_data =
        Projects[project_index].flat().find((item) => item.group === group)[
        key
        ] ?? 0;
      const brows_fs_data =
        BrowsFsData[project_index].flat().find((item) => item.group === group)[
        key
        ] ?? 0;
      if (first_scenario) {
        return (brows_fs_data - project_data).toFixed(2);
      } else if (second_scenario) {
        if (key === "py_amt") {
          return (brows_fs_data - project_data).toFixed(2);
        }
        return project_data.toFixed(2);
      } else {
        return project_data.toFixed(2);
      }
    } catch (error) {
      return 0;
    }
  };

  const totalAdjustment = (
    note_group_index,
    note_group,
    scenario,
    note_index
  ) => {
    try {
      const GroupData = groupingData.response.data;
      if (
        note_group_index === 0 ||
        note_group_index === 1 ||
        note_group_index === 2 ||
        note_group_index === 3 ||
        note_group_index === 4
      ) {
        let note_no = getNoteNumber(consoleNotes, note_index);
        if (note_no === "--") {
          return "...";
        }
        const groupResult = groupDataCal(groupingData, note_no);
        const Groups = getGroupName(
          GroupData.current_company_grouping.fs_grp,
          note_no
        );
        const adjustmentData = prepareAdjustmentData(AdjData, note_no);
        const autoPopulateData = prepareAutoPopulationData(
          APAdjustment,
          note_no
        );
        const { currentProject } = groupResult;
        const finalResult = currentProject.map(
          (projectGroup, group_index) =>
            projectGroup
              .map((data, project_index) =>
                adjustmentResult(
                  groupResult,
                  adjustmentData,
                  autoPopulateData,
                  group_index,
                  scenario,
                  project_index,
                  false,
                  getPatternType(consoleNotes, note_no)
                )
              )
              .reduce((data, num) => data + num, 0),
          Groups[0]
        );
        return finalResult.reduce((num, acc) => Number(num) + acc, 0);
      } else {
        return "--";
      }
    } catch (err) {
      return "!!";
    }
  };

  const adjustmentResult = (
    groupDataCal,
    adjustmentData,
    autoPopulateData,
    group_index,
    scenario,
    project_index,
    showManualOnly,
    pattern_type,
    groupName
  ) => {
    try {
      const { BrowsFsData } = groupDataCal;
      const scenarioResult = subsiaryData.map((sub_data, sub_index) => {
        return {
          scenario: getScenario(currentCompany, subsiaryData, sub_index, FindIr),
          brows_fs: BrowsFsData[sub_index][group_index][project_index],
          adjustment: adjustmentData?.filter(
            (adj) => adj.company_id === sub_data?.all_data?._id
          ),
          autoPopulate: autoPopulateData?.filter(
            (adj) => adj.company_id === sub_data?.all_data?._id
          ),
        };
      });
      const browsDataRes = scenarioResult.filter(
        (data) => data.brows_fs !== undefined
      );
      const browsDataRes_other = scenarioResult.filter((data) => data);
      if (browsDataRes.length === 0) {
        return 0;
      }
      const pre_adjustment = getPreAdjustment(
        scenarioResult,
        browsDataRes,
        browsDataRes_other,
        scenario,
        pattern_type,
        groupName
      );
      const manual_auto_adjustment = manualAutoAdjustment(
        scenarioResult,
        browsDataRes_other,
        scenario
      );
      return pre_adjustment + manual_auto_adjustment;
    } catch (error) {
      return 0;
    }
  };

  const getPreAdjustment = (
    scenarioResult,
    browsDataRes,
    browsDataRes_other,
    scenario,
    pattern_type,
    groupName
  ) => {
    let first_scenario_data = 0;
    let second_scenario_data = 0;
    let third_scenario_data = 0;

    if (preAdjustment.includes(browsDataRes[0].brows_fs.group)) {
      const first_scenario_res = scenarioResult.filter(
        (data) => data.scenario.first_scenario === true
      );
      const first_result = first_scenario_res
        .map((data) => data.brows_fs.cy_amt)
        .reduce((data, num) => data + num, 0);
      first_scenario_data += first_result;

      const second_scenario_res = scenarioResult.filter(
        (data) => data.scenario.second_scenario === true
      );
      const second_result = second_scenario_res
        .map((data) => data.brows_fs.cy_amt)
        .reduce((data, num) => data + num, 0);
      second_scenario_data += second_result;
      first_scenario_data += second_result;

      const third_scenario_res = scenarioResult.filter(
        (data) =>
          data.scenario.first_scenario !== true &&
          data.scenario.second_scenario !== true
      );
      const third_result = third_scenario_res
        .map((data) => data.brows_fs.cy_amt)
        .reduce((data, num) => data + num, 0);
      third_scenario_data += third_result;
      second_scenario_data += third_result;
      first_scenario_data += third_result;
    } else if (
      preAdjustmentOther
        .map((item) => item.sub_group)
        .includes(browsDataRes[0].brows_fs.group)
    ) {
      const cal_data = preAdjustmentOther.find(
        (item) => item.sub_group === browsDataRes[0].brows_fs.group
      ).cal_data;
      const first_scenario_res = scenarioResult.filter(
        (data) => data.scenario.first_scenario === true
      );
      const first_result = first_scenario_res
        .map((data) => Number(data[cal_data]))
        .reduce((data, num) => data + num, 0);
      first_scenario_data += first_result;

      const second_scenario_res = scenarioResult.filter(
        (data) => data.scenario.second_scenario === true
      );
      const second_result = second_scenario_res
        .map((data) => Number(data[cal_data]))
        .reduce((data, num) => data + num, 0);
      second_scenario_data += second_result;
      first_scenario_data += second_result;

      const third_scenario_res = scenarioResult.filter(
        (data) =>
          data.scenario.first_scenario !== true &&
          data.scenario.second_scenario !== true
      );
      const third_result = third_scenario_res
        .map((data) => Number(data[cal_data]))
        .reduce((data, num) => data + num, 0);
      third_scenario_data += third_result;
      second_scenario_data += third_result;
      first_scenario_data += third_result;
    }
    const signChangeGroup = [
      "Equity",
      "Non Current Liabilities",
      "Current Liabilities",
      "Income",
    ];
    switch (scenario) {
      case 1: {
        if (pattern_type === "pattern_2") {
          return first_scenario_data;
        } else {
          if (
            signChangeGroup.includes(groupName) ||
            browsDataRes_other[0].brows_fs.group === "Goodwill" ||
            browsDataRes_other[0].brows_fs.group === "Brands/trademarks" ||
            browsDataRes_other[0].brows_fs.group ===
            "Investments in Equity Instruments - Unquoted"
          ) {
            return first_scenario_data;
          } else {
            return first_scenario_data * -1;
          }
        }
      }
      case 2: {
        if (pattern_type === "pattern_2") {
          return second_scenario_data;
        } else {
          if (
            signChangeGroup.includes(groupName) ||
            browsDataRes_other[0].brows_fs.group === "Goodwill" ||
            browsDataRes_other[0].brows_fs.group === "Brands/trademarks" ||
            browsDataRes_other[0].brows_fs.group ===
            "Investments in Equity Instruments - Unquoted"
          ) {
            return second_scenario_data;
          } else {
            return second_scenario_data * -1;
          }
        }
      }
      case 3: {
        if (pattern_type === "pattern_2") {
          return third_scenario_data;
        } else {
          if (
            signChangeGroup.includes(groupName) ||
            browsDataRes_other[0].brows_fs.group === "Goodwill" ||
            browsDataRes_other[0].brows_fs.group === "Brands/trademarks" ||
            browsDataRes_other[0].brows_fs.group ===
            "Investments in Equity Instruments - Unquoted"
          ) {
            return third_scenario_data;
          } else {
            return third_scenario_data * -1;
          }
        }
      }
      default:
        return 0;
    }
  };

  return (
    <>
      <div class="row">
        <div className="col" style={{ whiteSpace: "nowrap" }}>
          <div style={{ display: "flex" }}>
            <div style={{ marginLeft: "1rem" }}>
              <table
                style={{
                  marginLeft: "10px",
                  display: "inline-block",
                  width: "auto",
                }}
              >
                <CompareFsTableHeader showTotal={false} p_name={"Project 1"} />
                <tbody>
                  <div style={{ marginLeft: "1rem" }}>
                    <p style={heading}>Previous Period</p>
                  </div>
                  {currentProjectSoccie[0]?.previous_row_header.filter((socieItem) => socieItem?.row_header !== "Balance at the beginning")
                    .map(
                      (soccie, pr_index) => (
                        <React.Fragment key={getRandomKey()}>
                          {
                            soccie?.row_header === "Total Comprehensive Income for the previous year" ? (
                              <>
                                <tr>
                                  {
                                    Object.keys(soccie).map((keyname, index) => (
                                      <React.Fragment>
                                        {
                                          !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                            <td
                                              key={index}
                                              style={{
                                                fontWeight: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) ? "bold" : "normal",
                                                backgroundColor: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) && "#c8ffdb",
                                              }}
                                              className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                            >
                                              {
                                                keyname === "row_header" ?
                                                  soccie[keyname] :
                                                  ComprehensiveCalPy(
                                                    { previous_row_header: currentProjectSoccie[0]?.previous_row_header },
                                                    pr_index,
                                                    keyname
                                                  )
                                              }
                                            </td> : ""
                                        }
                                      </React.Fragment>
                                    ))
                                  }
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }} className="text-left pl-2">
                                    On account of acquisition of subsidiary
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </>
                            ) :
                              soccie?.row_header === "Any other change (to be specified)" ? (
                                <>
                                  <tr>
                                    {
                                      Object.keys(soccie).map((keyname, index) => (
                                        <React.Fragment>
                                          {
                                            !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                              <td
                                                key={index}
                                                style={{
                                                  fontWeight: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) ? "bold" : "normal",
                                                  backgroundColor: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) && "#c8ffdb",
                                                }}
                                                className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                              >
                                                {soccie[keyname]}
                                              </td> : ""
                                          }
                                        </React.Fragment>
                                      ))
                                    }
                                  </tr>
                                  <tr>
                                    {
                                      Object.keys(soccie).map((keyname, index) => (
                                        <React.Fragment>
                                          {
                                            !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                              <td
                                                key={index}
                                                style={{
                                                  fontWeight: "bold",
                                                  backgroundColor: "#c8ffdb",
                                                }}
                                                className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                              >
                                                {
                                                  keyname === "row_header" ?
                                                    "Total" :
                                                    TotalCalPy(
                                                      { previous_row_header: currentProjectSoccie[0]?.previous_row_header },
                                                      currentProjectSoccie[0]?.previous_row_header.length - 1,
                                                      keyname
                                                    )
                                                }
                                              </td> : ""
                                          }
                                        </React.Fragment>
                                      ))
                                    }
                                  </tr>
                                  <tr>
                                    {
                                      Object.keys(soccie).map((keyname, index) => (
                                        <React.Fragment>
                                          {
                                            !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                              <td
                                                key={index}
                                                style={{
                                                  fontWeight: "bold",
                                                  backgroundColor: "#feffbb",
                                                }}
                                                className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                              >
                                                {
                                                  keyname === "row_header" ?
                                                    "Balance at the end of the previous reporting period" :
                                                    (
                                                      RestatedBalanceCalPy(
                                                        { previous_row_header: currentProjectSoccie[0]?.previous_row_header },
                                                        2,
                                                        keyname
                                                      ) +
                                                      ComprehensiveCalPy(
                                                        { previous_row_header: currentProjectSoccie[0]?.previous_row_header },
                                                        5,
                                                        keyname
                                                      ) +
                                                      TotalCalPy(
                                                        { previous_row_header: currentProjectSoccie[0]?.previous_row_header },
                                                        currentProjectSoccie[0]?.previous_row_header.length - 1,
                                                        keyname
                                                      )
                                                    )
                                                }
                                              </td> : ""
                                          }
                                        </React.Fragment>
                                      ))
                                    }

                                  </tr>
                                </>
                              )
                                :
                                <tr key={getRandomKey()}>
                                  <React.Fragment>
                                    {
                                      Object.keys(soccie).map((keyname, index) => (
                                        <React.Fragment>
                                          {
                                            !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                              <td
                                                key={index}
                                                style={{
                                                  fontWeight: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) ? "bold" : "normal",
                                                  backgroundColor: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) && "#c8ffdb",
                                                }}
                                                className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                              >
                                                {
                                                  keyname === "row_header" ?
                                                    soccie[keyname] :
                                                    soccie?.row_header === "Restated balance at the beginning of the previous reporting period" ?
                                                      RestatedBalanceCalPy(
                                                        { previous_row_header: currentProjectSoccie[0]?.previous_row_header },
                                                        pr_index,
                                                        keyname
                                                      )
                                                      : soccie[keyname]
                                                }
                                              </td> : ""
                                          }
                                        </React.Fragment>
                                      ))
                                    }
                                  </React.Fragment>
                                </tr>
                          }
                        </React.Fragment>
                      )
                    )}

                  <div style={{ marginLeft: "1rem" }}>
                    <p style={heading}>Current Period</p>
                  </div>
                  {currentProjectSoccie[0]?.current_row_header.map(
                    (soccie, cr_index) => (
                      <React.Fragment key={getRandomKey()}>
                        {
                          soccie?.row_header === "Total Comprehensive Income for the current year" ? (
                            <>
                              <tr>
                                {
                                  Object.keys(soccie).map((keyname, index) => (
                                    <React.Fragment>
                                      {
                                        !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                          <td
                                            key={index}
                                            style={{
                                              fontWeight: ["Total Comprehensive Income for the current year"].includes(soccie?.row_header) ? "bold" : "normal",
                                              backgroundColor: ["Total Comprehensive Income for the current year"].includes(soccie?.row_header) && "#c8ffdb",
                                            }}
                                            className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                          >
                                            {keyname === "row_header" ?
                                              soccie[keyname] :
                                              ComprehensiveCalCy(
                                                { current_row_header: currentProjectSoccie[0]?.current_row_header },
                                                cr_index,
                                                keyname
                                              )
                                            }
                                          </td> : ""
                                      }
                                    </React.Fragment>
                                  ))
                                }
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "bold" }} className="text-left pl-2">
                                  On account of acquisition of subsidiary
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </>
                          ) :
                            soccie?.row_header === "Any other change (to be specified)" ? (
                              <>
                                <tr>
                                  {
                                    Object.keys(soccie).map((keyname, index) => (
                                      <React.Fragment>
                                        {
                                          !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                            <td
                                              key={index}
                                              style={{
                                                fontWeight: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) ? "bold" : "normal",
                                                backgroundColor: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) && "#c8ffdb",
                                              }}
                                              className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                            >
                                              {soccie[keyname]}
                                            </td> : ""
                                        }
                                      </React.Fragment>
                                    ))
                                  }
                                </tr>
                                <tr>
                                  {
                                    Object.keys(soccie).map((keyname, index) => (
                                      <React.Fragment>
                                        {
                                          !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                            <td
                                              key={index}
                                              style={{
                                                fontWeight: "bold",
                                                backgroundColor: "#c8ffdb",
                                              }}
                                              className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                            >
                                              {
                                                keyname === "row_header" ? "Total" :
                                                  TotalCalCy(
                                                    { current_row_header: currentProjectSoccie[0]?.current_row_header },
                                                    currentProjectSoccie[0]?.current_row_header.length - 1,
                                                    keyname
                                                  )
                                              }
                                            </td> : ""
                                        }
                                      </React.Fragment>
                                    ))
                                  }
                                </tr>
                                <tr>
                                  {
                                    Object.keys(soccie).map((keyname, index) => (
                                      <React.Fragment>
                                        {
                                          !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                            <td
                                              key={index}
                                              style={{
                                                fontWeight: "bold",
                                                backgroundColor: "#c8ffdb",
                                              }}
                                              className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                            >
                                              {
                                                keyname === "row_header" ? "Balance at the end of the current reporting period" :
                                                  (
                                                    RestatedBalanceCalCy(
                                                      { current_row_header: currentProjectSoccie[0]?.current_row_header },
                                                      2,
                                                      keyname
                                                    ) +
                                                    ComprehensiveCalCy(
                                                      { current_row_header: currentProjectSoccie[0]?.current_row_header },
                                                      5,
                                                      keyname
                                                    ) +
                                                    TotalCalCy(
                                                      { current_row_header: currentProjectSoccie[0]?.current_row_header },
                                                      currentProjectSoccie[0]?.current_row_header.length - 1,
                                                      keyname
                                                    )
                                                  )
                                              }
                                            </td> : ""
                                        }
                                      </React.Fragment>
                                    ))
                                  }
                                </tr>
                              </>
                            ) :
                              <tr key={getRandomKey()}>
                                {
                                  Object.keys(soccie).map((keyname, index) => (
                                    <React.Fragment>
                                      {
                                        !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                          <td
                                            key={index}
                                            style={{
                                              fontWeight: ["Restated balance at the beginning of the current reporting period", "Total Comprehensive Income for the current year"].includes(soccie?.row_header) ? "bold" : "normal",
                                              backgroundColor: ["Restated balance at the beginning of the current reporting period", "Total Comprehensive Income for the current year"].includes(soccie?.row_header) && "#c8ffdb",
                                            }}
                                            className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                          >
                                            {
                                              keyname === "row_header" ?
                                                soccie[keyname] :
                                                soccie?.row_header === "Restated balance at the beginning of the current reporting period" ?
                                                  RestatedBalanceCalCy(
                                                    { current_row_header: currentProjectSoccie[0]?.current_row_header },
                                                    cr_index,
                                                    keyname
                                                  )
                                                  : soccie[keyname]
                                            }
                                          </td> : ""
                                      }
                                    </React.Fragment>
                                  ))
                                }
                              </tr>
                        }
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>
            </div>


            <div style={{ marginLeft: "1rem" }}>
              <table
                style={{
                  marginLeft: "10px",
                  display: "inline-block",
                  width: "auto",
                }}
              >
                <CompareFsTableHeader showTotal={false} p_name={"Project 2"} />
                <tbody>
                  <div style={{ marginLeft: "1rem" }}>
                    <p style={heading}>Previous Period</p>
                  </div>
                  {soccieTotalData[0]?.previous_row_header.filter((socieItem) => socieItem?.row_header !== "Balance at the beginning")
                    .map(
                      (soccie, pr_index) => (
                        <React.Fragment key={getRandomKey()}>
                          {
                            soccie?.row_header === "Total Comprehensive Income for the previous year" ? (
                              <>
                                <tr>
                                  {
                                    Object.keys(soccie).map((keyname, index) => (
                                      <React.Fragment>
                                        {
                                          !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                            <td
                                              key={index}
                                              style={{
                                                fontWeight: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) ? "bold" : "normal",
                                                backgroundColor: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) && "#c8ffdb",
                                              }}
                                              className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                            >
                                              {
                                                keyname === "row_header" ?
                                                  soccie[keyname] :
                                                  ComprehensiveCalPy(
                                                    { previous_row_header: soccieTotalData[0]?.previous_row_header },
                                                    pr_index,
                                                    keyname
                                                  )
                                              }
                                            </td> : ""
                                        }
                                      </React.Fragment>
                                    ))
                                  }
                                </tr>
                                <tr>
                                  <td style={{ fontWeight: "bold" }} className="text-left pl-2">
                                    On account of acquisition of subsidiary
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </>
                            ) :
                              soccie?.row_header === "Any other change (to be specified)" ? (
                                <>
                                  <tr>
                                    {
                                      Object.keys(soccie).map((keyname, index) => (
                                        <React.Fragment>
                                          {
                                            !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                              <td
                                                key={index}
                                                style={{
                                                  fontWeight: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) ? "bold" : "normal",
                                                  backgroundColor: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) && "#c8ffdb",
                                                }}
                                                className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                              >
                                                {soccie[keyname]}
                                              </td> : ""
                                          }
                                        </React.Fragment>
                                      ))
                                    }
                                  </tr>
                                  <tr>
                                    {
                                      Object.keys(soccie).map((keyname, index) => (
                                        <React.Fragment>
                                          {
                                            !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                              <td
                                                key={index}
                                                style={{
                                                  fontWeight: "bold",
                                                  backgroundColor: "#c8ffdb",
                                                }}
                                                className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                              >
                                                {
                                                  keyname === "row_header" ?
                                                    "Total" :
                                                    TotalCalPy(
                                                      { previous_row_header: soccieTotalData[0]?.previous_row_header },
                                                      soccieTotalData[0]?.previous_row_header.length - 1,
                                                      keyname
                                                    )
                                                }
                                              </td> : ""
                                          }
                                        </React.Fragment>
                                      ))
                                    }
                                  </tr>
                                  <tr>
                                    {
                                      Object.keys(soccie).map((keyname, index) => (
                                        <React.Fragment>
                                          {
                                            !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                              <td
                                                key={index}
                                                style={{
                                                  fontWeight: "bold",
                                                  backgroundColor: "#feffbb",
                                                }}
                                                className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                              >
                                                {
                                                  keyname === "row_header" ?
                                                    "Balance at the end of the previous reporting period" :
                                                    (
                                                      RestatedBalanceCalPy(
                                                        { previous_row_header: soccieTotalData[0]?.previous_row_header },
                                                        2,
                                                        keyname
                                                      ) +
                                                      ComprehensiveCalPy(
                                                        { previous_row_header: soccieTotalData[0]?.previous_row_header },
                                                        5,
                                                        keyname
                                                      ) +
                                                      TotalCalPy(
                                                        { previous_row_header: soccieTotalData[0]?.previous_row_header },
                                                        soccieTotalData[0]?.previous_row_header.length - 1,
                                                        keyname
                                                      )
                                                    )
                                                }
                                              </td> : ""
                                          }
                                        </React.Fragment>
                                      ))
                                    }

                                  </tr>
                                </>
                              )
                                :
                                <tr key={getRandomKey()}>
                                  <React.Fragment>
                                    {
                                      Object.keys(soccie).map((keyname, index) => (
                                        <React.Fragment>
                                          {
                                            !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                              <td
                                                key={index}
                                                style={{
                                                  fontWeight: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) ? "bold" : "normal",
                                                  backgroundColor: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) && "#c8ffdb",
                                                }}
                                                className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                              >
                                                {
                                                  keyname === "row_header" ?
                                                    soccie[keyname] :
                                                    soccie?.row_header === "Restated balance at the beginning of the previous reporting period" ?
                                                      RestatedBalanceCalPy(
                                                        { previous_row_header: soccieTotalData[0]?.previous_row_header },
                                                        pr_index,
                                                        keyname
                                                      )
                                                      : soccie[keyname]
                                                }
                                              </td> : ""
                                          }
                                        </React.Fragment>
                                      ))
                                    }
                                  </React.Fragment>
                                </tr>
                          }
                        </React.Fragment>
                      )
                    )}

                  <div style={{ marginLeft: "1rem" }}>
                    <p style={heading}>Current Period</p>
                  </div>
                  {soccieTotalData[0]?.current_row_header.map(
                    (soccie, cr_index) => (
                      <React.Fragment key={getRandomKey()}>
                        {
                          soccie?.row_header === "Total Comprehensive Income for the current year" ? (
                            <>
                              <tr>
                                {
                                  Object.keys(soccie).map((keyname, index) => (
                                    <React.Fragment>
                                      {
                                        !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                          <td
                                            key={index}
                                            style={{
                                              fontWeight: ["Total Comprehensive Income for the current year"].includes(soccie?.row_header) ? "bold" : "normal",
                                              backgroundColor: ["Total Comprehensive Income for the current year"].includes(soccie?.row_header) && "#c8ffdb",
                                            }}
                                            className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                          >
                                            {keyname === "row_header" ?
                                              soccie[keyname] :
                                              ComprehensiveCalCy(
                                                { current_row_header: soccieTotalData[0]?.current_row_header },
                                                cr_index,
                                                keyname
                                              )
                                            }
                                          </td> : ""
                                      }
                                    </React.Fragment>
                                  ))
                                }
                              </tr>
                              <tr>
                                <td style={{ fontWeight: "bold" }} className="text-left pl-2">
                                  On account of acquisition of subsidiary
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </>
                          ) :
                            soccie?.row_header === "Any other change (to be specified)" ? (
                              <>
                                <tr>
                                  {
                                    Object.keys(soccie).map((keyname, index) => (
                                      <React.Fragment>
                                        {
                                          !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                            <td
                                              key={index}
                                              style={{
                                                fontWeight: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) ? "bold" : "normal",
                                                backgroundColor: ["Restated balance at the beginning of the previous reporting period", "Total Comprehensive Income for the previous year"].includes(soccie?.row_header) && "#c8ffdb",
                                              }}
                                              className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                            >
                                              {soccie[keyname]}
                                            </td> : ""
                                        }
                                      </React.Fragment>
                                    ))
                                  }
                                </tr>
                                <tr>
                                  {
                                    Object.keys(soccie).map((keyname, index) => (
                                      <React.Fragment>
                                        {
                                          !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                            <td
                                              key={index}
                                              style={{
                                                fontWeight: "bold",
                                                backgroundColor: "#c8ffdb",
                                              }}
                                              className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                            >
                                              {
                                                keyname === "row_header" ? "Total" :
                                                  TotalCalCy(
                                                    { current_row_header: soccieTotalData[0]?.current_row_header },
                                                    soccieTotalData[0]?.current_row_header.length - 1,
                                                    keyname
                                                  )
                                              }
                                            </td> : ""
                                        }
                                      </React.Fragment>
                                    ))
                                  }
                                </tr>
                                <tr>
                                  {
                                    Object.keys(soccie).map((keyname, index) => (
                                      <React.Fragment>
                                        {
                                          !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                            <td
                                              key={index}
                                              style={{
                                                fontWeight: "bold",
                                                backgroundColor: "#c8ffdb",
                                              }}
                                              className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                            >
                                              {
                                                keyname === "row_header" ? "Balance at the end of the current reporting period" :
                                                  (
                                                    RestatedBalanceCalCy(
                                                      { current_row_header: soccieTotalData[0]?.current_row_header },
                                                      2,
                                                      keyname
                                                    ) +
                                                    ComprehensiveCalCy(
                                                      { current_row_header: soccieTotalData[0]?.current_row_header },
                                                      5,
                                                      keyname
                                                    ) +
                                                    TotalCalCy(
                                                      { current_row_header: soccieTotalData[0]?.current_row_header },
                                                      soccieTotalData[0]?.current_row_header.length - 1,
                                                      keyname
                                                    )
                                                  )
                                              }
                                            </td> : ""
                                        }
                                      </React.Fragment>
                                    ))
                                  }
                                </tr>
                              </>
                            ) :
                              <tr key={getRandomKey()}>
                                {
                                  Object.keys(soccie).map((keyname, index) => (
                                    <React.Fragment>
                                      {
                                        !["refData", "sub_grp", "isHeading"].includes(keyname) ?
                                          <td
                                            key={index}
                                            style={{
                                              fontWeight: ["Restated balance at the beginning of the current reporting period", "Total Comprehensive Income for the current year"].includes(soccie?.row_header) ? "bold" : "normal",
                                              backgroundColor: ["Restated balance at the beginning of the current reporting period", "Total Comprehensive Income for the current year"].includes(soccie?.row_header) && "#c8ffdb",
                                            }}
                                            className={`${keyname === "row_header" ? "text-left pl-2" : "text-right pr-2"}`}
                                          >
                                            {
                                              keyname === "row_header" ?
                                                soccie[keyname] :
                                                soccie?.row_header === "Restated balance at the beginning of the current reporting period" ?
                                                  RestatedBalanceCalCy(
                                                    { current_row_header: soccieTotalData[0]?.current_row_header },
                                                    cr_index,
                                                    keyname
                                                  )
                                                  : soccie[keyname]
                                            }
                                          </td> : ""
                                      }
                                    </React.Fragment>
                                  ))
                                }
                              </tr>
                        }
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompareFsSocie;

const CompareFsTableHeader = ({ showTotal, p_name }) => {
  const particlarTab = {
    width: "500px",
    height: "50px",
    textAlign: "center",
    maxWidth: "500px",
    wordWrap: "break-word",
    whiteSpace: "normal",
  }

  const thBgColor = { backgroundColor: "#A2C0C2" }
  const thBgColor2 = { backgroundColor: "#FFFF00" }
  return (
    <thead>
      <tr>
        <th>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {p_name}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th>
        {/* <th style={thBgColor2}>
        </th> */}
        {showTotal ? <th style={thBgColor2}>
          {/* OTHER EQUITY */}
        </th> : ""}
      </tr>
      <tr style={thBgColor}>
        <th
          style={particlarTab}
          rowSpan={2}
        >
          {/* OTHER EQUITY */}
        </th>
        <th style={particlarTab} rowSpan={2}>
          Share application money pending allotment
        </th>
        <th style={particlarTab} rowSpan={2}>
          Equity component of compounded financial instruments
        </th>
        <th style={particlarTab} colSpan={4}>
          Reserves and surplus
        </th>
        <th style={particlarTab} rowSpan={2}>
          Debt instruments through Other Comprehensive Income
        </th>
        <th style={particlarTab} rowSpan={2}>
          Equity Instruments through Other Comprehensive Income
        </th>
        <th style={particlarTab} rowSpan={2}>
          Effective portion of Cash Flow Hedges
        </th>
        <th style={particlarTab} rowSpan={2}>
          Revaluation Surplus
        </th>
        <th style={particlarTab} rowSpan={2}>
          Exchange differences on translating the financial statements of a
          foreign operation
        </th>
        <th
          style={particlarTab}
          rowSpan={2}
        >
          Money received against share warrants
        </th>
        {/* <th style={particlarTab} rowSpan={2}>Non-controlling interest</th> */}
        {showTotal ? <th style={particlarTab} rowSpan={2}> Total </th> : ""}

      </tr>
      <tr style={thBgColor}>
        <th style={{}}>Capital Reserve</th>
        <th style={{}}>Securities Premium</th>
        <th style={{}}>Other Reserves (specify nature)</th>
        <th style={{}}>Retained earnings</th>
      </tr>
    </thead>
  )
}