
import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Navbar } from "../../../Components/Navbar";
import {
  BrowsFs,
  CreateNewIRDetails,
  getCompany,
  GetCompanyDataIR,
  GetConsoleEntryData,
  GetProjectsBasedOnUserId,
  addAllOtherOptions,
  getAllOtherOptions,
  getAllOtherDetails,
  addOtherDetails,
  deleteAllOtherDetails,
  addAllOtherDetails,
  saveAllIrDetails
} from "../ApiServices/apiHandler";
import "../../../WorkSpace/font.css";
import swal from "sweetalert";
import { useHistory, useParams } from "react-router";
import BrowsFsModal from "./Modals/BrowsFSModal";
import { toast } from "react-toastify";
import ConsoleEntryModal from "./Modals/ConsoleEntryModal";
import AddOtherDetailsModal from "./Modals/AddOtherDetailsModal";
import AddNewOptionsModal from "./Modals/AddNewOptionsModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllDetailsData } from "../../../redux/actions/InvestmentRegister/actionAllOthersData";

const companyRelationShip = ["Subsidiary Company", "Associate Company", "Investment"]

const InsideIR = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const user_id = localStorage.getItem("user_id");

  const [allCompany, setAllCompany] = useState([]);
  const [allRegisterData, setAllRegisterData] = useState([]);


  const [details, setDetails] = useState({
    companyCode: "",
    browseFsCompanyId: "",
    details_id: ""
  })
  const [singleIrData, setSingleIrData] = useState({});
  const [isBrowsModalOpen, setIsBrowsModalOpen] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [isConsoleEntryOpen, setIsConsoleEntryOpen] = useState(false);
  const [allConsoleEntry, setAllConsoleEntry] = useState([]);
  const [isOtherInputOpen, setIsOtherInputOpen] = useState(false);
  const [allOtherDetailsInputs, setOtherDetailsInputs] = useState([]);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [otherOptions, setOtherOptions] = useState([]);
  const [options, setOptions] = useState('');
  const [consoleInvestmentValue,setConsoleInvestmentValue]=useState(0);
  const [allDetailsId,setAllDetailsId]=useState('');
  const [showFirstTable, setShowFirstTable] = useState(true);
  const [projects,setProjects]=useState([]);

  useEffect(() => {
    GetCompany();
    AllRegisterData();
    GetProjects();
  }, []);


  const all_othersData = useSelector((state) => state.AllOtherDetailsReducer);

  function handleChangeDetails(e, field, i) {
    const { value } = e.target;
    if (value === "Add_new") {
      setIsOptionsModalOpen(true);
    } else {
      const newArray = allOtherDetailsInputs.map((item, index) => {
        if (i === index) {
          return { ...item, [field]: value };
        }
        return item;
      });
      setOtherDetailsInputs(newArray);
    }
  }

  const GetCompany = async () => {
    const res = await getCompany();
    if (res.status === 200) {
      setAllCompany(res.data.getCompany);
    }
  };


  // TODO :- New APIs 
  const handleSubmitFs = () => {
    const data = {
      project_id: details.browseFsCompanyId,
      details_id: details.details_id
    }

    BrowsFs(data).then(response => {
      toast.success("Brows FS mapped successfully.");
      setIsBrowsModalOpen(false);
      GetProjects();
      setAllRegisterData((prev) =>
        prev.map((item) => {
          if (item._id === response.data?.result._id) {
            return { ...item, prepare_preset_project_id: response.data?.result.prepare_preset_project_id };
          }
          return item;
        })
      );
    }).catch(error => {
      console.log("browsfs error", error)
    })
  };

  const openNewIr = () => {
    const data = {
      parent_company_id: "",
      ir_id: id,
      investment_company_id: "",
      investment_company_code: "",
      date_Of_investment: "",
      value_of_investment: "",
      no_of_shares: "",
      total_no_shares: "",
      holdings: "",
      non_controlling_interest: "",
      nature_of_relationship: "",
    };
    CreateNewIRDetails(data).then(response => {
      console.log("response.data.result", response.data.result)
      setAllRegisterData(prev => ([...prev, response.data.result]))
    }).catch(error => {
      console.log("error", error)
    })
  };

  const AllRegisterData = async () => {
    GetCompanyDataIR(id).then(response => {
      setAllRegisterData(response.data.updatedOtherData);
      setSingleIrData(response.data.investmentRegister);
      // dispatch(fetchAllDetailsData(response.data.updatedOtherData[0]._id));
    }).catch(error => {
      console.log("error", error)
    })
  };

  const GetAllProjects = async () => {
    GetProjectsBasedOnUserId(user_id).then(response => {
      setIsBrowsModalOpen(true);
      setAllProjects(response.data?.result)
    }).catch(error => {
      console.log("error", error)
    })
  };

  const GetAllConsoleEntry = async (ir) => {
    const data = {
      non_controlling_interest:ir.non_controlling_interest,
      investment_value: -ir.value_of_investment,
    };

    GetConsoleEntryData(ir._id, data)
      .then((response) => {
        const result = response.data?.result;
        setIsConsoleEntryOpen(true);
        setAllConsoleEntry(result?.console_entry);

        setAllRegisterData((prev) =>
          prev.map((item) => {
            if (item._id === result._id) {
              return { ...item, console_entry: result?.console_entry };
            }
            return item;
          })
        );
      })
      .catch((error) => {
        swal(
          "Error",
          error?.response?.data?.error || "something went wrong",
          "error"
        );
      });
  };


  const HandleChange = (e, index) => {
    const { name, value } = e.target;
    setAllRegisterData(prev =>
      prev.map((item, idx) => {
        if (idx !== index) return item;

        const updatedItem = { ...item, [name]: value };

        if (name === "investment_company_id") {
          const resultCompany = allCompany.find(
            (det) => det?._id === value
          );
          updatedItem.investment_company_code = resultCompany?.company_code ?? "";
        }

        if (name === "no_of_shares" || name === "total_no_shares") {
          const noOfShares = name === "no_of_shares"
            ? parseFloat(value)
            : parseFloat(item.no_of_shares);
          const totalNoShares = name === "total_no_shares"
            ? parseFloat(value)
            : parseFloat(item.total_no_shares);

          if (!isNaN(noOfShares) && !isNaN(totalNoShares) && totalNoShares !== 0) {
            const holdings = (noOfShares / totalNoShares) * 100;
            updatedItem.holdings = holdings;
            updatedItem.non_controlling_interest = 100 - holdings;
            if (holdings >= 51) {
              updatedItem.nature_of_relationship = "Subsidiary Company";
            } else if (holdings >= 20 && holdings < 51) {
              updatedItem.nature_of_relationship = "Associate Company";
            } else {
              updatedItem.nature_of_relationship = "Investment";
            }
          } else {
            updatedItem.holdings = 0;
            updatedItem.non_controlling_interest = 0;
            updatedItem.nature_of_relationship = "Investment";
          }
        }

        return updatedItem;
      })
    );
  };

  // add Others Details new Options
  const addNewOptionsDetails = async () => {
    const data = {
      otherDatailsId: allDetailsId,
      options: options
    }
    const res = await addAllOtherOptions(data);
    if (res.status === 201) {
      setIsOptionsModalOpen(false);
      setAllRegisterData((prev) =>
        prev.map((item) => {
          if (item._id === res.data.data._id) {
            return { ...item, other_details_options: res.data.data.other_details_options };
          }
          return item;
        })
      );
      getOthersDetailsOptions();
    }
  };
  //get other Details options
  const getOthersDetailsOptions = async () => {
    const res = await getAllOtherOptions(allDetailsId);
    if (res.status === 201) {
      setOtherOptions(res.data.options);
    }
  };
  //get all others Details
  const getOthersDetails = async () => {
    const res = await getAllOtherDetails(allDetailsId);
    if (res.status === 201) {
      setIsOtherInputOpen(true)
      setOtherDetailsInputs(res.data.other_items);
      getOthersDetailsOptions();
    }
  };
  // add  new row in others details
  const addNewOthersDetails = async () => {
    const data = {
      otherDatailsId: allDetailsId,
      other_items: allOtherDetailsInputs
    }
    const res = await addOtherDetails(data);
    if (res.status === 201) {
      getOthersDetails();
    }
  };
  //delete others details 
  const deleteOthersDetails = async (index) => {
    const data = {
      otherDatailsId: allDetailsId,
      index: index
    }
    const res = await deleteAllOtherDetails(data);
    if (res.status === 201) {
      getOthersDetails();
    }
  };

  // add all others details data
  const addAllOthersDetails = async () => {
    const data = {
      otherDatailsId: allDetailsId,
      other_items: allOtherDetailsInputs
    }
    const res = await addAllOtherDetails(data);
    if (res.status === 201) {
      setIsOtherInputOpen(false);
      setAllRegisterData((prev) =>
        prev.map((item) => {
          if (item._id === res.data.data._id) {
            return { ...item, other_items: res.data.data.other_items };
          }
          return item;
        })
      );
      // dispatch(fetchAllDetailsData(allDetailsId));
    }
  };

  const saveAllIrsDetails = async () => {
    const data = {
      irId: id,
      allIrData: allRegisterData
    }
    const res = await saveAllIrDetails(data);
    if (res.status === 200) {
      AllRegisterData();
      history.push(`/detailsIR/${id}`);
      swal("Success", "All IR Data Save Successfully", 'success');
    }
  };

  const ProjectName = (p_id) => {
    const ProjectData = projects.find((adj) => adj._id === p_id);
    return ProjectData?.project_name || '';
  };

  const handleChangeInputs=(e)=>{
    setConsoleInvestmentValue(e.target.value);
  }

  const handleNext = (ir) => {
    const data = {
      non_controlling_interest:ir.non_controlling_interest,
      investment_value:-(Number(ir.value_of_investment) + Number(consoleInvestmentValue)),
    };

    GetConsoleEntryData(ir._id, data)
      .then((response) => {
        const result = response.data?.result;
        setShowFirstTable(false);
        setAllConsoleEntry(result?.console_entry);

        setAllRegisterData((prev) =>
          prev.map((item) => {
            if (item._id === result._id) {
              return { ...item, console_entry: result?.console_entry };
            }
            return item;
          })
        );
      })
      .catch((error) => {
        swal(
          "Error",
          error?.response?.data?.error || "something went wrong",
          "error"
        );
      });
  };

  const GetProjects = async () => {
    GetProjectsBasedOnUserId(user_id).then(response => {
      setProjects(response.data?.result)
    }).catch(error => {
      console.log("error", error)
    })
  };


  return (
    <>
      <Navbar />

      <div className="pb-2">
        <div className="container mt-4">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <button
              onClick={() => history.push(`/detailsIR/${id}`)}
              className="border-0 d-flex bg-color-inherit custom-common-theme-text-color"
            >
              <ArrowBackIcon />
              &nbsp;
              <div>Go Back</div>
            </button>
            <div className="d-flex" >
              <button
                onClick={openNewIr}
                className="px-2 bg-white py-1 shadow rounded custom-border custom-common-theme-text-color"
              >
                <AddIcon />
                Create Investment
              </button>
              <button
                onClick={saveAllIrsDetails}
                className="px-2 ml-2 text-white shadow py-1 rounded custom-border custom-common-theme-bg-color"
              >
                Save Details
              </button>
            </div>
          </div>

          <div className="d-flex">
            <p className="fs-inter">
              HOME / INVESTMENT / REGISTERS /&nbsp;
              <span className="custom-font-600" >
                CREATE REGISTER
              </span>
            </p>
          </div>
          <h5 className="font-size-24 custom-font-600">Create Investment Register</h5>
          <div className="mt-4 d-flex align-items-center">
            <div className="d-flex flex-column">
              <label className="dm-sans iw-label">Name of investment register </label>
              <input
                className="rounded mt-0"
                type="text"
                name="InvestRegister"
                value={singleIrData?.ir_name || ""}
                disabled
              />
            </div>
            <div className="d-flex flex-column ml-2">
              <label className="dm-sans custom-font-700 font-size-14 text-secondary">Parent company</label>
              <select
                style={{
                  minWidth: "350px",
                  minHeight: "54px",
                }}
                className="rounded"
                name="ParentCompany"
                disabled
              >
                <option>Select</option>
                {allCompany.length > 0 &&
                  allCompany.map((det, index) =>
                  (
                    <option selected={singleIrData?.parent_company_id === det._id} key={index} value={det._id}>{det.company_name}</option>
                  )
                  )}
              </select>
            </div>
          </div>

          <hr style={{ width: "731px" }} />

          {allRegisterData.length > 0 &&
            allRegisterData.map((investdata, ix) =>
            (
              <>
                <div className="row mb-2">
                  <div className="col-md-8">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="font-weight-bold">
                          Investment
                        </div>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Typography>
                          <div className="row">
                            <div className="col-md-6">
                              <label className="dm-sans custom-font-700 font-size-14 text-secondary">Investee company name </label>
                              <select
                                style={{
                                  width: "336px",
                                  height: "55px",
                                }}
                                className="rounded"
                                name="investment_company_id"
                                onChange={(e) => HandleChange(e, ix)}
                              >
                                <option>Select...</option>
                                {allCompany.length > 0 &&
                                  allCompany.map((det) => (
                                    <option
                                      value={det._id}
                                      selected={
                                        investdata?.investment_company_id === det._id
                                      }
                                    >
                                      {det.company_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-md-6">
                              <label className="dm-sans custom-font-700 font-size-14 text-secondary">Company code</label>
                              <input
                                type="text"
                                className="rounded mt-0"
                                value={investdata.investment_company_code}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-4">
                              <label className="dm-sans custom-font-700 font-size-14 text-secondary">Date of investment</label>
                              <input
                                onChange={(e) => HandleChange(e, ix)}
                                name="date_Of_investment"
                                type="date"
                                className="form-control p-0"
                                value={investdata.date_Of_investment}
                              />
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-12">
                              <label className="dm-sans custom-font-700 font-size-14 text-secondary">Value of investment </label>
                              <input
                                type="text"
                                name="value_of_investment"
                                className="rounded"
                                onChange={(e) => HandleChange(e, ix)}
                                value={investdata.value_of_investment}
                              />
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-6">
                              <label className="dm-sans custom-font-700 font-size-14 text-secondary">No of shares bought</label>
                              <input
                                type="text"
                                className="rounded"
                                name="no_of_shares"
                                onChange={(e) => HandleChange(e, ix)}
                                defaultValue={investdata.no_of_shares}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="dm-sans custom-font-700 font-size-14 text-secondary">Total no shares in the company</label>
                              <input
                                type="text"
                                className="rounded"
                                name="total_no_shares"
                                onChange={(e) => HandleChange(e, ix)}
                                defaultValue={investdata.total_no_shares}
                              />
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-6">
                              <label className="dm-sans custom-font-700 font-size-14 text-secondary">Percentage holding
                              </label>
                              <input
                                type="text"
                                placeholder="%"
                                className="rounded"
                                name="holdings"
                                value={investdata.holdings.toLocaleString("en-IN")}
                                disabled
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="dm-sans custom-font-700 font-size-14 text-secondary">Non-controlling interest</label>
                              <input
                                type="text"
                                className="rounded"
                                placeholder="Add non-controlling interest"
                                name="non_controlling_interest"
                                value={investdata.non_controlling_interest.toLocaleString("en-IN")}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-12">
                              <label className="dm-sans custom-font-700 font-size-14 text-secondary">Nature of relationship </label>
                              <select
                                style={{
                                  height: "59px",
                                }}
                                className="rounded w-100"
                                name="nature_of_relationship"
                                disabled={false}
                                value={investdata.nature_of_relationship}
                                onChange={(e) => HandleChange(e, ix)}
                              >
                                <option>Select</option>
                                {
                                  companyRelationShip.map(nr => (
                                    <option
                                      value={nr}
                                      selected={investdata.nature_of_relationship === nr}
                                    >
                                      {nr}
                                    </option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>

                          {
                            investdata.nature_of_relationship==="Subsidiary Company" && (
                              <div className="row mt-3">
                                <div
                                  className="col-md-6"
                                >
                                  <button
                                    onClick={() => {
                                      GetAllConsoleEntry(investdata)
                                    }}
                                    className="px-2 text-white shadow py-2 w-100 rounded custom-border custom-common-theme-bg-color"
                                  >
                                    Create consol entry
                                  </button>
                                </div>

                                <div className="col-md-6">
                                  <button
                                    onClick={() => {
                                      setDetails(prev => ({ ...prev, details_id: investdata._id }))
                                      GetAllProjects();
                                    }}
                                    className="px-2 text-white shadow py-2 w-100 rounded custom-border custom-common-theme-bg-color"
                                  >
                                    Browse control date FS
                                  </button>
                                  <span> {ProjectName(investdata?.prepare_preset_project_id)}</span>
                                </div>
                              </div>
                            )
                          }

                          {investdata.nature_of_relationship==="Subsidiary Company" && (
                            <div className="row  mt-3">
                              <div className="col-md-12 ">
                                <label className="dm-sans custom-font-700 font-size-14 text-secondary mb-0">Details of net-worth on the date of control </label>
                                <input
                                  type="text"
                                  className="rounded"
                                />
                              </div>

                              <div className="col-md-12 mt-1">
                                <button
                                  onClick={() => {
                                    setAllDetailsId(investdata._id);
                                    getOthersDetails();
                                  }}
                                  className="border-0 bg-color-inherit mt-0 text-secondary d-flex justify-content-center align-items-center"
                                >
                                  Add other details
                                  <AddIcon />
                                </button>
                              </div>
                            </div>
                          )}

                          <div className="row mt-2">
                            <div className="col-md-12">
                              <label className="dm-sans custom-font-700 font-size-14 text-secondary mb-0">
                                Whether transaction is covered as common control transaction
                              </label>
                              <select
                                className="rounded"
                                style={{
                                  width: "691px",
                                  height: "59px",
                                }}
                              >
                                <option>Select....</option>
                                <option>Yes</option>
                                <option>No</option>
                              </select>
                            </div>
                            <div className="col-md-12 mt-3">
                              {investdata.nature_of_relationship==="Subsidiary Company" && (
                                <div className="table-responsive table-bordered">
                                  <table className="table" >
                                    <tbody>
                                    {/* all_othersData?.response */}
                                      {investdata.other_items && investdata.other_items.length > 0 &&
                                        investdata.other_items.map((item, v) => (
                                          <tr key={v}>
                                            <td>{item.select}</td>
                                            <td>{item.value}</td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </>
            )
            )}
        </div>
      </div>

      <BrowsFsModal
        setIsBrowsModalOpen={setIsBrowsModalOpen}
        isBrowsModalOpen={isBrowsModalOpen}
        allProjects={allProjects}
        details={details}
        setDetails={setDetails}
        handleSubmitFs={handleSubmitFs}
      />

      <ConsoleEntryModal
        isConsoleEntryOpen={isConsoleEntryOpen}
        setIsConsoleEntryOpen={setIsConsoleEntryOpen}
        allConsoleEntry={allConsoleEntry}
        allRegisterData={allRegisterData}
        handleChangeInputs={handleChangeInputs}
        consoleInvestmentValue={consoleInvestmentValue}
        handleNext={handleNext}
        showFirstTable={showFirstTable}
        setShowFirstTable={setShowFirstTable}
        additinalData={null}
      />

      <AddOtherDetailsModal
        setIsOtherInputOpen={setIsOtherInputOpen}
        isOtherInputOpen={isOtherInputOpen}
        allOtherDetailsInputs={allOtherDetailsInputs}
        allotherdata={otherOptions}
        handleChangeDetails={handleChangeDetails}
        handleNew={addNewOthersDetails}
        handleDelete={deleteOthersDetails}
        handleSave={addAllOthersDetails}
      />

      <AddNewOptionsModal
        isOptionsModalOpen={isOptionsModalOpen}
        setIsOptionsModalOpen={setIsOptionsModalOpen}
        setOptions={setOptions}
        saveData={addNewOptionsDetails}
      />
    </>
  );
};

export default InsideIR;
