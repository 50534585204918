import React from 'react'
// import "./cashFlow.css"
import moment from 'moment'
import { formatIndianCurrency } from '../../helper/ConvertValue';
import { Checkbox } from '@mui/material';

const CashFlowTable = ({ workspaceDate, data }) => {

    const calculateTotalByIndex = (data, index_1, index_2) => {
        const slicedArray = [...data].slice(index_1, index_2);
        const sumCy = slicedArray.reduce((acc, ele) => acc + (typeof ele.cy === 'number' ? ele.cy : 0), 0);
        const sumPy = slicedArray.reduce((acc, ele) => acc + (typeof ele.py === 'number' ? ele.py : 0), 0);
        return { sumCy, sumPy };
    };

    const calculateCashGeneratedOperatingActivities = () => {
        const workingCaptial = calculateTotalByIndex(data, 0, 17);
        const CashGeneratedOperation = calculateTotalByIndex(data, 17, 27);

        return { cy : (workingCaptial?.sumCy + CashGeneratedOperation?.sumCy), py: (workingCaptial?.sumPy + CashGeneratedOperation?.sumPy)}
    }

    const netCashGeneratedA = () => {
        const cashGeneratedOperatingActivities = calculateCashGeneratedOperatingActivities();
        const income_tax_paid_received = data.find(ele => ele.particular === "Income tax (paid) / refund received")
        return { 
            cy : (cashGeneratedOperatingActivities?.cy + income_tax_paid_received?.cy), 
            py: (cashGeneratedOperatingActivities?.py + income_tax_paid_received?.py)
        }
    }

    const netCashGeneratedABC = () => {
        return { 
            cy : (netCashGeneratedA()?.cy + calculateTotalByIndex(data, 28,55)?.sumCy), 
            py: (netCashGeneratedA()?.py + calculateTotalByIndex(data, 28,55)?.sumPy)
        }
    }

    const CashEquivalentsEndYear = () => {
        const income_tax_paid_received = data.find(ele => ele.particular === "Cash and cash equivalents at the beginning of the year")

        return { 
            cy : (netCashGeneratedABC()?.cy + (typeof income_tax_paid_received?.cy === "number" ? income_tax_paid_received?.cy : 0)), 
            py: (netCashGeneratedABC()?.py + (typeof income_tax_paid_received?.py === "number" ? income_tax_paid_received?.py : 0))
        }
    }

    const calculateBalancesPerStatement = () => 
        data.filter(ele => ["Cash on hand", "Bank balances"].includes(ele?.particular))
        .reduce((acc, sub) => {
            return {
                cy_amt: acc.cy_amt + (typeof sub.cy === "number" ? sub.cy : 0),
                py_amt: acc.py_amt + (typeof sub.py === "number" ? sub.py : 0),
            };
        }, { cy_amt: 0, py_amt: 0 })
    

    return (
        <>
            <div className='table-custom mb-3'>
                <table>
                    <thead>
                        <tr>
                            <th className='text-left pl-5  bg-white custom-font-600 border-top-0 border-bottom-0  text-uppercase font-inter' style={{ fontSize: "15px", width: "30%" }}>Particulars</th>
                            <th className='text-center pr-3  bg-white custom-font-600 border-top-0 border-bottom-0  text-uppercase font-inter' style={{ fontSize: "15px", width: "10%" }}>Notes</th>
                            <th  className='text-center pr-3 bg-white border-top-0 border-bottom-0' style={{width: "15%"}}>
                                <p className='m-0  custom-font-600 text-uppercase font-inter' style={{ fontSize: "15px",  }}>Year ended <br />{moment(workspaceDate?.cy).format("DD MMMM YYYY")}</p>
                            </th>
                            <th  className='text-center pr-3 bg-white border-top-0 border-bottom-0' style={{width: "19%"}}>
                                <p className='m-0  custom-font-600 text-uppercase font-inter' style={{ fontSize: "15px",  }} >Year ended <br /> {moment(workspaceDate?.py).format("DD MMMM YYYY")}</p>
                            </th>
                            <th  className='text-center pr-3 bg-white border-top-0 border-bottom-0' style={{width: "10%"}}>
                                <p  className='m-0  custom-font-600 text-uppercase font-inter' style={{ fontSize: "15px",  }}>REFERENCES</p>
                            </th>
                            <th  className='text-center pr-3 bg-white border-top-0 border-bottom-0' style={{width: "10%"}}>
                                <p  className='m-0  custom-font-600 text-uppercase font-inter' style={{ fontSize: "15px",  }}>IND AS<br /> REFERENCES</p>
                            </th>
                            <th  className='text-center pr-3 bg-white border-top-0 border-bottom-0' style={{width: "6%"}}>
                                <p className='m-0 custom-font-600 text-uppercase font-inter' style={{ fontSize: "15px",  }}>CHECKLIST <br />REFERENCES</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='text-left border-top-0 border-bottom-0 font-size-16 text-dark custom-font-600' style={{backgroundColor:"#f2e2b5"}}>Cash flow from operating activities</td>
                            <td className='font-size-16 font-sans' style={{backgroundColor:"#f2e2b5"}}></td>
                            <td className='font-size-16 font-sans' style={{backgroundColor:"#f2e2b5"}}></td>
                            <td className='font-size-16 font-sans' style={{backgroundColor:"#f2e2b5"}}></td>
                            <td className='font-size-16 font-sans' style={{backgroundColor:"#f2e2b5"}}></td>
                            <td className='font-size-16 font-sans' style={{backgroundColor:"#f2e2b5"}}></td>
                            <td className='font-size-16 font-sans' style={{backgroundColor:"#f2e2b5"}}></td>
                        </tr>
                        
                        {
                            data && data.length > 0 && data.map((ele, i) => (
                                <>
                                    {
                                        [17,28,34,55,56].includes(i) && (
                                            <>
                                                <tr>
                                                    <td className='font-weight-bold'>
                                                        {i === 28 && "Net cash generated / (used) in operating activities (A)"}
                                                        {i === 34 && "Net cash used in investing activities (B)"}
                                                        {i === 55 && "Net cash from financing activities (C)"}
                                                        {i === 56 && "Cash and cash equivalents at the end of the year "}
                                                    </td>
                                                    <td></td>
                                                    <td className={`font-weight-bold border-top text-right pr-3 ${[28,34,55,56].includes(i) && "border-bottom"}`}>
                                                        {i === 17 && formatIndianCurrency(calculateTotalByIndex(data, 0, 17)?.sumCy, null, true)}
                                                        {i === 28 && formatIndianCurrency(netCashGeneratedA()?.cy, null , true)}
                                                        {i === 34 && formatIndianCurrency(calculateTotalByIndex(data, 28,34)?.sumCy, null, true)}
                                                        {i === 55 && formatIndianCurrency(calculateTotalByIndex(data, 34, 55)?.sumCy, null, true)}
                                                        {i === 56 && formatIndianCurrency(CashEquivalentsEndYear()?.cy, null , true)}

                                                    </td>
                                                    <td className={`font-weight-bold border-top text-right pr-3 ${[28,34,55,56].includes(i) && "border-bottom"}`}>
                                                        {i === 17 && formatIndianCurrency(calculateTotalByIndex(data, 0, 17)?.sumPy, null, true )}
                                                        {i === 28 && formatIndianCurrency(netCashGeneratedA()?.py, null, true )}
                                                        {i === 34 && formatIndianCurrency(calculateTotalByIndex(data, 28,34)?.sumPy, null, true )}
                                                        {i === 55 && formatIndianCurrency(calculateTotalByIndex(data, 34, 55)?.sumPy, null, true )}
                                                        {i === 56 && formatIndianCurrency(CashEquivalentsEndYear()?.py, null, true )}
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold" style={{backgroundColor: i === 28 || i === 34 ? "#f2e2b5" : "transparent"}}>
                                                            {i === 17 && "Working capital adjustments:"}
                                                            {i === 28 && "Cash flows from investing activities"}
                                                            {i === 34 && "Cash flows from financing activities"}
                                                            {i === 55 && "Net (decrease) in cash and cash equivalents (A+B+C)"}
                                                            {i === 56 && "Reconciliation of cash and cash equivalents as per cash flow statement"}
                                                    </td>
                                                    <td style={{backgroundColor: i === 28 || i === 34 ? "#f2e2b5" : "transparent"}}></td>
                                                    <td className="font-weight-bold text-right pr-3" style={{ backgroundColor: i === 28 || i === 34 ? "#f2e2b5" : "transparent"}}>
                                                            {i === 55 && formatIndianCurrency(netCashGeneratedABC()?.cy, null, true)}
                                                    </td>
                                                    <td className="font-weight-bold text-right pr-3" style={{ backgroundColor: i === 28 || i === 34 ? "#f2e2b5" : "transparent" }}>
                                                            {i === 55 && formatIndianCurrency(netCashGeneratedABC()?.py, null, true)}
                                                    </td>
                                                    <td style={{ backgroundColor: i === 28 || i === 34 ? "#f2e2b5" : "transparent" }}></td>
                                                    <td style={{backgroundColor: i === 28 || i === 34 ? "#f2e2b5" : "transparent"}}></td>
                                                    <td style={{backgroundColor: i === 28 || i === 34 ? "#f2e2b5" : "transparent"}}></td>
                                                    <td style={{backgroundColor: i === 28 || i === 34 ? "#f2e2b5" : "transparent"}}></td>
                                                </tr>
                                                {i === 56 &&
                                                    <>
                                                    <tr>
                                                        <td>
                                                            {"Cash and cash equivalents as per the above comprises of the following"}
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td className='text-right pr-3'>
                                                            <p className='m-0 font-weight-bold' style={{ fontSize: "14px", fontWeight: "600" }} >As at </p>
                                                            <p className='m-0 font-weight-bold' style={{ fontSize: "14px", fontWeight: "600" }} >{moment(workspaceDate?.cy).format("DD MMMM YYYY")}</p>
                                                        </td>
                                                        <td className='text-right pr-3'>
                                                            <p className='m-0 font-weight-bold' style={{ fontSize: "14px", fontWeight: "600" }} >As at </p>
                                                            <p className='m-0 font-weight-bold' style={{ fontSize: "14px", fontWeight: "600" }} >{moment(workspaceDate?.py).format("DD MMMM YYYY")}</p>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    </>
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        [1, 27].includes(i) && (
                                            <tr key={i}>
                                                <td className='font-weight-bold'>
                                                    {i === 1 && "Adjustments:"}
                                                    {i === 27 && "Cash generated from operating activities"}
                                                </td>
                                                <td></td>
                                                <td className={`font-weight-bold text-right pr-3 ${i === 27 && "border-top"}`}>{i === 27 && formatIndianCurrency(calculateCashGeneratedOperatingActivities()?.cy, null, true)}</td>
                                                <td className={`font-weight-bold text-right pr-3 ${i === 27 && "border-top"}`}>{i === 27 && formatIndianCurrency(calculateCashGeneratedOperatingActivities()?.py, null, true)}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )
                                    }
                                    <tr key={i}>
                                        <td>{ele?.particular}</td>
                                        <td className='text-right pr-3' >{ele?.note}</td>
                                        <td className='text-right pr-3' >{formatIndianCurrency(ele?.cy, null, true)}</td>
                                        <td className='text-right pr-3' >{formatIndianCurrency(ele?.py, null, true)}</td>
                                        <td></td>
                                        <td>
                                        <input 
                                         type="checkbox" 
                                        />
                                        </td>
                                        <td className='pl-5'>
                                        <input 
                                         type="checkbox" 
                                        />
                                        </td>
                                    </tr>
                                </>
                            ))
                        }
                        <tr>
                            <td className='font-weight-bold'>Balances as per statement of cash flows</td>
                            <td></td>
                            <td  className='font-weight-bold border-top border-bottom text-right pr-3'>{formatIndianCurrency(calculateBalancesPerStatement()?.cy_amt, null, true)}</td>
                            <td  className='font-weight-bold border-top border-bottom text-right pr-3'>{formatIndianCurrency(calculateBalancesPerStatement()?.py_amt, null, true)}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default CashFlowTable