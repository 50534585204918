import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router";
import { useDropzone } from 'react-dropzone';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import swal from "sweetalert";
import { toast } from 'react-toastify';
import "./../../Bookclosure/Styles/Bookclosure.css"
import { Navbar } from '../../Components/Navbar';
import Guide from '../Components/UploadTB/Guide';
import Folder from '../Components/SvgComponents/Folder';
import SelectColumn from '../Components/UploadTB/SelectColumn';
import XlxsSvgComponent from '../Components/SvgComponents/XlxsSvgComponent';
import { getOneBookClosure, getTbHeaders, setTbHeaders, uploadTB,getAllCOA } from '../Services/ApiHandler';

const tbInitialState = {
  ledgerCode: "",
  ledgerName: "",
  periodEndingBalance: "",
  periodBeginingBalance: "",
  amountIn: "",
  bookClosureCOAID:""
}

const UploadTbV2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { workspaceId, companyId } = useParams();

  const {
    bookClosureId,
  } = useSelector((state) => state.BookClosureReducer);

  const [isGuidTb, setIsGuidTb] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isSelectColumnModal, setIsSelectColumnModal] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [bookClosureTbId, setBookClosureTbId] = useState(null);
  const [tbHeadersData, setTbHeadersData] = useState(null);
  const [headersMappedData, setHeadersMappedData] = useState(tbInitialState)
  const [allCOA,setAllCOA]=useState([]);

  useEffect(() => {
    if (!bookClosureId) {
      getBookClosureData();
    }
  }, []);

  useEffect(() => {
    GetAllCoa();
  }, []);

  const getBookClosureData = () => {
    const data = {
      companies: [companyId],
    }
    getOneBookClosure(workspaceId, data).then(response => {
      dispatch({ type: "BOOK_cLOSURE_DATA", payload: response.data.bookClosure || {} });
    }).catch(error => {
      swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
    })
  }

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) {
      return;
    }
    setUploadFile(file);
    const formData = new FormData();
    formData.append('bookClosureID', bookClosureId);
    formData.append('companyID', companyId);
    formData.append('file', file);
    setIsUploading(true);
    uploadTB(formData).then(response => {
      toast.success("File Uploaded Successfully");
      setBookClosureTbId(response.data.bookClosureTB._id);
    }).catch(error => {
      setUploadFile(null);
      swal("Error", `UploadTb Error: ${error.response?.data?.error}`, 'error');
    }).finally(() => setIsUploading(false))
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.xlsx, .xls' });

  const GetTbHeaders = () => {
    getTbHeaders(bookClosureTbId).then(response => {
      setTbHeadersData(response.data?.fields);
      setIsSelectColumnModal(true)
    }).catch(error => {
      swal("Error", `Get TB Headers Error: ${error.response?.data?.error}`, 'error');
    })
  }
// get all coa through company ID
  const GetAllCoa = () => {
    getAllCOA(companyId).then(response => {
      setAllCOA(response.data.bookClosureCOAList);
    }).catch(error => {
      swal("Error", `Get all COA failed: ${error.response?.data?.error}`, 'error');
    })
  }

  const CoaName = allCOA.map(item => item.name);
  const COAID = allCOA.find(item => item.name === headersMappedData.bookClosureCOAID);

  const SetTbHeaders = () => {
    setTbHeaders(bookClosureTbId, {
      ledgerCode: headersMappedData.ledgerCode !== "select" ? headersMappedData.ledgerCode : "",
      ledgerName: headersMappedData.ledgerName !== "select" ? headersMappedData.ledgerName : "",
      periodEndingBalance: headersMappedData.periodEndingBalance !== "select" ? headersMappedData.periodEndingBalance : "",
      periodBeginingBalance: headersMappedData.periodBeginingBalance !== "select" ? headersMappedData.periodBeginingBalance : "",
      amountIn: headersMappedData.amountIn !== "select" ? headersMappedData.amountIn : "",
      bookClosureCOAID:headersMappedData.bookClosureCOAID !== "select"? COAID?._id:""
    }).then(response => {
      toast.success("TB Headers Mapped Successfully");
      setIsBtnDisabled(false);
    }).catch(error => {
      swal("Error", `Mapping TB Headers Error: ${error.response?.data?.error}`, 'error');
    }).finally(() => TBModalCleanUp())
  }

  const onChangeTB = useCallback((event) => {
    const { name, value } = event.target;
    setHeadersMappedData(prev => ({ ...prev, [name]: value }));
  }, []);

  const TBModalCleanUp = () => {
    setHeadersMappedData(tbInitialState);
    setIsSelectColumnModal(false)
  }

  return (
    <>
      <Navbar />
      <div className='px-5 py-5'>
        <div className='d-flex justify-content-between'>
          <button
            className='border-0 bg-color-inherit custom-common-theme-text-color'
            onClick={() => history.push(`/bookclosure/${workspaceId}`)}
          >
            <ArrowBackIcon className='custom-common-theme-text-color' /> Back
          </button>
          <div className='d-flex align-items-center'>
            <span >
              <HelpOutlineIcon onClick={() => setIsGuidTb(true)} className='custom-common-theme-text-color cursor-pointer' />
            </span>
          </div>
        </div>

        <div>
          <p className='mt-4 mb-0 font-size-24 text-dark custom-font-600 font-sans'>Upload Trail Balances</p>
          <div className='p-4'>
            <ul style={{ listStyleType: "disc" }}>
              <li className='text-secondary custom-font-500 mb-2 font-sans'>File you upload must contain Ledger code, ledger name, and current year amount</li>
              <li className='text-secondary custom-font-500 mb-2 font-sans'>Please ensure Column header is on the first row</li>
              <li className='text-secondary custom-font-500 mb-2 font-sans'>Remove sub-totals in between or at the end</li>
            </ul>
          </div>
        </div>

        <div
          className='w-75 radius-10px bg-white'
          style={{ border: "2px solid #D6D6D6" }}
        >
          <div
            className='ml-3 mt-3 mb-3 mr-3 d-flex justify-content-center align-items-center radius-8px custom-bg-white-150'
            style={{ border: "2px dashed #D6D6D6", height: "276px" }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div>
              <div className='d-flex flex-column align-items-center'>
                <div className='d-flex justify-content-center align-items-center rounded-circle' style={{ backgroundColor: '#FFE3BF', width: '100px', height: '100px' }}>
                  <Folder color={"#573E20"} height={36} width={46} />
                </div>
                <p className='custom-common-theme-text-color mt-1 mb-0 font-size-12 custom-font-500' >{isUploading ? "uploading..." : uploadFile?.name}</p>
                <p className='mt-0 mb-0 custom-font-600 font-size-16 font-sans mt-2'>Upload files here to prepare financial statements</p>
                <div className='mt-2 d-flex justify-content-center align-items-center'>
                  <span><ErrorOutlineIcon className='custom-common-theme-text-color' /></span>
                  <p className='mt-0 mb-0 custom-font-500 font-size-12 text-secondary font-sans ml-1'>
                    File must contain Ledger Code, Ledger Name, Net Balance for Current Year
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          uploadFile &&
          <div className='w-75 radius-8px mt-3 bg-white'>
            <div className="d-flex align-items-center justify-content-between p-3">
              <div className="d-flex align-items-center">
                <IconButton
                  aria-label="delete"
                  onClick={() => setUploadFile(null)}
                >
                  <DeleteIcon />
                </IconButton>
                <div className="d-flex align-items-center ml-2 rounded custom-bg-white-150 py-3 px-4" style={{}}>
                  <XlxsSvgComponent height={24} width={24} color={"#343434"} key={"xlxsIcon"} />
                  <p className='mt-0 mb-0 ml-2 font-size-16 custom-font-500 font-inter'>{uploadFile?.name}</p>
                </div>
              </div>
              <button
                onClick={GetTbHeaders}
                className='custom-common-theme-text-color font-size-16 custom-font-600 radius-6px font-sans px-4 py-3 bg-white custom-border'
              >
                Select Columns
              </button>
            </div>
          </div>
        }

        <div className="d-flex justify-content-center w-75 mt-3 save-btn">
          <Button
            className='text-capitalize custom-common-theme-bg-color font-size-16 custom-font-600 text-white radius-6px font-sans save-btnv2'
            variant="contained"
            disabled={isBtnDisabled}
            onClick={() => history.push(`/tbc/${bookClosureTbId}/${workspaceId}`)}
          >
            Save and Continue
          </Button>
        </div>
      </div>

      <Guide isGuidTb={isGuidTb} setIsGuidTb={setIsGuidTb} />
      <SelectColumn
        isSelectColumnModal={isSelectColumnModal}
        tbHeaders={tbHeadersData}
        headersMappedData={headersMappedData}
        onChangeTB={onChangeTB}
        TBModalCleanUp={TBModalCleanUp}
        SetTbHeaders={SetTbHeaders}
        CoaName={CoaName}
      />
    </>
  )
}

export default UploadTbV2