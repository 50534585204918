import Sidebar from "../../Workspace2/Sidebar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Badge from "@mui/material/Badge";
import "./../..././../../../WorkSpace/SignUp2.css";
import "../../../WorkSpace/font.css";

import {
  CreateCompany,
  CreateIr,
  GetAllCompany,
  GetAllCompanyData,
  GetCheckedIR,
  UpdateStepStatus,
  fetchIR,
  getCheckData,
  getCompany,
  getWorkspace,
  getCompanyByPreparerId,
  GetAllImportedCompanies,
} from "../ApiServices/apiHandler";
import { useEffect } from "react";
import useFetchApi from "../../../services/CustomHook/useFetchApi";
import swal from "sweetalert";
import { useState } from "react";
import { getCompanybyId } from "../../../Console/Component/services/apiHandler";
import { useHistory, useLocation } from "react-router";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { addWeeks } from "date-fns";
import WorkspaceHeader from "./WorkspaceHeader";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCompany, getOneBookClosure } from "../../../Bookclosure/Services/ApiHandler";
import { toast } from "react-toastify";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const label2 = { inputProps: { "aria-label": "Checkbox demo" } };

const AddCompanies = (props) => {
  const dispatch = useDispatch()

  const { id } = useParams();
  let history = useHistory();

  const user = JSON.parse(localStorage.getItem('user'));


  const location = useLocation();
  let a = location.pathname;
  const { loading, response, error } = useFetchApi(
    `api/v1/company-data/all-IR`
  );

  const { bookClosureId } = useSelector((state) => state.BookClosureReducer);

  const [workspace, setWorkspace] = useState("");
  const [singleWorkspace, setSingleWorkspace] = useState({});
  const [investmentRegister, setInvestmentRegister] =  useState([]);
  const [selectedInvestmentRegister, setSelectedInvestmentRegister] = useState([]);
  const [investmentRegisterImported, setInvestmentRegisterImported] = useState(false)
  const [isOpenIr, setIsOpenIr] = useState(false);

  const [company, setCompany] =  useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [companyImported, setCompanyImported] = useState(false)
  const [importedCompany, setImportedCompany] = useState([]);
  const [isOpenCompany, setIsOpenCompany] = useState(false);

  useEffect(() => {
    if (response && Object.keys(response).length > 0) {
      setInvestmentRegister(response.data.fetchIR)
    }
    if (error) {
      swal("Error", error, "error");
    }
    getIr();
    companyData();
    getWorkspaceDetails();
    GetAllImportedCompany();
  }, [response || error]);

  // useEffect(() => {
  //   if(!bookClosureId){
  //     getOneBookClosure(id).then(response => {
  //       dispatch({ type: "BOOK_cLOSURE_DATA", payload: response.data.bookClosure || {} });
  //     }).catch(error => {
  //       swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
  //     })
  //   }
  // }, [bookClosureId])

  const handleOpenIr = () => {
    setIsOpenIr(!isOpenIr);
  }
  const handleOpenCompany = () => {
    setIsOpenCompany(!isOpenCompany);
  }
  const GetAllImportedCompany = async () => {
    const user_id=user._id;
    const res = await GetAllImportedCompanies(user_id);
    if (res.status === 200) {
      setImportedCompany(res.data.result);
    }
  };

  const companyData = async () => {
    try {
      const res = await getCompany();
      if (res.status === 200) {
        setCompany(res.data.getCompany)
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };
  const getWorkspaceDetails = async () => {
    const res = await getWorkspace(id);
    if (res.status === 200) {
      setSingleWorkspace(res.data?.getworkspace[0])
      const workspaceData = res.data?.getworkspace[0];
      if(workspaceData && workspaceData.book_closure_as_on_date){
        getOneBookClosure(id).then(response => {
          dispatch({ type: "BOOK_cLOSURE_DATA", payload: response.data.bookClosure || {} });
        }).catch(error => {
          swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
        })
      }
    }
  }

  const getIr = async () => {
    try {
      const res = await GetCheckedIR(id);
      if (res.status === 200) {
            console.log(res,'---------')
        if(res.data.getIR[0].all_IR_checked.length>0){
          setInvestmentRegisterImported(true)
          setSelectedInvestmentRegister(res.data.getIR[0].all_IR_checked)
        }else {
          setInvestmentRegisterImported(false)
        }

        if(res.data.getIR[0].all_company_checked.length>0){
          setCompanyImported(true)
          setSelectedCompany(res.data.getIR[0].all_company_checked)
        } else {
          setCompanyImported(false)
        }

        setWorkspace(res.data.getIR[0]);
      }
    } catch (error) {}
  };


  
  const nextPage = async () => {
    try {
      const data = {
        steps: "step3",
        has_published:"draft",
        status:singleWorkspace.status,
        approver_id:singleWorkspace.approver_id,
        cancel_reason:singleWorkspace.cancel_reason,
      };

      const res = await UpdateStepStatus(id, data);
      if (res.status === 200) {
       history.push(`/importregister/${id}`);
      }
    } catch (error) {
      console.log("error", error.message);
      return 0;
    }
  };

  
  const companyName = (c_id) => {
    try {
      //console.log(c_id, "ciiii");
      const nameCompany = company.filter((adj) => adj._id === c_id);
      const result = nameCompany[0]?.company_name
        ? nameCompany[0]?.company_name
        : "unknown";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };

  const updateCheckedIR = (key, checked_data) => {
    const data  = {
      w_id: id,
      [key]: checked_data
    }
    CreateCompany(data).then(res => {
      if(key === "all_company_checked" && singleWorkspace && singleWorkspace.book_closure_as_on_date){
        // ? Update company in Book Closure Array
        addCompany(bookClosureId, {companies: checked_data} ).then(response => {
          toast.success("Book Closure: Company Added Successfully.")
        }).catch(error => {
          swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
        })
      }
      getIr();
      return res
    }).catch(err => {
      swal("Error", err, "error");
      return err
    })
  }

  const handleSelectIR = (event, id) => {
    setSelectedInvestmentRegister((prevItems)=>{
      // If the value is checked, push it to the array; otherwise, remove it
      if (prevItems.includes(id)) {   
        updateCheckedIR("all_IR_checked", selectedInvestmentRegister.filter((item) => item !== id));    
        return prevItems.filter((item) => item !== id);
      } else {
        setInvestmentRegisterImported(false)
        return [...prevItems, id];
      }
    })   
  };
  const importAllIR = async () => {
    const allIRIds = investmentRegister.map((ir) => ir._id)
    setSelectedInvestmentRegister(allIRIds);
    updateCheckedIR("all_IR_checked", allIRIds);
  }

  const handleSelectCompany = (event, id) => {
    setSelectedCompany((prevItems)=>{
      // If the value is checked, push it to the array; otherwise, remove it
      if (prevItems.includes(id)) {   
        updateCheckedIR("all_company_checked", selectedCompany.filter((item) => item !== id));    
        return prevItems.filter((item) => item !== id);
      } else {
        setCompanyImported(false)
        return [...prevItems, id];
      }
    })   
  };
  const importAllCompany = async () => {
    const allCompanyIds = company.map((ir) => ir._id)
    if(user.responsibilities==="preparer"){
    const matchingCompanyIds = allCompanyIds.filter(id => importedCompany.includes(id));
    setSelectedCompany(matchingCompanyIds)
    updateCheckedIR("all_company_checked", matchingCompanyIds);
    }else{
      setSelectedCompany(allCompanyIds)
    updateCheckedIR("all_company_checked", allCompanyIds);
    }
  }


  return (
    <>
      <div className="row mr-0 ml-0">
        <div className="col-3 bg-white">
          <Sidebar activePage="3" id={id} />
        </div>
        <div className="col-9 main-workspace-content">
          <WorkspaceHeader
            currentStep="3"
            title="Add companies to your workspace"
          />

          <div className="row mt-3">
            <div className="col-12 mt-2">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={() => handleOpenIr()}
                >
                  <Typography>
                    {isOpenIr?(
                    <div className="open-radio-outer">
                      <div className="open-radio-inner"></div>
                    </div> 
                    ):investmentRegisterImported?(
                      <CheckCircleIcon
                      style={{
                        color: "#03565A",
                      }}
                    />
                    ):(
                      <RadioButtonUncheckedIcon
                        style={{
                          color: "#03565A",
                        }}
                      />
                    )}
                    
                    <p className="dm-sans addcompconthead font-sans">
                      Investment register 
                      &nbsp;
                      {investmentRegisterImported?(
                      <Badge className="badge_css font-sans">Imported</Badge>
                     ):''}
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <hr />

                  <Typography
                    style={{
                      overflowY: "scroll",
                      height: "15rem",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                  >
                    {investmentRegister.length > 0 &&
                      investmentRegister?.map((ir) => {
                        return (
                          <>
                          {ir?.ir_name && 
                          <>
                            <div className="d-flex ">
                            <Checkbox
                                sx={{
                                  color: "#03565A",
                                  '&.Mui-checked': {
                                    color: "#03565A",
                                  },
                                }}
                                checked={selectedInvestmentRegister.includes(ir._id)}
                                disabled={user.responsibilities==="approver" ||singleWorkspace.status==="waiting"}
                                id={ir._id}
                                onChange={(event) =>
                                  handleSelectIR(
                                    event,
                                    ir._id
                                  )
                                }
                              />
                              <span className="d-flex justify-content-between w-100 mt-2 font-sans">
                                <h6>
                                  {ir?.ir_name ? ir?.ir_name : "unknown"}
                                </h6>
                                <strong className="font-sans" style={{ paddingRight: "5rem",color:"#8B909A" }}>
                                  {companyName(ir.parent_company_id)}
                                </strong>
                              </span>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div
                                className="d-flex"
                                style={{ marginLeft: "22px" }}
                              >
                                <div
                                  style={{ color: "gray", marginLeft: "15px" }}
                                >
                                  <CalendarMonthIcon
                                    style={{ fontSize: "14px" }}
                                  />
                                  <span style={{ fontSize: "12px" }}>
                                    {moment(ir?.date_Of_investment).format(
                                      "MMMM DD,YYYY"
                                    )}
                                  </span>
                                </div>
                                <div style={{ color: "gray" }}>
                                  <CalendarMonthIcon
                                    style={{ fontSize: "14px" }}
                                  />
                                  <span style={{ fontSize: "12px" }}>
                                    {moment(ir?.date_Of_investment).format(
                                      "MMMM DD,YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                          }
                          </>
                        );
                      })}
                  </Typography>
                  <hr />
                  <div className="d-flex">
                    <div>
                      <Button
                      className="font-sans"
                        style={{
                          marginLeft: "0px",
                          backgroundColor: "#03565A",
                          color: "white",
                          border: "2px solid #03565A",
                          textTransform: "none",
                          width: "265px",
                          height: "48px"
                        }}
                        onClick={() => updateCheckedIR("all_IR_checked", selectedInvestmentRegister)}
                        disabled={user.responsibilities==="approver"||singleWorkspace.status==="waiting"}
                      >
                        Import selected 
                      </Button>
                    </div>
                    <div className="ml-3">
                      <Button
                      className="font-sans"
                        style={{
                          border: "2px solid #03565A",
                          color: "#03565A",
                          textTransform: "none",
                          width: "265px",
                          height: "48px"
                        }}
                        onClick={importAllIR}
                        disabled={user.responsibilities==="approver"||singleWorkspace.status==="waiting"}
                      >
                        Import all IR
                      </Button>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 mt-2">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={() => handleOpenCompany()}
                >
                  <Typography>
                  {isOpenCompany?(
                    <div className="open-radio-outer">
                      <div className="open-radio-inner"></div>
                    </div> 
                    ):companyImported?(
                      <CheckCircleIcon
                      style={{
                        color: "#03565A",
                      }}
                    />
                    ):(
                      <RadioButtonUncheckedIcon
                        style={{
                          color: "#03565A",
                        }}
                      />
                    )}
                    <p className="dm-sans addcompconthead font-sans">Companies List 
                    &nbsp; 
                    {companyImported?(
                      <Badge className="badge_css font-sans">Imported</Badge>
                     ):''}
                    </p>
                  </Typography>
                  <hr />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    style={{
                      overflowY: "scroll",
                      height: "15rem",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                  >
                    <div style={{}}>
                      {company.length > 0 && company.map((comp) => {
                          return (
                            <>
                              <div className="d-flex ">
                              <Checkbox
                                sx={{
                                  color: "#03565A",
                                  '&.Mui-checked': {
                                    color: "#03565A",
                                  },
                                }}
                                checked={selectedCompany.includes(comp._id)}
                                disabled={user.responsibilities==="approver" || user.responsibilities==="preparer" && !importedCompany.includes(comp._id)||singleWorkspace.status==="waiting"}
                                id={comp._id}
                                onChange={(event) =>
                                  handleSelectCompany(
                                    event,
                                    comp._id
                                  )
                                }
                              />
                                <span className="d-flex justify-content-between w-100 mt-2 font-sans">
                                  <h6>{comp?.company_name}</h6>
                                </span>
                              </div>
                              
                            </>
                          );
                        })}
                    </div>
                  </Typography>
                  <hr />
                  <div className="d-flex">
                    <div>
                      <Button
                      className="font-sans"
                        style={{
                          marginLeft: "0px",
                          backgroundColor: "#03565A",
                          color: "white",
                          textTransform: "none",
                          border: "2px solid #03565A",
                          width: "265px",
                          height: "48px"
                        }}
                        disabled={user.responsibilities==="approver"||singleWorkspace.status==="waiting"}
                        onClick={() => updateCheckedIR("all_company_checked", selectedCompany)}
                      >
                        Import selected 
                      </Button>
                    </div>
                    <div className="ml-3">
                      <Button
                      className="font-sans"
                        style={{
                          border: "2px solid #03565A",
                          color: "#03565A",
                          textTransform: "none",
                          width: "265px",
                          height: "48px"
                        }}
                        disabled={user.responsibilities==="approver"||singleWorkspace.status==="waiting"}
                        onClick={importAllCompany}
                      >
                        Import all companies
                      </Button>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6">
              <div
                className="backicon cursor-pointer"
                onClick={() => {
                  dispatch({ type: "WS_BACK_FROM_QUERY", payload: "addCompanies" });
                  history.push(`/workspacesetup/${id}`)
                }}
              >
                <ArrowBackIcon />
                <div className="font-sans">
                  Back to the Previous step
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="nextbtn-v2 text-right">
                {workspace?.all_company_checked?.length > 0 ||
                workspace?.all_IR_checked?.length > 0 ? (
                  <Button
                    className="datebtn font-sans"
                    style={{
                      backgroundColor: "#03565A",
                      color: "white",
                      textTransform: "none",
                    }}
                    onClick={nextPage}
                  >
                    Next Step
                  </Button>
                ) : (
                  <Button
                    className="datebtn"
                    style={{
                      backgroundColor: "#B3D9D9",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    Next Step
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCompanies;
