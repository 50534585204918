import { Box, Button, Modal } from "@mui/material";
import React, { useState } from "react";

const stylemodal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height:290,
  bgcolor: "#f5f7fa",
  boxShadow: 24,
  p: 5,
  borderRadius:1
};

const shadow = {
    width: "100%",
    height: "3rem",
    outline: "none",
    borderRadius: "6px",
    outline: "none",
    border: "1px solid #D0D0D0",
    padding: "10px",
    fontSize: "20px",
    color: "var(--clr-font-dark)",
}
const COAModal = ({ isOpen, onClose, SaveCOA,setOperModal }) => {
  const [coaName, setCoaName] = useState("");

  const handleClose = () => {
    setOperModal(false);
    setCoaName('');
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={stylemodal}>
          <h2 className="custom-font-600 font-sans">Chart Of Accounts</h2>
          <div>
            <label className="font-sans font-size-14" style={{color:"#696f79"}}>COA Name</label>
            <input
              style={shadow}
              value={coaName}
              onChange={(event) => setCoaName(event.target.value)}
            />
          </div>
          <div className="d-flex flex-row-reverse mt-5">
            <div className="ml-2">
              <Button
               className="datebtn font-sans text-white custom-common-theme-bg-color font-size-16 radius-6px text-capitalize py-2" 
               style={{width:"120px"}}
               onClick={handleClose}
               >
                Cancel
              </Button>
            </div>
            <div>
              <Button
               className="datebtn font-sans mr-1 text-white custom-common-theme-bg-color font-size-16 radius-6px text-capitalize py-2" 
               style={{width:"120px"}}
               onClick={()=>{
                SaveCOA(coaName);
                setCoaName('');
               }}
               >
                Save
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default COAModal;
